import { buildRadioButtonInput } from "../inputs/buildRadioButtonInput";
import { buildRadioIconInput } from "../inputs/buildRadioIconInput";

const stepTwoContentTemplate = ({ classes, model, sections }) => {
	const [sectionAge, sectionGender] = sections;
	const errorMessageSectionAge = "step-two__section-age";
	const errorMessageSectionGender = "step-two__section-gender";

	return `
        <div class="${classes.rootClass}__event-info">
            <h4>${model.texts.signupForLabel} ${model.eventModel.name}</h4>
            <time
                data-async-module="local-datetime"
                data-date-format="event-date-time"
                data-utc-start="${model.eventModel.startDateUTC}"
                data-utc-end="${model.eventModel.endDateUTC}"
                data-date-fallback="${model.eventModel.dateString}"
                data-use-ksa="true"
            ></time>
        </div>
        <div class="${classes.rootClass}__section">
            <fieldset class="${classes.rootClass}__fieldset">
                <legend class="visuallyhidden">Step 2</legend>

                <div class="${classes.rootClass}__radio-group ${
	classes.rootClass
}__radio-group--button">
                    <h3>${sectionAge.title}</h3>
                    <ul class="${classes.rootClass}__stacking-list">
                    	${buildRadioButtonInput(
		sectionAge.fields[0],
		errorMessageSectionAge
	)}
                    </ul>
                    <p class="${errorMessageSectionAge}"></p>
                </div>

                <div class="${classes.rootClass}__radio-group ${
	classes.rootClass
}__radio-group--icon">
                    <h3>${sectionGender.title}</h3>
                    <ul class="${classes.rootClass}__horizontal-list">
                        ${buildRadioIconInput(
		sectionGender.fields[0],
		classes,
		errorMessageSectionGender
	)}
                    </ul>
                    <p class="${errorMessageSectionGender}"></p>
                </div>
            </fieldset>
        </div>
    `;
};

export default stepTwoContentTemplate;
