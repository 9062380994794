export const pauseIcon = `
    <svg width="15" height="15" viewBox="0 0 24 24" fill="#00A3E0" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C10 20.5523 9.55228 21 9 21C8.44771 21 8 20.5523 8 20V4C8 3.44772 8.44772 3 9 3C9.55229 3 10 3.44772 10 4L10 20ZM16 20C16 20.5523 15.5523 21 15 21C14.4477 21 14 20.5523 14 20L14 4C14 3.44772 14.4477 3 15 3C15.5523 3 16 3.44772 16 4L16 20Z"></path>
    </svg>
`;

export const playIcon = `
    <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
        <path d="M4.16233 3.04657L11.9371 7.5L4.16233 11.9534L4.16233 3.04657Z" fill="none" stroke-width="1.3" stroke="currentColor"></path>
    </svg>
`;

export const crossIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"><path d="M19.0711 17.6568C19.4617 18.0473 19.4617 18.6805 19.0711 19.071C18.6806 19.4615 18.0474 19.4615 17.6569 19.071L12.0001 13.4142L6.34322 19.071C5.95269 19.4615 5.31953 19.4615 4.929 19.071C4.53848 18.6805 4.53848 18.0473 4.929 17.6568L10.5859 11.9999L4.929 6.34309C4.53848 5.95257 4.53848 5.3194 4.929 4.92888C5.31953 4.53836 5.95269 4.53835 6.34322 4.92888L12.0001 10.5857L17.6569 4.92888C18.0474 4.53836 18.6806 4.53836 19.0711 4.92888C19.4617 5.3194 19.4617 5.95257 19.0711 6.34309L13.4143 11.9999L19.0711 17.6568Z"></path></svg>`

export const headPhonesIcon = `<svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.7144 10.8771L4.85048 19.9654L3.14948 20.178L2 10.9825V10.9292C2 5.40659 6.47719 0.929688 11.9999 0.929688C17.5228 0.929688 22 5.40659 22 10.9292V10.9825L20.8505 20.178L19.1495 19.9654L20.2856 10.8771C20.2575 6.32531 16.5587 2.64393 11.9999 2.64393C7.44123 2.64393 3.74242 6.32531 3.7144 10.8771Z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M5.75561 10.543C6.84183 10.4358 7.81332 11.2193 7.93872 12.3035L8.54433 17.54C8.67141 18.639 7.8823 19.6323 6.78316 19.757L3.78909 20.0968L3.59584 18.3934L6.58981 18.0537C6.58982 18.0537 6.58979 18.0537 6.58981 18.0537C6.74682 18.0359 6.85959 17.894 6.84144 17.737C6.84144 17.737 6.84144 17.737 6.84144 17.737L6.23583 12.5005C6.21791 12.3455 6.07911 12.2336 5.92401 12.249L2.79723 12.5575L2.62891 10.8515L5.75561 10.543C5.75563 10.543 5.75558 10.543 5.75561 10.543Z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M15.8661 12.5383C15.9951 11.4396 16.9916 10.6546 18.0897 10.7858L21.0818 11.1435L20.8783 12.8456L17.8863 12.4879C17.8863 12.4879 17.8864 12.4879 17.8863 12.4879C17.7293 12.4692 17.587 12.5815 17.5686 12.7382L16.9898 17.6652C16.9716 17.8201 17.0811 17.9609 17.2356 17.9816L20.349 18.3982L20.1217 20.0973L17.0084 19.6807C17.0084 19.6807 17.0085 19.6807 17.0084 19.6807C15.9261 19.536 15.1599 18.5497 15.2873 17.4652L15.8661 12.5383C15.8661 12.5383 15.8661 12.5383 15.8661 12.5383Z"></path></svg>`
