import { buildRadioIconInput } from "../inputs/buildRadioIconInput";

const stepOneTemplate = ({ classes, sections, texts, iconRadioButtonOptions, stepText }) => {
	const errorMessageTarget = `${classes.rootClass}__error-message`;

	return `
        <div class="${classes.rootClass}__header">
				 		<p class="${classes.rootClass}__manchet">${stepText}</p>
            <h2 class="${classes.rootClass}__title">${sections.title}</h2>
        </div>
        <div class="${classes.cardClass}__slider" data-flickity-container>
            ${buildRadioIconInput(sections.fields[0], texts, classes, errorMessageTarget, true, iconRadioButtonOptions)}
            <p class="${errorMessageTarget}"></p>
        </div>
    `;
};

export default stepOneTemplate;
