export const lockIconUnlocked = `<svg focusable="false" width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.10493 1.92844C4.64139 1.56628 5.27385 1.37275 5.92111 1.37269C6.78106 1.37343 7.60556 1.7155 8.21346 2.32376C8.8214 2.93206 9.163 3.75681 9.1632 4.61681V4.61695V6.51264H2.6226C1.17418 6.51264 0 7.68682 0 9.13524V14.3774C0 15.8258 1.17418 17 2.6226 17H9.20465C10.6531 17 11.8272 15.8258 11.8272 14.3774V9.13524C11.8272 8.17276 11.3088 7.33138 10.5359 6.87515V4.61668C10.5357 3.3928 10.0495 2.21907 9.18438 1.35341C8.31922 0.487739 7.14578 0.000949824 5.9219 0H5.92136C5.00029 7.05459e-06 4.10026 0.275378 3.33687 0.790741C2.57348 1.3061 1.9816 2.03792 1.63725 2.8922C1.49553 3.24377 1.66565 3.64366 2.01722 3.78537C2.36879 3.92709 2.76868 3.75696 2.9104 3.40539C3.15241 2.80499 3.5684 2.29065 4.10493 1.92844ZM1.37269 9.13524C1.37269 8.44493 1.93229 7.88533 2.6226 7.88533H9.20465C9.89495 7.88533 10.4546 8.44493 10.4546 9.13524V14.3774C10.4546 15.0677 9.89495 15.6273 9.20465 15.6273H2.6226C1.93229 15.6273 1.37269 15.0677 1.37269 14.3774V9.13524ZM6.64461 11.5273C6.98612 11.2977 7.21083 10.9077 7.21083 10.4652C7.21083 9.75892 6.63825 9.18634 5.93194 9.18634C5.22563 9.18634 4.65305 9.75892 4.65305 10.4652C4.65305 10.9301 4.90103 11.337 5.27192 11.5609V13.6987C5.27192 14.0777 5.57921 14.385 5.95826 14.385C6.33732 14.385 6.64461 14.0777 6.64461 13.6987V11.5273Z" fill="#00A3E0"/>
</svg>
`;

export const lockIconLocked = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="25" viewBox="0 0 18 25" fill="none">
<path d="M13.7219 10.3337H4.2781C2.7438 10.3337 1.5 11.5775 1.5 13.1118V20.6331C1.5 22.1674 2.7438 23.4112 4.2781 23.4112H13.7219C15.2562 23.4112 16.5 22.1674 16.5 20.6331V13.1118C16.5 11.5775 15.2562 10.3337 13.7219 10.3337Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.37109 10.0711V6.62886C3.37138 5.13311 3.96577 3.69872 5.02353 2.64117C6.08129 1.58361 7.5158 0.989502 9.01155 0.989502C10.5065 0.990662 11.9399 1.58528 12.9967 2.64271C14.0536 3.70014 14.6473 5.13387 14.6476 6.62886V10.0711" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M9.02633 16.8547C10.0397 16.8547 10.8613 16.0332 10.8613 15.0198C10.8613 14.0064 10.0397 13.1849 9.02633 13.1849C8.01293 13.1849 7.19141 14.0064 7.19141 15.0198C7.19141 16.0332 8.01293 16.8547 9.02633 16.8547Z" fill="currentColor"/>
<path d="M9.0625 16.0441V19.6593" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;