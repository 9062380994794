export const buildTextInput = (field, classes) => {
	const errorMessageTargetClass = `${field.key}`;

	return `
        ${field.label ? `<h3>${field.label}</h3>` : ""}
        <div class="${classes.rootClass}__text-group animate-translate input">
            <input type="text"
                autocomplete="off"
                name="${field.key}"
                value="${field.value || ""}"
                ${field.maxLength ? `maxLength="${field.maxLength}"` : ``}
                data-error-message="${field.defaultErrorMessage}"
                data-error-container="${errorMessageTargetClass}"
                data-keyup-validation="disable"
                ${field.readOnly ? ` readonly` : ``}
                ${field.isRequired ? ` required` : ``}
                id="${field.key}"
            />
            <label for="${field.key}">${field.placeHolder}</label>
        </div>
        <div class="${errorMessageTargetClass}"></div>
    `;
};
