import { addEvent, removeAllEvents } from "~/foundation/Events/events";
import { ToggleVisiblePassword } from "~/foundation/Form/input/toggle-visible-password";
import { fetcher } from "~/foundation/Api";
import {
	showError,
	removeErrors,
	removeError
} from "~/foundation/Form/input/input-errors";
import { changePasswordMarkup } from "./markup";
import { appendElement, emptyElement } from "~/foundation/Dom/elementManipulation";

export class ChangePassword {
	/**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
	dom = {};

	/**
     *
     * @param {Element} domReference - The element to work from. -> membership__content
     */
	constructor(config) {
		this.config = config;
		this.dom.membershipContent = config.membershipContent;
		this.texts = config.texts;
		this.user = config.user ? config.user : {};
		this.errorMessages = config.errorMessages;

		this.changePasswordApiUrl = config.changePasswordApiUrl;

		this.callbacks = {
			onPasswordUpdate: config.onPasswordUpdate,
			onCancelClick: config.onCancelClick
		};

		this.initialize();
	}

	kill = () => {
		removeAllEvents([this.dom.savePassword, this.dom.cancelButton]);
		removeAllEvents([
			this.inputs.NewPassword,
			this.inputs.OldPassword,
			this.inputs.RepeatPassword
		]);
	};

	validatePassword = () => {
		if (this.inputs.OldPassword.value === "") {
			showError(
				this.inputs.OldPassword,
				this.errorMessages.oldPasswordRequired
			);
			return false;
		}

		if (this.inputs.NewPassword.value === "") {
			showError(
				this.inputs.NewPassword,
				this.errorMessages.newPasswordRequired
			);
			return false;
		}

		if (this.inputs.RepeatPassword.value === "") {
			showError(
				this.inputs.RepeatPassword,
				this.errorMessages.newPasswordRequired
			);
			return false;
		}

		removeErrors();
		return true;
	};

	savePassword = () => {
		if (!this.validatePassword()) {
			return;
		}

		fetcher(this.changePasswordApiUrl, "POST", {
			oldPassword: this.inputs.OldPassword.value,
			newPassword: this.inputs.NewPassword.value,
			repeatPassword: this.inputs.RepeatPassword.value
		}).then(result => {
			if (result.success) {
				this.callbacks.onPasswordUpdate();
				this.kill();
			} else {
				result.errors.map(error =>
					showError(this.inputs[error.property], error.message)
				);
			}
		});
	};

	onPasswordFocus(e) {
		const password = e.target;
		removeError(password);
	}

	initialize() {
		const editNameHtml = changePasswordMarkup(this.texts, this.user);

		emptyElement(this.dom.membershipContent);
		appendElement(editNameHtml, this.dom.membershipContent);

		const passwordToggleButtons =
            this.dom.membershipContent.querySelectorAll(
            	".toggle-visible-password"
            );
		this.togglePasswordVisibility = passwordToggleButtons.forEach(
			button => new ToggleVisiblePassword(button)
		);

		this.inputs = {
			OldPassword:
                this.dom.membershipContent.querySelector("#oldPassword"),
			NewPassword:
                this.dom.membershipContent.querySelector("#newPassword"),
			RepeatPassword:
                this.dom.membershipContent.querySelector("#repeatPassword")
		};

		addEvent(
			[
				this.inputs.NewPassword,
				this.inputs.OldPassword,
				this.inputs.RepeatPassword
			],
			"focus",
			this.onPasswordFocus
		);

		this.dom.savePasswordButton =
            this.dom.membershipContent.querySelector(".button--save");
		addEvent(this.dom.savePasswordButton, "click", this.savePassword);

		this.dom.cancelButton =
            this.dom.membershipContent.querySelector(".button--cancel");
		addEvent(this.dom.cancelButton, "click", () => {
			this.callbacks.onCancelClick();
			this.kill();
		});
	}
}
