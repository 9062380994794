import { addEvent, removeAllEvents } from "~/foundation/Events/events";
import { fetcher } from "~/foundation/Api";
import { showError, removeErrors } from "~/foundation/Form/input/input-errors";
import { editNameMarkup } from "./markup";
import { appendElement, emptyElement } from "~/foundation/Dom/elementManipulation";
export class EditName {
	/**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
	dom = {};

	/**
     *
     * @param {Element} domReference - The element to work from. -> membership__content
     */
	constructor(config) {
		this.dom.membershipContent = config.membershipContent;
		this.texts = config.texts;
		this.user = config.user ? config.user : {};
		this.errorMessages = config.errorMessages;

		this.updateNamesApiUrl = config.updateNamesApiUrl;

		this.callbacks = {
			onNameUpdate: config.onNameUpdate,
			onCancelClick: config.onCancelClick
		};

		this.initialize();
	}

	kill = () => {
		removeAllEvents([this.dom.saveName, this.dom.cancelButton]);
	};

	validateName = () => {
		if (this.dom.firstNameInput.value === "") {
			showError(
				this.dom.firstNameInput,
				this.errorMessages.firstNameRequired
			);
			return false;
		}

		if (this.dom.lastNameInput.value === "") {
			showError(
				this.dom.lastNameInput,
				this.errorMessages.lastNameRequired
			);
			return false;
		}

		removeErrors();
		return true;
	};

	saveName = () => {
		if (!this.validateName()) {
			return;
		}

		const user = {
			firstName: this.dom.firstNameInput.value,
			lastName: this.dom.lastNameInput.value,
			email: this.user.emailAddress
		};

		if (
			this.user.firstName === user.firstName &&
            this.user.lastName === user.lastName
		) {
			this.callbacks.onCancelClick();
			this.kill();
			return;
		}

		fetcher(this.updateNamesApiUrl, "POST", user).then(result => {
			if (result.success) {
				this.callbacks.onNameUpdate();
				this.kill();
			} else {
				alert("name change error");
			}
		});
	};

	initialize() {
		const editNameHtml = editNameMarkup(this.texts, this.user);

		emptyElement(this.dom.membershipContent);
		appendElement(editNameHtml, this.dom.membershipContent);

		this.dom.firstNameInput =
            this.dom.membershipContent.querySelector("#firstName");
		this.dom.lastNameInput =
            this.dom.membershipContent.querySelector("#lastName");

		this.dom.saveNameButton =
            this.dom.membershipContent.querySelector(".button--save");
		addEvent(this.dom.saveNameButton, "click", this.saveName);

		this.dom.cancelButton =
            this.dom.membershipContent.querySelector(".button--cancel");
		addEvent(this.dom.cancelButton, "click", () => {
			this.callbacks.onCancelClick();
			this.kill();
		});
	}
}
