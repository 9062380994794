import {
	appendElement,
	emptyElement
} from "~/foundation/Dom/elementManipulation";
import { addDependentMarkup } from "./markup";
import { showError, removeErrors } from "~/foundation/Form/input/input-errors";
import { fetcher } from "~/foundation/Api";
import { makeButtonLoad } from "~/foundation/Components/button/make-button-load";
import { addEvent, removeAllEvents } from "~/foundation/Events/events";
import { validateEmail } from "~/foundation/Helpers/validate";

export class AddDependentScreen {
	/**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
	dom = {};

	/**
     *
     * @param {Element} domReference - The element to work from. -> membership__content
     */
	constructor(config) {
		this.dom.container = config.container;
		this.dom.membershipContent = config.membershipContent;
		this.texts = config.texts;
		this.errorMessages = config.errorMessages;

		this.checkDependentApiUrl = config.apiEndPoints.checkDependentApiUrl;

		this.callbacks = {
			onEmailSuccess: config.onEmailSuccess,
			onCancelClick: config.onCancelClick
		};

		this.initialize();
	}

	validateAndCheckEmail = () => {
		if (this.dom.emailInput.value === "") {
			showError(this.dom.emailInput, this.errorMessages.emailRequired);
			return;
		}

		if (!validateEmail(this.dom.emailInput.value)) {
			showError(this.dom.emailInput, this.errorMessages.invalideEmail);
			return;
		}

		removeErrors();

		const buttonLoader = new makeButtonLoad(
			this.dom.continueButton,
			this.texts.loadingText
		);
		buttonLoader.load();

		fetcher(
			`${this.checkDependentApiUrl}?email=${this.dom.emailInput.value}`
		).then(result => {
			buttonLoader.finish(true).then(() => {
				if (result.success) {
					if (result.data.alreadyAddedAsDependent) {
						showError(
							this.dom.emailInput,
							this.errorMessages.dependencyWithEmailAlreadyAdded
						);
						return;
					}

					if (!result.data.accountWithEmailExists) {
						this.callbacks.onEmailSuccess({
							email: this.dom.emailInput.value,
							accountWithEmailExists:
                                result.data.accountWithEmailExists
						});
						return;
					}

					removeErrors();

					this.callbacks.onEmailSuccess(result.data);
				} else {
					showError(this.dom.emailInput, result.errors[0].message);
				}
			});
		});
	};

	kill = () => {
		removeAllEvents([this.dom.continueButton, this.dom.cancelButton]);
		this.callbacks.onCancelClick();
	};

	initialize() {
		const overviewHtml = addDependentMarkup(this.texts);

		emptyElement(this.dom.membershipContent);
		appendElement(overviewHtml, this.dom.membershipContent);

		this.dom.emailInput =
            this.dom.membershipContent.querySelector("#email");

		this.dom.continueButton =
            this.dom.container.querySelector(".button--continue");
		addEvent(this.dom.continueButton, "click", this.validateAndCheckEmail);

		this.dom.cancelButton =
            this.dom.container.querySelector(".button--cancel");
		addEvent(this.dom.cancelButton, "click", this.kill);
	}
}
