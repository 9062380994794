import { forEach } from "./forEach";

/**
 *
 * @param {Array|object|NodeList} collection - Collection to filter. Can be an array, an object or a NodeList.
 * @param {function} predicateFunction - Predicate function. Must return a truthy value for filter to include option.
 * @returns {Array|Object} An object of the same type as the input collection, containing all entries that pass the predicate function. However, if you pass a NodeList, it will come back as an array instead.
 * @deprecated Do not use this!
 */
export function filter(collection: any, predicateFunction: any) { // eslint-disable-line
	if (Array.isArray(collection)) {
		return collection.filter(predicateFunction);
	} else if (typeof collection === "object") {
		const filteredObject: any = {}; // eslint-disable-line
		forEach(collection, (value, key) => {
			if (Boolean(predicateFunction(value, key)) === true) {
				filteredObject[key] = value;
			}
		});

		// If a NodeList was passed, return as an array. If not, just throw an object back instead.
		return collection.constructor === NodeList
			? Object.values(filteredObject)
			: filteredObject;
	}

	return collection;
}
