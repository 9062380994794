import { parseHTML } from "~/foundation/Dom/elementManipulation";
import viewPasswordSvg from "~/foundation/Icons/view-password";
import { closeMarkup } from "../../../close";

export const resetPasswordMarkup = (labels, error, emailParam) => {
	const {
		headerTitle,
		emailLabel,
		passwordLabel,
		continueLabel,
		linkExpiredTitle,
		linkExpiredText,
		startOverLabel,
		passwordHelpText
	} = labels;

	if (error) {
		return parseHTML(`
            <div class="membership-header">
                ${closeMarkup}
            </div>
            <div class="membership-middle membership-message animate-translate">
                <h2 class="membership-header__title"><strong>${linkExpiredTitle}</strong></h2>
                ${linkExpiredText}
            </div>
            <div class="membership-footer">
                <button type="button" class="animate-scale cta cta--white">
                    <span>${startOverLabel}</span>
                </button>
            </div>
        `);
	}

	return parseHTML(`
    <div class="membership-header membership-header--reset-password">
        ${closeMarkup}
        <h2 class="membership-header__title"><strong>${headerTitle}</strong></h2>
    </div>
    <div class="membership-middle membership-middle--login-reset-password">
        <div class="animate-translate email-label">${emailLabel} <strong>${emailParam}</strong></div>
		<div class="animate-translate input">
            <label for="password">${passwordLabel}</label>
            <input autocomplete="off" required type="password" id="password" class="password" />
            <span class="error"></span>
            <button class="toggle-visible-password" type="button">${viewPasswordSvg}</button>
        </div>
        <div class="animate-translate password-guide">
            ${passwordHelpText}
        </div>
    </div>
    <div class="membership-footer">
        <button type="submit" class="animate-scale cta cta--white">
            <span>${continueLabel}</span>
        </button>
    </div>`);
};
