import { buildRadioButtonInput } from "../inputs/buildRadioButtonInput";

const stepThreeContentTemplate = ({ classes, model, sections }) => {
	const errorMessageStepThree = "step-three";

	return `
        <div class="${classes.rootClass}__event-info">
        <h4>${model.texts.signupForLabel} ${model.eventModel.name}</h4>
        <time
            data-async-module="local-datetime"
            data-date-format="event-date-time"
            data-utc-start="${model.eventModel.startDateUTC}"
            data-utc-end="${model.eventModel.endDateUTC}"
            data-date-fallback="${model.eventModel.dateString}"
            data-use-ksa="true"
        ></time>
        </div>
        <div class="${classes.rootClass}__section">
            <fieldset class="${classes.rootClass}__fieldset">
                <legend class="visuallyhidden">Step 3</legend>
                <div class="${classes.rootClass}__radio-group ${
	classes.rootClass
}__radio-group--button">
                    <h3>${sections.title}</h3>
                    <ul class="${classes.rootClass}__stacking-list">
                        ${buildRadioButtonInput(
		sections.fields[0],
		errorMessageStepThree
	)}
                    </ul>
                    <p class="${errorMessageStepThree}"></p>
                </div>
            </fieldset>
        </div>
    `;
};

export default stepThreeContentTemplate;
