import { appendElement } from "~/foundation/Dom/elementManipulation";
import { enterNameMarkup } from "./markup";
import { addEvent, removeAllEvents } from "~/foundation/Events/events";
import { showError, removeErrors } from "~/foundation/Form/input/input-errors";

export class EnterName {
	/**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
	dom = {};

	/**
     *
     * @param {Element} domReference - The element to work from. -> membership__content
     */
	constructor(config) {
		this.dom.membershipContent = config.membershipContent;
		this.texts = config.texts;
		this.user = config.user ? config.user : {};
		this.errorMessages = config.errorMessages;

		this.callbacks = {
			onContinue: config.onContinue
		};

		this.initialize();
	}

	validateNameScreen = () => {
		if (this.dom.firstNameInput.value === "") {
			showError(
				this.dom.firstNameInput,
				this.errorMessages.firstNameRequired
			);
			return;
		}

		if (this.dom.lastNameInput.value === "") {
			showError(
				this.dom.lastNameInput,
				this.errorMessages.lastNameRequired
			);
			return;
		}

		removeErrors();

		removeAllEvents(this.dom.continueButton);
		this.callbacks.onContinue(
			this.dom.firstNameInput.value,
			this.dom.lastNameInput.value
		);
	};

	initialize() {
		const enterNameHtml = enterNameMarkup(this.texts);

		appendElement(enterNameHtml, this.dom.membershipContent);

		this.dom.membershipContent.className =
            "membership__content membership__content--signup-name";
		this.dom.firstNameInput =
            this.dom.membershipContent.querySelector("#firstName");
		this.dom.lastNameInput =
            this.dom.membershipContent.querySelector("#lastName");

		if (this.user.firstName) {
			this.dom.firstNameInput.value = this.user.firstName;
		}

		if (this.user.lastName) {
			this.dom.lastNameInput.value = this.user.lastName;
		}

		this.dom.continueButton = this.dom.membershipContent.querySelector(
			".membership-footer .cta"
		);
		addEvent(this.dom.continueButton, "click", this.validateNameScreen);
	}
}
