import { onClickOutside, removeOnClickOutside } from "~/foundation/Events/onClickOutside.ts";
import { disableScrollLock, enableScrollLock } from "~/foundation/Dom/scrollLock.ts";
import closeSvg from "~/foundation/Icons/close";
import { themeClassPrimary } from "~/foundation/Theme/theme-context.ts"
import { addLoader } from "~/foundation/Loaders/loader.ts";
import { globalDictionary } from "~/foundation/Dictionary";
import { type ColorTheme } from "~/foundation/Theme";

let dialogElement: HTMLDialogElement | null = null;
let dialogContentElement: HTMLDivElement | null = null;

export type UseDialog = ReturnType<typeof useDialog>;

export const useDialog = () => {

	const close = () => {
		dialogContentElement!.innerHTML = "";
		dialogElement!.close();

		// Restore classnames.
		dialogElement!.className = "";
		dialogElement!.classList.add(themeClassPrimary);

		disableScrollLock();
		removeOnClickOutside(dialogElement!, close, window);
	}

	const createDialog = () => {
		if (!dialogElement || !dialogContentElement) {

			dialogElement = document.createElement("dialog");
			dialogElement.classList.add(themeClassPrimary);

			dialogContentElement = document.createElement("div");
			dialogContentElement.classList.add("dialog-content");

			const closeButton = document.createElement("button");
			closeButton.type = "button";
			closeButton.classList.add("dialog__close");
			closeButton.setAttribute('aria-label', 'Close Dialog');
			closeButton.innerHTML = /*html*/`
				${closeSvg}
				<span>${globalDictionary.closeLabel}</span>
			`;

			dialogElement.appendChild(closeButton);
			dialogElement.appendChild(dialogContentElement);

			closeButton.addEventListener("click", () => {
				close();
			});

			document.body.appendChild(dialogElement);
		}
	}

	createDialog();

	return {
		open: async (html: string | null = null, themeClassOverride?: ColorTheme["name"], focusBefore?: HTMLElement | null) => {

			if (focusBefore) {
				focusBefore.tabIndex = -1;
				focusBefore.focus();
			}

			if (!html) {
				dialogContentElement!.innerHTML = "";
				addLoader(dialogContentElement!);
			} else {
				dialogContentElement!.innerHTML = html || "";
			}

			// Override classname.
			if (themeClassOverride) {
				dialogElement?.classList.replace(themeClassPrimary, themeClassOverride)
			}
			dialogElement!.show();

			enableScrollLock();
			//photo-competition has clickoutside handling that conflicts with this causing dialog to fail to open
			if (!document.querySelector(".photo-competition")){
				onClickOutside(dialogElement!, close, window, true);
			}

			return new Promise<void>((resolve) => {
				requestAnimationFrame(() => { // requestAnimationFrame makes sure the content is queryable after using innerHTML
					resolve();
				});
			});
		},
		setContent: async (html: string) => {
			dialogContentElement!.innerHTML = html;

			return new Promise<void>((resolve) => {
				requestAnimationFrame(() => { // requestAnimationFrame makes sure the content is queryable after using innerHTML
					resolve();
				});
			});
		},
		close,
		element: dialogElement
	}

};
