const API_VERSION = "/api/v1";

const MEMBERSHIP_USER = `${API_VERSION}/membership/user`;
const MEMBERSHIP_SIGNUP_START = `${API_VERSION}/membership/signup/start`;
const MEMBERSHIP_SELECTTOPICS_START = `${API_VERSION}/membership/topicscores/start`;
const MEMBERSHIP_PASSWORD_START = `${API_VERSION}/membership/password/start`;
const MEMBERSHIP_PROFILE_START = `${API_VERSION}/membership/profile/start`;
const MEMBERSHIP_DEPENDENT_START = `${API_VERSION}/membership/dependent/start`;
const MEMBERSHIP_LOGIN = `${API_VERSION}/membership/login/start`;
const MEMBERSHIP_LOGOUT = `${API_VERSION}/membership/logout`;
const CHECK_USER = `${API_VERSION}/membership/checkuser`;

export {
	MEMBERSHIP_USER,
	MEMBERSHIP_SIGNUP_START,
	MEMBERSHIP_SELECTTOPICS_START,
	MEMBERSHIP_PASSWORD_START,
	MEMBERSHIP_LOGIN,
	MEMBERSHIP_LOGOUT,
	MEMBERSHIP_PROFILE_START,
	MEMBERSHIP_DEPENDENT_START,
	CHECK_USER
}