/* eslint-disable @typescript-eslint/no-explicit-any */
import { forEach } from "./forEach";

const searchParams: Record<string, URLSearchParams> = {};

function init(url: string) {
	// Get url without query string
	const baseUrl = url.includes("?")
		? url.substring(0, url.indexOf("?"))
		: url;

	// If baseUrl is new, initialize it
	if (!searchParams[baseUrl]) {
		searchParams[baseUrl] = new URL(url).searchParams;
	}
	// Return the base url
	return baseUrl;
}

function removeFromArray(array: string[], element: string) {
	// Find the element's index in the array
	const index = array.indexOf(element);

	// If the element exists in the array, remove it
	if (index > -1) {
		array.splice(index, 1);
	}

	// Return the array, with or without changes
	return array;
}

/**
 * Helper function that infers the correct datatype from string - SHOULD BE A GENERAL UTILITY
 */
function getDatatype(value: string): any {
	if (value === "undefined") {
		return undefined;
	}

	const regexp = /^(true|false|null)$/;

	return regexp.test(value) ? JSON.parse(value) : Number(value) || value;
}

export function getQueryString(url = window.location.href) {
	const currentUrl = init(url);

	return searchParams[currentUrl].toString();
}

export function getParameter(key: string, url = window.location.href): string[] {
	const currentUrl = init(url);

	function setParamsObj(key: string) {
		const results = searchParams[currentUrl].getAll(key);
		let realResults: Array<string> = [];

		if (results.length) {
			if (results.length === 1) {
				realResults = [getDatatype(results[0])];
			} else {
				forEach(results, result => {
					realResults.push(getDatatype(result));
				});
			}
		}

		return realResults;
	}

	return setParamsObj(key);
}

export function addParameter(
	key: string,
	value: string | string[],
	override = true,
	url = window.location.href
) {
	const currentUrl = init(url);

	// Clear current value(s)
	if (override) {
		searchParams[currentUrl].delete(key);
	}

	// If multiple values
	if (Array.isArray(value)) {
		forEach(value, val => {
			searchParams[currentUrl].append(key, val);
		});
	} else if (typeof key === "string") {
		searchParams[currentUrl].append(key, value);
	}
}

export function deleteParameter(key: string, value = false, url: string = window.location.href) {
	const currentUrl = init(url);

	// If key is a string and a value is parsed
	if (typeof key === "string" && value) {
		// Cache all current values
		let values = searchParams[currentUrl].getAll(key);

		// Remove parsed value(s) from the array of all values
		if (Array.isArray(value)) {
			forEach(value, val => {
				values = removeFromArray(values, val);
			});
		} else if (typeof value === "string") {
			values = removeFromArray(values, value);
		}

		// Delete all values for the parsed key
		searchParams[currentUrl].delete(key);

		// Add the remaining values back into the object
		forEach(values, val => {
			searchParams[currentUrl].append(key, val);
		});
	} else {
		// If no value parameter is parsed, delete all values for the parsed key
		if (Array.isArray(key)) {
			forEach(key, k => {
				searchParams[currentUrl].delete(k);
			});
		} else if (typeof key === "string") {
			searchParams[currentUrl].delete(key);
		}
	}
}
