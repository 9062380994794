import { onReady } from "~/foundation/Events/onReady";
import { addEvent, delegateEvent, removeEvent } from "~/foundation/Events/events";
import { onWindowResize } from "~/foundation/Events/onWindowResize";
import { forEach } from "~/foundation/Helpers/forEach";
import stepThreeTemplate from "./templates/step-three";
import stepFourTemplate from "./templates/step-four";
import stepTwoTemplate from "./templates/step-two";
import stepOneTemplate from "./templates/step-one";
import anime from "animejs";
import { FormValidate } from "~/foundation/FormValidate";
import { fetcher } from "~/foundation/Api";
import { appendElement, parseHTML } from "~/foundation/Dom/elementManipulation";
import { addClass, removeClass } from "~/foundation/Dom/classList";
import { STANDARDCUBICBEZIER } from "~/foundation/Styles/easings";
import { MEDIUM } from "~/foundation/Styles/durations";
import html2canvas from "html2canvas";
import "./greeting.scss";

export class Greeting {
	/**
		 * Internal placeholder for cached DOM-objects.
		 *
		 * @type {object}
		 * @ignore
		 */
	dom = {
		container: undefined
	};

	classes = {
		rootClass: "greeting",
		cardClass: "greeting-card"
	};

	/**
		 *
		 * @param {Element} domReference - The element to work from.
		 */
	constructor(domReference) {
		this.dom.container = domReference;

		this.dom.form = this.dom.container.querySelector("form");

		this.dom.stepsContainer = this.dom.container.querySelector(
			`.${this.classes.rootClass}__steps`
		);

		this.dom.continueButtons = this.dom.container.querySelectorAll(
			`.${this.classes.rootClass}__buttons--continue`
		);

		this.dom.backButtons = this.dom.container.querySelectorAll(
			`.${this.classes.rootClass}__buttons--back`
		);

		this.dom.stepsIndicatorContainer = this.dom.container.querySelector(
			`.${this.classes.rootClass}__steps-indicator`
		);

		this.classes = {
			...this.classes,
			formInvalidField: "form-validate__field--invalid"
		};

		onReady(() => this.initialize());
	}

	getSections = obj => {
		const result = {};
		const keys = Object.keys(obj);

		keys.forEach(key => {
			let entries = Object.entries(obj[key]).filter(
				([, subValue]) => !subValue?.hideSection
			);
			result[key] = Object.fromEntries(entries);
		});

		return result;
	};

	setupButtons = () => {
		const continueButtons = this.dom.container.querySelectorAll(`.${this.classes.rootClass}__buttons--continue`);
		const backButtons = this.dom.container.querySelectorAll(`.${this.classes.rootClass}__buttons--back`);

		continueButtons.forEach(button => {
			button.innerText = this.greetingDictionary.continueButtonLabel;
			addClass(button, `${this.classes.rootClass}__buttons--hidden`);
		});

		backButtons.forEach(button => {
			button.innerText = this.greetingDictionary.backButtonLabel;
			addClass(button, `${this.classes.rootClass}__buttons--hidden`);
		});
	};

	updateContinueButton = currentStep => {
		const footerContainer = this.dom.container.querySelector(`.${this.classes.rootClass}__footer`);
		const continueButtons = footerContainer.querySelectorAll(`.${this.classes.rootClass}__buttons--continue`);
		const backButtons = this.dom.container.querySelectorAll(`.${this.classes.rootClass}__buttons--back`);
		const footerBackButton = footerContainer.querySelector(`.${this.classes.rootClass}__buttons--back`);

		continueButtons.forEach(button => {
			if (currentStep <= this.settings.totalSteps) {
				button.dataset.stepNumber = currentStep;
				removeClass(button, `${this.classes.rootClass}__buttons--hidden`);
			}

			if (currentStep === 1) {
				addClass(button, `${this.classes.rootClass}__buttons--hidden`);
			}

			if (currentStep === this.settings.totalSteps) {
				addClass(button, `${this.classes.rootClass}__buttons--hidden`);
			} else if (currentStep < this.settings.totalSteps && currentStep !== 1) {
				removeClass(button, `${this.classes.rootClass}__buttons--hidden`);
			}

			if (currentStep === 3) {
				addClass(continueButtons, `${this.classes.rootClass}__buttons--hidden`);
			}
		});

		backButtons.forEach(button => {
			if (currentStep === 1) {
				addClass(button, `${this.classes.rootClass}__buttons--hidden`);
			} else {
				removeClass(button, `${this.classes.rootClass}__buttons--hidden`);
			}
		});

		if (currentStep === 4 || currentStep === 3) {
			addClass(footerBackButton, `${this.classes.rootClass}__buttons--hidden`);
		}

		if (currentStep === 3) {
			const stepThreeContinueButton = this.dom.container.querySelector(`.${this.classes.rootClass}__step-3 .${this.classes.rootClass}__buttons--continue`);
			removeClass(stepThreeContinueButton, `${this.classes.rootClass}__buttons--hidden`);
		}
	};

	createStepIndicator = (stepNumber, currentStep) => {
		return `<div data-step-number="${stepNumber}" class="${this.classes.rootClass
			}__steps-indicator-step ${stepNumber === currentStep
				? `${this.classes.rootClass}__steps-indicator-step--active`
				: ""
			}"></div>`;
	};

	buildStepsIndicator = () => {
		const MAX_STEPS = this.settings.totalSteps;

		this.dom.stepsIndicatorContainer.innerHTML = [
			...Array(MAX_STEPS).keys()
		]
			.map(i => this.createStepIndicator(i + 1, this.currentStep))
			.join("");
	};

	// BUILDER FUNCTIONS
	buildStepWrapper = (stepContent, stepNumber) => {
		return `
            <div class=${this.classes.rootClass
			}__step data-step=${stepNumber} ${stepNumber > 1 ? `hidden` : ``}>
                ${stepContent}
            </div>`;
	};

	// Move out / create 'factory'-ish func if same structure is followed
	buildStepFromSections = (sections, stepTemplateId, iconRadioButtonOptions) => {
		const model = this.shownSections;
		const classes = this.classes;
		const texts = this.greetingDictionary;
		const totalSteps = this.settings.totalSteps;
		const stepTextDictionary = this.greetingDictionary.stepText;
		const stepText = stepTextDictionary.replace("{0}", stepTemplateId).replace("{1}", totalSteps);
		let stepContent;

		if (!stepTextDictionary) {
			console.error("No step text dictionary found in greeting card data");
		}

		// eslint-disable-next-line default-case
		switch (stepTemplateId) {
			case 1:
				stepContent = stepOneTemplate({
					classes,
					model,
					sections,
					texts,
					iconRadioButtonOptions,
					stepText
				});
				break;
			case 2:
				stepContent = stepTwoTemplate({
					classes,
					model,
					sections,
					stepText
				});
				break;
			case 3:
				stepContent = stepThreeTemplate({
					classes,
					model,
					sections,
					stepText
				});
				break;
			case 4:
				stepContent = stepFourTemplate({
					classes,
					model,
					sections,
					stepText
				});
				break;
		}
		return this.buildStepWrapper(stepContent, stepTemplateId);
	};

	fetchData = () => {
		// Fetch the data
		fetcher(this.settings.endPoint.replace("{context}", this.settings.contextId)).then(({ data }) => {
			// All the sections
			this.allSections = data;
			this.iconRadioButtonOptions = data.iconRadioButtonOptions;

			// Filter out the sections that is hidden (hideSection: true)
			this.shownSections = this.getSections(data);
			// Maybe we need this??
			this.stepLabels = Object.keys(this.shownSections.formFieldSections);
			// The "dictionary" part of the response
			this.greetingDictionary = data.texts;

			window.dataLayer = window.dataLayer || [];

			// STEP 1 -> USER INFO
			if (this.shownSections.formFieldSections["section-choose-card"]) {
				appendElement(
					parseHTML(
						this.buildStepFromSections(
							this.shownSections.formFieldSections[
								"section-choose-card"
							],
							1,
							this.iconRadioButtonOptions
						)
					),
					this.dom.stepsContainer
				);
			}

			// STEP 2
			if (this.shownSections.formFieldSections["section-enter-message"]) {
				appendElement(
					parseHTML(
						this.buildStepFromSections(
							this.shownSections.formFieldSections[
								"section-enter-message"
							],
							2
						)
					),
					this.dom.stepsContainer
				);
			}

			// STEP 3
			if (this.shownSections.formFieldSections["section-preview-card"]) {
				appendElement(
					parseHTML(
						this.buildStepFromSections(
							this.shownSections.formFieldSections[
								"section-preview-card"
							],
							3
						)
					),
					this.dom.stepsContainer
				);

				const stepThreeContainer = this.dom.container.querySelector(`.${this.classes.rootClass}__step-3`);
				const stepThreeBackButton = stepThreeContainer.querySelector(`.${this.classes.rootClass}__buttons--back`);
				const stepThreeContinueButton = stepThreeContainer.querySelector(`.${this.classes.rootClass}__buttons--continue`);

				addEvent(stepThreeBackButton, "click", () => {
					if (this.currentStep > 1) {
						this.moveSteps(this.currentStep - 1);
					}
				});

				addEvent(stepThreeContinueButton, "click", () => {
					if (this.currentStep > 1) {
						this.moveSteps(this.currentStep + 1);
					}
				});
			}

			// STEP 4
			if (this.shownSections.formFieldSections["section-share-card"]) {
				appendElement(
					parseHTML(
						this.buildStepFromSections(
							this.shownSections.formFieldSections[
								"section-share-card"
							],
							4
						)
					),
					this.dom.stepsContainer
				);

				// This is added as the footer back button is hidden, and a new one is added inside the step four container
				// To align with the new layout
				// Instead of us calling setupLazyEvents again, so the events pile up, we just add the event here
				const stepFourContainer = this.dom.container.querySelector(`.${this.classes.rootClass}__step-4`);
				const stepFourBackButton = stepFourContainer.querySelector(`.${this.classes.rootClass}__buttons--back`);
				addEvent(stepFourBackButton, "click", () => {
					if (this.currentStep > 1) {
						this.moveSteps(this.currentStep - 1);
					}
				});
			}

			this.dom.stepCollection = this.dom.stepsContainer.querySelectorAll(
				`.${this.classes.rootClass}__step`
			);

			this.dom.previewContainers =
				this.dom.stepsContainer.querySelectorAll(
					`.${this.classes.rootClass}__preview-container`
				);

			this.dom.currentStepElement =
				this.dom.stepsContainer.querySelector(`[data-step="1"]`);

			this.buildStepsIndicator();
			this.setupButtons();

			// Adding validation for form fields
			this.formValidate = new FormValidate(this.dom.container, {
				saveDataWhenOffline: false,
				beforeSubmit: () => {
					// Previous errors (if any) have been removed from all fields and validation is about to run.
					// If you have any code you wish to execute before validation, you can put it here.
				},
				onSubmit: () => { },
				errorMessageTargetClass: `${this.classes.rootClass}__error-message-target`
			});
		});
	};

	checkValidity = stepToMoveTo => {
		forEach(
			this.dom.currentStepElement.querySelectorAll(
				"input[required],textarea[required]"
			),
			currentStepInput => {
				if (currentStepInput) {
					this.formValidate.checkFieldFromElement(currentStepInput);
				}
			}
		);

		const invalidField = this.dom.currentStepElement.querySelector(
			`.${this.classes.formInvalidField}`
		);

		return new Promise((resolve, reject) => {
			if (!invalidField || this.currentStep > stepToMoveTo) {
				resolve("Success validating inputs");
			} else {
				reject(invalidField);
				window.console.warn("ERROR", this.classes.formInvalidField);
				// Inputs did not validate!
			}
		});
	};

	collectStepData = () => {
		const checkboxValues = [];

		forEach(
			this.dom.currentStepElement.querySelectorAll("input, textarea"),
			inputField => {
				if (inputField.type === "radio") {
					if (inputField.checked) {
						this.greetingData[inputField.name] = inputField.value;
					}
				} else if (inputField.type === "checkbox") {
					if (inputField.checked) {
						checkboxValues.push(inputField.value);

						this.greetingData[inputField.name] =
							checkboxValues.join(",");
					}
				} else {
					this.greetingData[inputField.name] = inputField.value;
				}
			}
		);
	};

	animateStepContent = stepToMoveTo => {
		anime
			.timeline({
				easing: STANDARDCUBICBEZIER
			})
			.add({
				targets: this.dom.stepsContainer,
				opacity: [1, 0],
				easing: STANDARDCUBICBEZIER,
				duration: MEDIUM,
				complete: () => {
					forEach(this.dom.stepCollection, step => {
						step.setAttribute("hidden", true);
					});

					this.dom.currentStepElement.removeAttribute("hidden");
				}
			})
			.add({
				targets: this.dom.stepsContainer,
				opacity: [0, 1],
				easing: STANDARDCUBICBEZIER,
				duration: MEDIUM
			});
	};

	moveSteps = stepToMoveTo => {
		// Checking if the fields of the current step are valid
		this.checkValidity(stepToMoveTo)
			// eslint-disable-next-line no-unused-vars
			.then(success => {
				// Updating the current step
				this.currentStep = stepToMoveTo;

				// Scrolling content
				this.animateStepContent(stepToMoveTo);

				// Updated steps indicator, move background and update the continue button.
				this.buildStepsIndicator(stepToMoveTo);
				this.updateContinueButton(stepToMoveTo);

				// Storing data on each step
				this.collectStepData();

				// TRACKING
				// Push greeting card step 2 to dataLayer when navigating to step 4
				if (stepToMoveTo === 4) {
					window.dataLayer.push({
						event: "greetingCreated",
						type: Object.values(this.greetingData)[0]
					});
				}

				if (stepToMoveTo !== 1 && stepToMoveTo !== 2) {
					const currentPreviewContainer = this.dom.currentStepElement.querySelector(`.${this.classes.rootClass}__preview-container`);

					let radialGradientId = undefined;
					let clipPathId = undefined;
					let linearGradientId = undefined;

					if (currentPreviewContainer) {
						this.greetingCardMarkup = currentPreviewContainer.innerHTML;
					}

					forEach(this.dom.previewContainers, previewContainer => {
						const newRadialGradientId = Math.random()
							.toString()
							.substring(2, 8);
						const newLinearGradientId = Math.random()
							.toString()
							.substring(2, 8);

						previewContainer.innerHTML = this.greetingCardMarkup;

						const clipPathElements = previewContainer.querySelectorAll("clipPath");
						if (clipPathElements.length > 0) {
							forEach(clipPathElements, clipPathElement => {
								const newClipPathId = Math.random()
									.toString()
									.substring(2, 8);

								clipPathId = clipPathElement.id;

								clipPathElement.setAttribute("id", newClipPathId);

								const clipPathTargets = previewContainer.querySelectorAll(`[clip-path="url(#${clipPathId})"]`);

								if (clipPathTargets.length > 0) {
									forEach(clipPathTargets, clipPathTarget => {
										clipPathTarget.setAttribute("clip-path", `url(#${newClipPathId})`);
									});
								}
							});
						}

						const radialGradientElement = previewContainer.querySelector("radialGradient");

						if (radialGradientElement) {
							radialGradientId = radialGradientElement.id;
							const logoRect = previewContainer.querySelector(`rect[fill="url(#${radialGradientId})"]`)
								|| previewContainer.querySelector(`[style="fill: url(#${radialGradientId})"]`);

							radialGradientElement.setAttribute("id", newRadialGradientId);

							if (logoRect) {
								logoRect.setAttribute(
									"fill",
									`url(#${newRadialGradientId})`
								);
								logoRect.style.fill = `url(#${newRadialGradientId})`;
							}
						}

						const linearGradientElement =
							previewContainer.querySelector("linearGradient");

						if (linearGradientElement) {
							linearGradientId = linearGradientElement.id;
							const linearElement =
								previewContainer.querySelector(
									`rect[fill="url(#${linearGradientId})"]`
								) ||
								previewContainer.querySelector(
									`[style="fill: url(#${linearGradientId})"]`
								) ||
								previewContainer.querySelector(
									`[fill="url(#${linearGradientId})"]`
								);

							linearGradientElement.setAttribute(
								"id",
								newLinearGradientId
							);

							if (linearElement) {
								linearElement.setAttribute(
									"fill",
									`url(#${newLinearGradientId})`
								);
								linearElement.style.fill = `url(#${newLinearGradientId})`;
							}
						}
					});
				}

				// Updating currentStepElement
				this.dom.currentStepElement = this.dom.container.querySelector(
					`[data-step="${this.currentStep}"]`
				);

				if (stepToMoveTo === 3) {
					this.addGreetingText();
					this.setupGhostTyping(
						this.dom.stepsContainer.querySelector(
							`.${this.classes.rootClass}__ghost-input input[name=name]`
						),
						this.dom.currentStepElement.querySelector(
							`.${this.classes.cardClass}__author`
						)
					);
					this.setupGhostTyping(
						this.dom.stepsContainer.querySelector(
							`.${this.classes.rootClass}__ghost-input input[name=headline]`
						),
						this.dom.currentStepElement.querySelector(
							`.${this.classes.cardClass}__default-message`
						)
					);
				}
			})
			.catch(invalidField => {
				window.console.warn(`Invalid field:`, invalidField);
			});
	};

	addGreetingText = () => {
		let greetingMessage;
		let greetingMessageContainer =
			this.dom.currentStepElement.querySelector(
				`.${this.classes.cardClass}__message`
			);

		this.dom.greetingContainer = this.dom.currentStepElement.querySelector(
			`.${this.classes.cardClass}__greeting`
		);

		//if custom greeting radio is selected we pull the message from textarea instead of radio value
		if (
			this.dom.stepsContainer.querySelector(
				`.${this.classes.rootClass}__radio input[data-textarea-trigger]:checked`
			)
		) {
			greetingMessage = this.dom.stepsContainer.querySelector(
				`.${this.classes.rootClass}__textarea-group--radio textarea`
			).value;
		} else {
			greetingMessage = this.dom.stepsContainer.querySelector(
				`.${this.classes.rootClass}__radio input:checked`
			).value;
		}

		if (greetingMessage !== "") {
			if (greetingMessageContainer) {
				greetingMessageContainer.innerText = greetingMessage;
			} else {
				this.dom.greetingContainer.insertAdjacentHTML(
					"afterbegin",
					`<p class="${this.classes.cardClass}__message">${greetingMessage}</p>`
				);
			}
		}
	};

	setupGhostTyping = (input, output) => {
		if (input.value !== "") {
			output.innerText = input.value;
		}

		removeEvent(input, "keyup");

		addEvent(input, "keyup", event => {
			output.innerText = event.target.value;
		});
	};

	setupLazyEvents = () => {
		addEvent(this.dom.form, "keypress", e => {
			const key = e.charCode || e.keyCode || 0;
			if (e.target.nodeName === "INPUT" && key === 13) {
				e.preventDefault();
			}
		});

		// Dynamically select continue buttons
		const continueButtons = this.dom.container.querySelectorAll(`.${this.classes.rootClass}__buttons--continue`);
		continueButtons.forEach(button => {
			addEvent(button, "click", event => {
				if (parseInt(event.target.dataset.stepNumber) < this.settings.totalSteps) {
					this.moveSteps(parseInt(event.target.dataset.stepNumber) + 1);
				}
			});
		});

		// Dynamically select back buttons
		const backButtons = this.dom.container.querySelectorAll(`.${this.classes.rootClass}__buttons--back`);
		backButtons.forEach(button => {
			addEvent(button, "click", () => {
				if (this.currentStep > 1) {
					this.moveSteps(this.currentStep - 1);
				}
			});
		});

		delegateEvent(".greeting__download-image-button", "click", () => {
			let nodeToConvert = this.dom.container.querySelector(
				`.${this.classes.rootClass}__conversion-node`
			);

			html2canvas(nodeToConvert, {}).then(function (canvas) {
				var ctx = canvas.getContext('2d');
				ctx.drawImage(canvas, 0, 0);
				var dataURL = canvas.toDataURL();

				const link = document.createElement("a");
				link.download = `greeting-card.jpeg`;
				link.href = dataURL;
				link.click();
			});
		});

		delegateEvent(
			".greeting-card__slider .gallery-cell .greeting-card input",
			"click",
			event => {
				this.greetingCardMarkup = event.target.parentNode.outerHTML;

				this.moveSteps(this.currentStep + 1);
			}
		);
	};

	loadGreetingData = () => {
		this.settings.endPoint && this.fetchData();

		this.dom.continueButtons.forEach(button => {
			button.disabled = false;
		});

		if (this.dom.currentStepElement) {
			anime({
				targets: this.dom.container,
				opacity: [0, 1],
				easing: STANDARDCUBICBEZIER,
				duration: MEDIUM
			});
		}

		this.setupLazyEvents();
	};

	initialize() {
		this.settings = {
			contextId: this.dom.container.dataset.contextid,
			endPoint: this.dom.container.dataset.endpoint,
			totalSteps: 4
		};

		this.greetingData = {};
		this.greetingCardMarkup = "";
		this.greetingCardSlider = undefined;

		this.currentStep = 1;

		this.loadGreetingData();
	}
}
