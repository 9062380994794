export function showError(input, message) {
	const errorMessageContainer = input.parentNode.querySelector(".error");
	input.parentNode.classList.add("input--error");
	errorMessageContainer.innerHTML = message;
}

export function removeError(input) {
	const errorMessageContainer = input.parentNode.querySelector(".error");
	input.parentNode.classList.remove("input--error");
	if (errorMessageContainer) {
		errorMessageContainer.innerText = "";
	}
}

export function removeErrors() {
	const errorMessages = document.querySelectorAll(".input--error");
	for (let index = 0; index < errorMessages.length; index++) {
		const element = errorMessages[index];
		element.classList.remove("input--error");
	}
}
