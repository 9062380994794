import { onReady } from "~/foundation/Events/onReady";
import { addEvent, removeAllEvents } from "~/foundation/Events/events";
import { emptyElement } from "~/foundation/Dom/elementManipulation";
import { SelectLanguageScreen } from "./signup/screens/select-language/select-language";
import { EnterName } from "./signup/screens/enter-name/enter-name";
import { EnterEmail } from "./signup/screens/enter-email/enter-email";
import { Password } from "./signup/screens/password/password";
import { SelectTopics } from "./signup/screens/select-topics/select-topics";
import { Splash } from "./signup/screens/splash/splash";
import { Confirmation } from "./signup/screens/confirmation/confirmation";
import { fetcher } from "~/foundation/Api";
import { MEMBERSHIP_SIGNUP_START, MEMBERSHIP_SELECTTOPICS_START, MEMBERSHIP_PASSWORD_START, MEMBERSHIP_PROFILE_START, MEMBERSHIP_DEPENDENT_START, MEMBERSHIP_LOGIN, CHECK_USER } from "./constants";
import { getParameter } from "~/foundation/Helpers/queryString";
import { setCookie, getCookie } from "~/foundation/Helpers/cookie";
// import { triggerCustomEvent } from "~/foundation/Events/triggerCustomEvent";
// import { destroyEventName } from "~/foundation/Bootstrapper";
import { STANDARDCUBICBEZIER } from "~/foundation/Styles/easings";
import { isRtl } from "~/foundation/Helpers/isRtl";
import { Login } from "./login/screens/login/login";
import { ForgotPassword } from "./login/screens/forgot-password/forgot-password";
import LazyLoad from "vanilla-lazyload";
import { currentWindowWidth, currentWindowHeight } from "~/foundation/Events/onWindowResize";
import anime from "animejs";
import { enableScrollLock, disableScrollLock } from "~/foundation/Dom/scrollLock";
import { ForgotPasswordConfirmation } from "./login/screens/forgot-password-confirmation/forgot-password-confirmation";
import { ResetPassword } from "./login/screens/reset-password/reset-password";
import { Profile } from "./profile/profile";
import { forEach } from "~/foundation/Helpers/forEach";
import { Dependent } from "./profile/dependent/dependent";
import { EditName } from "./profile/edit-name/edit-name";
import { ChangePassword } from "./profile/change-password/change-password";
import { Snackbar } from "~/foundation/Components/snackbar/snackbar";

export const closeMembershipCookie = "closeMembership";
export const closeMembershipCookieValue = true;

export const membershipStartPages = {
	LOGIN: "login",
	SIGNUP: "signup",
	PROFILE: "profile",
	DEPENDENT: "dependent",
	SELECT_TOPICS: "select-topics",
	RESET_PASSWORD: "reset-password"
};

export const customMembershipTriggers = [
	"data-select-topics",
	"data-reset-password",
	"data-signup"
];

export class Membership {
	/**
		 * Internal placeholder for cached DOM-objects.
		 *
		 * @type {object}
		 * @ignore
		 */
	dom = {
		container: undefined
	};

	/**
		 *
		 * @param {Element} domReference - The element to work from.
		 */
	constructor(
		domReference,
		startPage = membershipStartPages.SIGNUP,
		data = {}
	) {
		this.dom.container = domReference;
		this.dom.membershipContent = this.dom.container.querySelector(
			".membership__content"
		);
		this.user = {};

		this.texts = {};
		this.loginTexts = {};
		this.loginApiEndPoints = {};
		this.apiEndPoints = {};
		this.languages = {};
		this.language = isRtl ? "ar" : "en";
		this.initialLanguage = this.language;
		this.topics = {};
		this.errorMessages = {};
		this.splashCookie = "splash";
		this.splashCookieValue = "shown";
		this.startPage = startPage;
		this.currentStep =
			getCookie(this.splashCookie) !== this.splashCookieValue ? 1 : 2;
		this.easing = STANDARDCUBICBEZIER;
		this.durationIn = 1500;
		this.durationOut = 500;
		this.lazyloadInstance = undefined;

		this.isAutomatedTest = this.dom.container.getAttribute("data-automated-test") === "true";
		this.showSelectTopics = this.dom.container.getAttribute("data-select-topics") === "true";
		this.showResetPassword = this.dom.container.getAttribute("data-reset-password") === "true";

		if (this.showSelectTopics) {
			this.startPage = membershipStartPages.SELECT_TOPICS;
		} else if (this.showResetPassword) {
			this.startPage = membershipStartPages.RESET_PASSWORD;
		}

		this.steps = {};
		this.data = data;

		emptyElement(this.dom.membershipContent);

		onReady(() => this.initialize());
	}

	splashScreen = () => {
		new Splash({
			membershipContent: this.dom.membershipContent,
			onSplashDone: () => {
				setCookie(this.splashCookie, this.splashCookieValue);
				this.navigate();
			}
		});
	};

	selectLanguageScreen = () => {
		new SelectLanguageScreen({
			membershipContent: this.dom.membershipContent,
			texts: this.texts,
			languages: this.languages,
			user: this.user,
			onChangeLanguage: languageCode => {
				this.language = languageCode;
				this.startPage = membershipStartPages.SIGNUP;
				this.initialize();
			},
			onSelectLanguage: languageCode => {
				this.user.languageCode = languageCode;
				this.navigate();
			},
			onInit: languageCode => {
				this.user.languageCode = languageCode;
			},
			onLoginButtonClick: () => {
				this.getLoginStart(this.language).then(() => {
					this.setupSteps();
					this.animate(false, "login");
				});
			}
		});
	};

	loginScreen = () => {
		new Login({
			membershipContent: this.dom.membershipContent,
			loginApiUrl: this.loginApiEndPoints.loginApiUrl,
			texts: {
				headerTitle: this.loginTexts.loginTitle,
				loginText: this.loginTexts.loginText,
				emailLabel: this.loginTexts.emailLabel,
				passwordLabel: this.loginTexts.passwordLabel,
				forgotPasswordLabel: this.loginTexts.forgotPasswordLinkText,
				logInLabel: this.loginTexts.loginButtonText,
				createAccountHeader: this.loginTexts.createAnAccountHeader,
				createAccountLabel: this.loginTexts.createAnAccountLinkText,
				loading: this.loginTexts.loadingText,
				emailAlreadyExist: this.loginTexts.emailAlreadyExist
			},
			errorMessages: {
				emailRequired: this.loginErrorMessages.emailRequired,
				invalideEmail: this.loginErrorMessages.invalideEmail,
				passwordRequired: this.loginErrorMessages.passwordRequired,
				genericMembershipError: this.loginErrorMessages.genericMembershipError
			},
			data: this.data,
			onCreateAccountClick: () => {
				this.getMembershipStart(this.language).then(() => {
					emptyElement(this.dom.membershipContent);
					this.steps[this.currentStep]();

					setTimeout(() => {
						this.animate();
					}, 50);
				});
			},
			onForgotPasswordClick: () => {
				this.animate(false, "forgotPassword");
			},
			onLoginSuccess: () => {
				// We check if user is isInternal to prevent users that do not have the authorization for seeing the link
				// "returnUrl" is given by the redirect from backend so that we can prompt with login overlay
				fetcher(CHECK_USER).then(({ data, success, errors }) => {
					if (success) {
						if (data.isInternal) {
							const paramUrl = getParameter("returnUrl")[0] || getParameter("ReturnUrl")[0] || "";

							// Since we do not have the querystring parameter on load, and it is pushed through window.history's state
							// we have to use the window.location object since it has the dynamic URL
							if (window.location.search.includes("?download=")) {
								// Since we are manually setting the window.location through window.history.pushState
								// we are sure the value to the key is our download link.
								window.open(window.location.search.split("=")[1], "_blank");
							}

							if (paramUrl && paramUrl.length > 0) {
								const redirectUrl = window.location.origin + paramUrl;
								// redirect to the url
								window.location.href = redirectUrl;
							} else {
								//added location.search to make sure params are kept in redirect - used by pdf-viewer.
								window.location = window.location.origin + window.location.pathname + window.location.search;
							}
						} else {
							window.location = window.location.origin + window.location.pathname;
						}
					} else {
						console.error("Error fetching data from user", errors.message);
					}
				});
				this.setCloseMembershipCookie();
			}
		});
		this.animate();
	};

	enterNameScreen = () => {
		new EnterName({
			membershipContent: this.dom.membershipContent,
			user: this.user,
			texts: {
				headerTitle: this.texts.introTitle,
				firstNameLabel: this.texts.firstNameLabel,
				lastNameLabel: this.texts.lastNameLabel,
				continueLabel: this.texts.continueButtonLabel
			},
			errorMessages: {
				firstNameRequired: this.errorMessages.firstNameRequired,
				lastNameRequired: this.errorMessages.lastNameRequired
			},
			onContinue: (firstName, lastName) => {
				this.user.firstName = firstName;
				this.user.lastName = lastName;
				this.navigate(true);
			}
		});
	};

	enterEmailScreen = () => {
		new EnterEmail({
			membershipContent: this.dom.membershipContent,
			verifyEmailApiUrl: this.apiEndPoints.verifyEmailApiUrl,
			user: this.user,
			texts: {
				headerTitle: this.texts.introTitle,
				emailLabel: this.texts.emailLabel,
				emailHelpText: this.texts.emailHelpText,
				continueLabel: this.texts.continueButtonLabel,
				loading: this.texts.loadingText
			},
			errorMessages: {
				emailRequired: this.errorMessages.emailRequired,
				invalideEmail: this.errorMessages.invalideEmail,
				accountAlreadyExists: this.errorMessages.accountAlreadyExists
			},
			onContinue: email => {
				this.user.email = email;
				this.navigate(true);
			}
		});
	};

	confirmationScreen = () => {
		new Confirmation({
			membershipContent: this.dom.membershipContent,
			resendSignupEmailApiUrl: this.apiEndPoints.resendSignupEmailApiUrl,
			user: this.user,
			texts: {
				confirmationText: this.texts.confirmationText.replace(
					"{email}",
					`${this.user.email}`
				),
				resendEmailText: this.texts.noEmailText,
				resendLabel: this.texts.noEmailLinkText
			},
			onResendEmailSuccess: () => {
				console.log("resend email success");
			}
		});
	};

	selectTopicsScreen = () => {
		new SelectTopics({
			container: this.dom.container,
			membershipContent: this.dom.membershipContent,
			saveTopicScoresApiUrl: this.apiEndPoints.saveTopicScoresApiUrl,
			texts: {
				headerTitle: `<strong${this.texts.introTitle}</strong>${this.texts.introText}`,
				topics: this.topics,
				continueLabel: this.texts.confirmationLinkText,
				skipLabel: this.texts.skipLinkText,
				confirmationText: this.texts.confirmationText,
				confirmationResponseText: this.texts.confirmationResponseText,
				pleaseSelectTopicsText: this.texts.pleaseSelectTopicsText
			},
			onSelectedTopicsSaved: () => {
				this.setCloseMembershipCookie();
				window.location =
					window.location.origin + window.location.pathname;
			},
			onSkipSelectTopics: () => {
				this.setCloseMembershipCookie();
				window.location =
					window.location.origin + window.location.pathname;
			}
		});
	};

	passwordScreen = () => {
		new Password({
			membershipContent: this.dom.membershipContent,
			signupApiUrl: this.apiEndPoints.signupApiUrl,
			user: this.user,
			texts: {
				headerTitle: this.texts.introTitle,
				passwordLabel: this.texts.passwordLabel,
				passwordHelpText: this.texts.passwordHelpText,
				termsText: this.texts.acceptTermsText,
				subscribeText: this.texts.acceptSubscribeText,
				continueLabel: this.texts.submitButtonLabel,
				loading: this.texts.loadingText,
				termsAndConditionUrl: this.texts.termsAndConditionUrl
			},
			errorMessages: {
				acceptTermsRequired: this.errorMessages.acceptTermsRequired,
				passwordRequired: this.errorMessages.passwordRequired
			},
			isAutomatedTest: this.isAutomatedTest,
			onSignupSuccess: () => {
				this.removePaginationListener();
				this.navigate();
				this.setCloseMembershipCookie();
			}
		});
	};

	forgotPasswordScreen = () => {
		new ForgotPassword({
			membershipContent: this.dom.membershipContent,
			forgotPasswordApiUrl: this.loginApiEndPoints.forgotPasswordApiUrl,
			validateEmailApiUrl: this.loginApiEndPoints.validateEmailApiUrl,
			user: this.user,
			texts: {
				headerTitle: this.loginTexts.forgotPasswordTitle,
				emailLabel: this.loginTexts.forgotPasswordEmailLabel,
				forgotPasswordText: this.loginTexts.forgotPasswordText,
				sendLinkLabel: this.loginTexts.forgotPasswordButtonLabel,
				goBackLabel: this.loginTexts.forgotPasswordBackButtonLinkText,
				loading: this.loginTexts.loadingText
			},
			errorMessages: {
				emailRequired: this.loginErrorMessages.emailRequired,
				invalideEmail: this.loginErrorMessages.invalideEmail,
				genericMembershipError:
					this.loginErrorMessages.genericMembershipError,
				noUserWithEmail: this.loginErrorMessages.noUserWithEmail
			},
			onSendEmailClick: () => {
				this.animate(false, "forgotPasswordConfirmation");
			},
			onLoginLinkClick: () => {
				this.animate(false, "login");
			}
		});

		this.animate();
	};

	forgotPasswordConfirmationScreen = () => {
		new ForgotPasswordConfirmation({
			membershipContent: this.dom.membershipContent,
			resendForgotPasswordApiUrl: this.loginApiEndPoints.forgotPasswordApiUrl,
			user: this.user,
			texts: {
				confirmationText: this.loginTexts.forgotPasswordConfirmationText.replace("{email}", this.user.email),
				resendEmailText: this.loginTexts.forgotPasswordNoEmailText,
				resendLabel: this.loginTexts.forgotPasswordResendEmailLinkText
			},
			onResendEmailSuccess: () => {
				console.log("resend email success");
			}
		});

		this.animate();
	};

	resetPasswordScreen = () => {
		new ResetPassword({
			membershipContent: this.dom.membershipContent,
			resetPasswordUrl: this.apiEndPoints.resetPasswordUrl,
			texts: {
				headerTitle: this.texts.title,
				emailLabel: this.texts.emailLabel,
				passwordLabel: this.texts.inputNewPassword,
				passwordHelpText: this.texts.passwordHelpText,
				continueLabel: this.texts.buttonLabel,
				startOverLabel: this.texts.startOverLabel,
				linkExpiredTitle: this.texts.linkExpiredLabel,
				linkExpiredText: this.texts.linkExpiredTextLabel,
				loading: this.texts.loadingText
			},
			errorMessages: {
				passwordRequired: this.errorMessages.passwordRequired
			},
			onSavePasswordSuccess: () => {
				this.setCloseMembershipCookie();
				window.location =
					window.location.origin + window.location.pathname;
			},
			onStartOverClick: () => {
				this.getLoginStart(this.language).then(() => {
					this.setupSteps();
					this.animate(false, "forgotPassword");
				});
			}
		});
	};

	profileScreen = () => {
		new Profile({
			container: this.dom.container,
			membershipContent: this.dom.membershipContent,
			animate: this.animate,
			languageCode: this.languageCode,
			subscribeUserApiUrl: this.apiEndPoints.subscribeUserApiUrl,
			unsubscribeUserApiUrl: this.apiEndPoints.unsubscribeUserApiUrl,
			logoutApiUrl: this.apiEndPoints.logoutApiUrl,
			logOutRedirectUrl: this.apiEndPoints.logOutRedirectUrl,
			deleteAccountApiUrl: this.apiEndPoints.deleteAccountApiUrl,
			deleteAccountRedirectUrl:
				this.apiEndPoints.deleteAccountRedirectUrl,
			user: this.user,
			subscriptionLists: this.subscriptions.subscriptionLists,
			dependentsLists: this.dependents.dependentsList,
			texts: {
				// labels with name changes
				headerTitle: this.texts.introTitle,
				editNameLabel: this.texts.editNameButtonLabel,
				changePasswordLabel: this.texts.changePasswordButtonLabel,
				deleteAccountLabel: this.texts.deleteAccountButtonLabel,
				logoutLabel: this.texts.logOutButtonLabel,
				// remaining labels
				...this.texts
			},
			errorMessages: this.errorMessages,
			onEditNameClick: () => {
				this.setupSteps();
				this.animate(false, "editName");
			},
			onChangePasswordClick: () => {
				this.setupSteps();
				this.animate(false, "changePassword");
			}
		});
		this.animate();
	};

	editNameScreen = () => {
		new EditName({
			animate: this.animate,
			membershipContent: this.dom.membershipContent,
			updateNamesApiUrl: this.apiEndPoints.updateNamesApiUrl,
			user: this.user,
			texts: this.texts,
			errorMessages: this.errorMessages,
			onNameUpdate: () => {
				this.initMembership(this.language, () => {
					this.dom.name =
						this.dom.membershipContent.querySelector(".lastName");
					setTimeout(() => {
						new Snackbar({
							element: this.dom.name,
							text: this.texts.profileUpdatedConfirmation,
							autoClose: true,
							confirm: false,
							position: isRtl ? "left" : "right"
						});
					}, 1000);
				});
			},
			onCancelClick: () => {
				this.animate(false, "profile");
			}
		});
		this.animate();
	};

	changePasswordScreen = () => {
		new ChangePassword({
			animate: this.animate,
			membershipContent: this.dom.membershipContent,
			changePasswordApiUrl: this.apiEndPoints.changePasswordApiUrl,
			user: this.user,
			texts: this.texts,
			errorMessages: this.errorMessages,
			onPasswordUpdate: () => {
				this.initMembership(this.language, () => {
					this.dom.password =
						this.dom.membershipContent.querySelector(
							".change-password-button"
						);
					setTimeout(() => {
						new Snackbar({
							element: this.dom.password,
							text: this.texts.changePasswordConfirmation,
							autoClose: true,
							confirm: false,
							position: isRtl ? "left" : "right"
						});
					}, 1000);
				});
			},
			onCancelClick: () => {
				this.animate(false, "profile");
			}
		});
		this.animate();
	};

	dependentScreen = () => {
		new Dependent({
			container: this.dom.container,
			membershipContent: this.dom.membershipContent,
			user: this.user,
			animate: this.animate,
			apiEndPoints: this.apiEndPoints,
			texts: this.texts,
			errorMessages: this.errorMessages
		});
	};

	setupSteps = () => {
		this.steps = {
			1: this.splashScreen,
			2: this.selectLanguageScreen,
			3: this.enterNameScreen,
			4: this.enterEmailScreen,
			5: this.passwordScreen,
			6: this.confirmationScreen,
			7: this.selectTopicsScreen,
			login: this.loginScreen,
			forgotPassword: this.forgotPasswordScreen,
			forgotPasswordConfirmation: this.forgotPasswordConfirmationScreen,
			resetPassword: this.resetPasswordScreen,
			profile: this.profileScreen,
			changePassword: this.changePasswordScreen,
			editName: this.editNameScreen,
			dependent: this.dependentScreen
		};
	};

	setCloseMembershipCookie = () => {
		setCookie(closeMembershipCookie, closeMembershipCookieValue, {
			days: 30
		});
	};

	kill = () => {
		forEach(customMembershipTriggers, trigger =>
			this.dom.container.setAttribute(trigger, false)
		);

		this.dom.container.style.display = "none";
		disableScrollLock();
		this.setCloseMembershipCookie();
		removeAllEvents(this.dom.closeButton);
		removeAllEvents(this.dom.continueButton);
		this.removePaginationListener();

		if (this.initialLanguage !== this.language) {
			window.location = window.location.origin + window.location.pathname;
		}
	};

	addCloseEvent = () => {
		this.dom.closeButton = this.dom.membershipContent.querySelector(".membership-close");
		this.dom.continueButton = this.dom.membershipContent.querySelector(".membership-footer .cta");

		addEvent(this.dom.closeButton, "click", this.kill);
	};

	removeCloseEvent = () => {
		removeAllEvents(this.dom.closeButton);
	};

	removePaginationListener = () => {
		const paginationItems = this.dom.membershipContent.querySelectorAll(".signup-pagination__item");
		removeAllEvents(paginationItems);
	};

	addPaginationListener = () => {
		const paginationItems = this.dom.membershipContent.querySelectorAll(".signup-pagination__item");

		if (paginationItems.length) {
			const currentPaginationItemId = parseInt(this.dom.membershipContent.querySelector(".signup-pagination__item--current").id);

			addEvent(paginationItems, "click", e => {
				const paginationitemId = parseInt(e.target.id);
				if (
					currentPaginationItemId !== paginationitemId &&
					paginationitemId > currentPaginationItemId
				) {
					if (
						paginationitemId === 2 &&
						this.user.firstName &&
						this.user.lastName
					) {
						// Email screen - only go to email screen if name is entered
						this.navigate();
					} else if (paginationitemId === 3 && this.user.email) {
						// password screen - only go to password screen is email is filled out
						this.navigate();
					}
				} else if (
					currentPaginationItemId !== paginationitemId &&
					paginationitemId < currentPaginationItemId
				) {
					this.navigate(true, false);
				}
			});
		}
	};

	addEventListeners = () => {
		this.addCloseEvent();
		this.addPaginationListener();
	};

	animate = (transitionIn = true, screenName) => {
		this.dom.continueButton = this.dom.membershipContent.querySelector(".membership-footer .cta");

		this.removePaginationListener();
		this.removeCloseEvent();

		const animateTranslate = this.dom.membershipContent.querySelectorAll(".animate-translate");
		const animateFade = transitionIn
			? this.dom.membershipContent.querySelectorAll(".animate-fade")
			: this.dom.membershipContent.querySelectorAll(".animate-fade:not(.animate-fade--once)");
		const animateScale = transitionIn
			? this.dom.membershipContent.querySelectorAll(".animate-scale:not(.animate-scale--out)")
			: this.dom.membershipContent.querySelectorAll(".animate-scale:not(.animate-scale--once)");

		if (this.dom.continueButton) {
			this.dom.continueButton.disabled = true;
		}

		if (transitionIn) {
			this.addEventListeners();
		}

		const firstInput = this.dom.membershipContent.querySelector("input");

		if (firstInput) {
			firstInput.focus();
		}

		const translateForwards = isRtl ? [-100, 0] : [100, 0];
		const translateBackwards = isRtl ? [0, 100] : [0, -100];

		const tl = anime.timeline({
			easing: this.easing,
			duration: transitionIn ? this.durationIn : this.durationOut,
			complete: () => {
				if (this.dom.continueButton) {
					this.dom.continueButton.disabled = false;
				}

				if (!transitionIn) {
					emptyElement(this.dom.membershipContent);
					if (screenName) {
						this.steps[screenName]();
					} else {
						this.steps[this.currentStep]();
						this.animate();
					}
				}
			}
		});

		if (animateTranslate.length) {
			tl.add(
				{
					targets: animateTranslate,
					translateX: transitionIn
						? translateForwards
						: translateBackwards,
					opacity: transitionIn ? [0, 1] : [1, 0],
					delay: anime.stagger(100)
				},
				0
			);
		}

		if (animateFade.length) {
			tl.add(
				{
					targets: animateFade,
					opacity: transitionIn ? [0, 1] : [1, 0],
					delay: anime.stagger(100)
				},
				0
			);
		}

		if (animateScale.length) {
			tl.add(
				{
					targets: animateScale,
					scale: transitionIn ? [0.95, 1] : [1, 0.95],
					opacity: transitionIn ? [0, 1] : [1, 0]
				},
				0
			);
		}
	};

	movePagination = (directionForward = true) => {
		const currentItem = this.dom.membershipContent.querySelector(
			".signup-pagination__item--current"
		);
		currentItem.classList.add(directionForward ? "forwards" : "backwards");
	};

	navigate = (movePagination, directionForward = true) => {
		this.currentStep = directionForward
			? this.currentStep + 1
			: this.currentStep - 1;

		if (movePagination) {
			this.movePagination(directionForward);
		}

		this.animate(false);
	};

	getMembershipStart(language) {
		return new Promise(resolve => {
			fetcher(`/${language + MEMBERSHIP_SIGNUP_START}`).then(
				({ data }) => {
					this.texts = data.texts;
					this.languages = data.languages;
					this.apiEndPoints = data.api;
					this.errorMessages = data.errorMessages;
					this.setupSteps();

					if (!this.languages[0].isSelected) {
						this.languages.reverse();
						this.user.languageCode = this.languages[1];
					} else {
						this.user.languageCode = this.languages[0];
					}

					resolve();
				}
			);
		});
	}

	getLoginStart(language) {
		return new Promise(resolve => {
			fetcher(`/${language + MEMBERSHIP_LOGIN}`).then(({ data }) => {
				this.loginTexts = data.texts;
				this.loginApiEndPoints = data.api;
				this.loginErrorMessages = data.errorMessages;

				resolve();
			});
		});
	}

	loadVideo() {
		const lazyLoadClass =
			currentWindowWidth > currentWindowHeight
				? ".lazy-video-landscape"
				: ".lazy-video-portrait";

		return new Promise((resolve, reject) => {
			const lazyloadConfig = {
				elements_selector: lazyLoadClass,
				class_loading: "lz-loading",
				class_loaded: "lz-loaded",
				callback_loaded: el => {
					resolve(el);
					this.videoLoaded = true;
					this.lazyloadInstance.destroy();
				},
				callback_error: el => {
					reject(el);
					this.lazyloadInstance.destroy();
				}
			};

			this.lazyloadInstance = new LazyLoad(lazyloadConfig);
			if (this.lazyloadInstance.toLoadCount === 0) {
				resolve();
			}
		});
	}

	initMembership = (language, callback = null) => {
		enableScrollLock();

		switch (this.startPage) {
			case membershipStartPages.LOGIN:
				this.getLoginStart(language).then(() => {
					this.setupSteps();
					this.animate(false, "login");
				});
				break;
			case membershipStartPages.PROFILE:
				fetcher(`/${language + MEMBERSHIP_PROFILE_START}`).then(
					({ data }) => {
						this.texts = data.texts;
						this.apiEndPoints = data.api;
						this.user = data.profileData;
						this.errorMessages = data.errorMessages;
						this.subscriptions = data.subscriptions;
						this.dependents = data.dependents;

						const splashDurationElem =
							document.querySelector(".splash-duration");
						splashDurationElem.style.opacity = 1;

						this.profileScreen();

						setTimeout(() => {
							this.animate();

							if (callback) {
								callback();
							}
						}, 50);
					}
				);
				break;
			case membershipStartPages.DEPENDENT:
				fetcher(`/${language + MEMBERSHIP_DEPENDENT_START}`).then(
					({ data }) => {
						this.texts = data.texts;
						this.apiEndPoints = data.api;
						this.user = data.profileData;
						this.errorMessages = data.errorMessages;

						const splashDurationElem =
							document.querySelector(".splash-duration");
						splashDurationElem.style.opacity = 1;

						this.dependentScreen();

						setTimeout(() => {
							this.animate();
						}, 50);
					}
				);
				break;
			case membershipStartPages.SELECT_TOPICS:
				fetcher(`/${language + MEMBERSHIP_SELECTTOPICS_START}`).then(
					({ data }) => {
						this.texts = data.texts;
						this.apiEndPoints = data.api;
						this.topics = data.topics;

						const splashDurationElem =
							document.querySelector(".splash-duration");
						splashDurationElem.style.opacity = 1;

						this.selectTopicsScreen();

						setTimeout(() => {
							this.animate();
						}, 50);
					}
				);
				break;
			case membershipStartPages.RESET_PASSWORD:
				fetcher(`/${language + MEMBERSHIP_PASSWORD_START}`).then(
					({ data }) => {
						this.texts = data.resetPasswordFormLabels;
						this.apiEndPoints = data.api;
						this.errorMessages = data.errorMessages;

						const splashDurationElem =
							document.querySelector(".splash-duration");
						splashDurationElem.style.opacity = 1;

						this.resetPasswordScreen();

						setTimeout(() => {
							this.animate();
						}, 50);
					}
				);
				break;
			case membershipStartPages.SIGNUP:
			default:
				this.getMembershipStart(language).then(() => {
					const splashDurationElem =
						document.querySelector(".splash-duration");

					if (this.currentStep === 1) {
						splashDurationElem.style.height = "0%";
					}

					splashDurationElem.style.opacity = 1;

					this.steps[this.currentStep]();

					setTimeout(() => {
						this.animate();
					}, 50);
				});
				break;
		}
	};

	initialize() {
		this.dom.container.style.display = "block";
		this.loadVideo().then(() => this.initMembership(this.language));
	}
}
