const stepFourTemplate = ({ classes, model, sections, stepText }) => {
	return `
				<div class="greeting__step-4">
					<div class="${classes.rootClass}__header">
							<p class="${classes.rootClass}__manchet">${stepText}</p>
							<h2 class="${classes.rootClass}__title">${sections.title}</h2>
					</div>
					<div class="${classes.rootClass}__section">
							<div class="${classes.rootClass}__preview-offset">
									<div class="${classes.rootClass}__preview-container ${classes.rootClass}__conversion-node"></div>
							</div>
							<div class="${classes.rootClass}__preview-container"></div>

							<div class="${classes.rootClass}__share-container">
								<h3>${model.texts.shareButtonsText}</h3>
								<div class="${classes.rootClass}__share-buttons">
										<button type="button" class="cta cta--outline greeting__buttons--back"></button>
										<button class="cta cta--primary greeting__download-image-button">${model.texts.downloadAsImageButtonLabel}</button>
								</div>
							</div>
					</div>
				</div>
    `;
};

export default stepFourTemplate;
