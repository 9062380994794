import { parseHTML } from "~/foundation/Dom/elementManipulation";
import { format, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { getLocalTimeZone } from "~/foundation/Helpers/getLocalTimeZone";
import { isRtl } from "~/foundation/Helpers/isRtl";
import { arSA } from "date-fns/locale";

const setDateString = data => {
	const localTimeZone = getLocalTimeZone;
	const formatOptions = isRtl ? { locale: arSA } : "";

	const publishDate = format(
		utcToZonedTime(parseISO(data.publishDate), localTimeZone),
		"MMMM d, hh:mm aaaa",
		formatOptions
	);

	return `${publishDate}`;
};

export const inTheNewsItemMarkup = data => {
	const { title, url, description, imageUrl } = data;
	return parseHTML(`
        <div class="in-the-news__item inview">
            <div class="in-the-news__date">
                <time>${setDateString(data)}</time>
            </div>${
	imageUrl
		? `<div class="in-the-news__image">
                 <img src=${imageUrl} /></div>`
		: ""
}
            <a href=${url} target="_blank" data-element="inTheNews" class="in-the-news__content">
                <h3>${title}</h3>
                <p>${description}</p>
            </a>
        </div>
    `);
};
