import Swiper from "swiper";
import { Autoplay, EffectCreative, Navigation, Pagination } from 'swiper/modules';
import { type Module } from "~/foundation/Bootstrapper/types";
import { breakpointIndex, currentBreakpoint, onWindowResize } from "~/foundation/Events/onWindowResize";
import { isMobile } from "~/foundation/Helpers/isMobile";

const THEME_REGEX = /theme-\S+/;

export const topStorySlider: Module = (container: HTMLElement) => {
	const swiperContainer = container.querySelector<HTMLElement>(".swiper-container");
	const header = document.querySelector<HTMLElement>("header");
	const pagination = container.querySelector<HTMLElement>(".swiper-pagination");
	const nextButton = container.querySelector<HTMLElement>(".swiper-button-next");

	if (!swiperContainer) return;

	const swiper = new Swiper(swiperContainer, {
		modules: [Navigation, Pagination, EffectCreative, Autoplay],
		direction: "vertical",
		effect: "creative",
		loop: true,
		noSwiping: true,
		noSwipingClass: "swiper-slide",
		speed: 950,
		autoplay: {
			delay: 4000,
		},
		creativeEffect: {
			prev: {
				translate: [0, "60%", -1],
			},
			next: {
				translate: [0, "-100%", 0],
			},
		},
		pagination: {
			el: pagination,
			clickable: true,
		},
		navigation: {
			nextEl: nextButton,
		},
		on: {
			init: (swiper) => {
				swiper.el.classList.remove("init");
			}
		}
	});

	function getTheme(element: HTMLElement | Element | null): string {
		const theme = element?.classList.value.match(THEME_REGEX) || null;
		if (!theme) return "";
		return theme[0];
	}

	function removeTheme(elements: (HTMLElement | Element | null)[]): void {
		elements.forEach(element => {
			const theme = element?.classList.value.match(THEME_REGEX) || null;
			if (!theme) return;
			element?.classList.remove(theme[0]);
		})
	}

	function setTheme(theme: string, elements: (HTMLElement | Element | null)[]) {
		removeTheme(elements);
		elements.forEach(element => element?.classList.add(theme));
	}

	function init() {
		const slide = container.querySelector(`[data-swiper-slide-index="0"]`);
		const theme = getTheme(slide);
		const elements = isMobile() ? [header] : [header, pagination]
		setTheme(theme, elements);

		swiper.on("slideChange", (swiper) => {
			const slide = container.querySelector(`[data-swiper-slide-index="${swiper.realIndex}"]`);
			const theme = getTheme(slide);
			setTheme(theme, elements);
		});

		if (currentBreakpoint >= breakpointIndex.sm) {
			pagination?.classList.remove("swiper-pagination-horizontal");
			pagination?.classList.add("swiper-pagination-vertical");

		} else {
			pagination?.classList.remove("swiper-pagination-vertical");
			pagination?.classList.add("swiper-pagination-horizontal");
		}
	}

	init();

	onWindowResize(init);
}

export default topStorySlider;