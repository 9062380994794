import { bookmarkService } from "../services/bookmarkService";
import { useBookmarkCount } from "./useBookmarkCount.ts";

const bookmarkCount = useBookmarkCount();

export async function useToggleBookmark(bookmarkId: string) {
	const isPageBookmarked = document.body.classList.contains("pageIsBookmarked");

	if (isPageBookmarked) {
		const response = await bookmarkService.delete(bookmarkId);
		if (response.success) {
			const bookmarkElements = document.querySelectorAll(".bookmark--filled");
			bookmarkElements.forEach(element => {
				element.classList.remove("bookmark--filled");
				element.classList.add("bookmark");
			});
			await bookmarkCount.decrease();
			document.body.classList.remove("pageIsBookmarked");
		} else {
			console.error(response.error?.message);
		}
		return response;
	} else {
		const response = await bookmarkService.create({ pageItemId: bookmarkId });
		if (response.success) {
			const bookmarkElements = document.querySelectorAll(".bookmark");
			bookmarkElements.forEach(element => {
				element.classList.remove("bookmark");
				element.classList.add("bookmark--filled");
			});
			await bookmarkCount.increase();
			document.body.classList.add("pageIsBookmarked");
		} else {
			console.error(response.error?.message);
		}
		return response;
	}
}