/* eslint-disable @typescript-eslint/no-explicit-any */
/*
 * @example <caption>Scroll window to 100px down on the Y-axis (using default duration of 1000ms)</caption>
 * import { scrollTo } from './utils/dom/scrollTo';
 * scrollTo(100);
 *
 *
 * @example <caption>Your editor may warn you about ignoring the promise returned from `scrollTo()`. If you want to avoid this, use the `void` operator:</caption>
 * void scrollTo(100);
 *
 *
 * @example <caption>Scroll `window` to 1024px on the X-axis and 100px on the Y-axis (500ms duration)</caption>
 * void scrollTo([1024, 100], 500);
 *
 *
 * @example <caption>Scroll `#test` to 500px on the X- and Y-axis (1500ms duration), and output a string to the console when done</caption>
 * const scrollElement = document.getElementById("test");
 * scrollTo([500, 500], 1500, scrollElement).then(() => window.console.log("Scroll done"));
 * // or:
 * scrollTo([500, 500], 1500, "test").then(() => window.console.log("Scroll done"));
 *
 *
 * @example <caption>Scroll `window` to reveal `#target` with -150 pixel offset on Y-axis</caption>
 * const targetElement = document.getElementById("target");
 * void scrollTo(targetElement, 1000, window, -150);
 * // or:
 * void scrollTo("target", 1000, window, -150);
 *
 *
 * @example <caption>Scroll `window` to reveal `#target` with 100 pixel offset on X-axis and -200 pixel offset on Y-axis</caption>
 * const targetElement = document.getElementById("target");
 * void scrollTo(targetElement, 2000, window, [100, -200]);
 * // or:
 * void scrollTo("target", 2000, window, [100, -200]);
 */

import { getElementPosition, getElementScroll } from "./elementProperties";
import { addEventOnce, removeEvent } from "~/foundation/Events/events";

const defaultDuration = 1000;

function doScroll(position: [x: number, y: number], element: HTMLElement, elementIsWindow: boolean) {
	const [x, y] = position;
	if (elementIsWindow) {
		element.scrollTo(x, y);
	} else {
		element.scrollLeft = x
		element.scrollTop = y;
	}
}

/**
 * Smooth scroll effect
 *
 * @param {Number|Number[]|HTMLElement|String} target - Where to scroll to.
 *        If a number is given, it will be interpreted as pixels on the Y-axis.
 *        If an array is given, it will be interpreted as `[x, y]`. Must be array of numbers.
 *        If an HTML element is given, that will be the target instead.
 *        If a string is given, an element with that string as its ID will be found and used.
 * @param {Number|Boolean|Null} [duration=1000] - Duration of scroll effect in ms. Defaults to `1000`.
 * @param {HTMLElement|String|Window} [targetElement=window] - Element to scroll inside. Defaults to `window`.
 * @param {Number|Number[]} [offset=0] - Offset value in pixels for Y-axis, or as an array (`[x, y]`) that offsets both X and Y-axis. Defaults to `0`.
 * @param {Boolean} [interruptible=true] - Whether the scrolling is interruptible by mousewheel scrolling. Defaults to `true`.
 * @param {Boolean} [onlyVertical=false] - Whether the scrolling should be only vertical - usable when target is HTMLElement, because some horizontal scrolling might occur
 * @returns {Promise} A promise that resolves when the scrolling is done.
 */
export function scrollTo(
	target: number | [x: number, y: number] | HTMLElement | string,
	duration = defaultDuration,
	targetElement = window,
	offset = 0,
	interruptible = true,
	onlyVertical = false
) {
	// Set duration to default if it isn't defined as a number
	const useDuration = typeof duration !== "number" ? defaultDuration : duration;

	// If target element is a string, find the element by its ID
	const useTargetElement = typeof targetElement === "string"
		? document.getElementById(targetElement)
		: targetElement;

	// Throw error if target element wasn't found
	if (!useTargetElement) {
		// eslint-disable-next-line no-throw-literal
		throw "getElementPosition did not find an element.";
	}

	const elementIsWindow = useTargetElement === window;
	let scrollCount = 0;
	let oldTimestamp = Date.now();

	// Get current scroll position
	const detectedScrollPosition = getElementScroll(useTargetElement);
	const scrollPos = [detectedScrollPosition!.left, detectedScrollPosition!.top];

	let useTarget: any;

	// If target is a number and not an array, make an array for scrolling on the Y-axis
	if (typeof target === "number") {
		useTarget = [scrollPos[0], target];
	} else if (!Array.isArray(target)) { // If target is not an array by now we'll try to find the position of an element
		const elementPosition = getElementPosition(target, useTargetElement);

		useTarget = [
			onlyVertical ? 0 : elementPosition.left,
			elementPosition.top
		];
	} else { // Target is already an array - just use it as it is
		useTarget = target;
	}

	// If no X or Y position is stated, keep the current position
	if (typeof useTarget[0] !== "number") {
		useTarget[0] = scrollPos[0];
	}
	if (typeof useTarget[1] !== "number") {
		useTarget[1] = scrollPos[1];
	}

	// Modify the target scroll position if 'offset' parameter is given and is of type array or number
	if (Array.isArray(offset)) {
		// If array, add offset to X and Y-axis
		useTarget[0] += offset[0];
		useTarget[1] += offset[1];
	} else if (typeof offset === "number") {
		// If number, only offset the Y-axis
		useTarget[1] += offset;
	}

	// Calculate
	const cosParameters = [
		(scrollPos[0] - useTarget[0]) / 2,
		(scrollPos[1] - useTarget[1]) / 2
	];

	return new Promise<void>(resolve => {
		// If duration is set to 0, just jump to the stated target/position
		if (useDuration <= 0) {
			doScroll(useTarget, useTargetElement as HTMLElement, elementIsWindow);
			resolve();
			return;
		}

		let scrollDone = false;
		let interruptScrollFlag = false;

		// If interruptible, add an eventlistener once on mousewheel scroll that calls 'interruptScroll'
		if (interruptible) {
			addEventOnce(useTargetElement as HTMLElement, "wheel", interruptScroll);
		}

		// Once this function is called, set 'interruptScrollFlag' to true, which resolves the promise
		function interruptScroll() {
			interruptScrollFlag = true;
		}

		function step() {
			if (interruptScrollFlag) {
				resolve();
				// Since resolving does not prevent the rest of the function from running, we also need to return
				return;
			}

			const newTimestamp = Date.now();
			const timeDifference = newTimestamp - oldTimestamp;
			let moveStep;

			// Pi is used to make easing
			scrollCount += Math.PI / (useDuration / timeDifference);

			// As soon as we cross over Pi, we're about where we need to be
			if (scrollCount >= Math.PI) {
				moveStep = useTarget;
				scrollDone = true;
			} else {
				// Calculate and set scroll position
				moveStep = [
					Math.round(
						useTarget[0] +
						cosParameters[0] +
						cosParameters[0] * Math.cos(scrollCount)
					),
					Math.round(
						useTarget[1] +
						cosParameters[1] +
						cosParameters[1] * Math.cos(scrollCount)
					)
				];
			}

			// Perform scroll action
			doScroll(moveStep, useTargetElement as HTMLElement, elementIsWindow);

			if (scrollDone) {
				removeEvent(useTargetElement as HTMLElement, "wheel", interruptScroll);
				resolve();
			} else {
				oldTimestamp = newTimestamp;
				window.requestAnimationFrame(step);
			}
		}

		window.requestAnimationFrame(step);
	});
}
