/* eslint-disable @typescript-eslint/no-explicit-any */
import { currentWindowHeight } from "~/foundation/Events/onWindowResize";
import { isElement } from "~/foundation/Helpers/typeCheckers";
import { forEach } from "~/foundation/Helpers/forEach";

/**
 * Check if an element is empty.
 */
export function elementIsEmpty(element: Element, strict = true) {
	return strict
		? !element.childNodes.length
		: !element.innerHTML.trim().length;
}

/**
 * Check if an element is hidden in the DOM with `display: none;`
 */
export function elementIsHidden(element: HTMLElement) {
	return element.offsetParent === null;
}

/**
 * Check if a given element is within the client viewport
 */
export function elementInViewport(element: HTMLElement, expandMargin = 0) {
	const rect = element.getBoundingClientRect();
	const span = rect.top + rect.height + expandMargin;

	return (
		span >= Math.min(0, expandMargin) &&
		rect.top <= currentWindowHeight + expandMargin
	);
}

/**
 * Return the position of an element
 */
export function getElementPosition(element: string | HTMLElement, relativeTo: Window | HTMLElement | string = window) {
	const useElement = typeof element === "string"
		? document.getElementById(element)
		: element;

	// Throw error if element wasn't found
	if (!useElement) {
		throw "getElementPosition did not find an element.";
	}

	const useRelativeTo = typeof relativeTo === "string"
		? document.getElementById(relativeTo)
		: relativeTo;

	if (!useRelativeTo) {
		throw "getElementPosition did not find an element to show the position relative to.";
	}

	if (relativeTo === window) {
		const rect = useElement.getBoundingClientRect();
		return {
			top: rect.top + (window.pageYOffset || document.documentElement.scrollTop),
			left: rect.left + (window.pageXOffset || document.documentElement.scrollLeft)
		};
	}

	return {
		top: useElement.offsetTop - (useRelativeTo as HTMLElement).offsetTop,
		left: useElement.offsetLeft - (useRelativeTo as HTMLElement).offsetLeft
	};
}

/**
 * Get the current scroll values of the given element (or window). Will return an object containing
 * "left" and "top" properties, which are set to the scroll values, or false if no compatible element
 * was given.
 */
export function getElementScroll(element: HTMLElement | Window = window) {
	if (!isElement(element)) {
		return null;
	}

	if (element instanceof Window) {
		return {
			left: element.scrollX || document.documentElement.scrollLeft,
			top: element.scrollY || document.documentElement.scrollTop
		};
	}

	return {
		left: element.scrollLeft,
		top: element.scrollTop
	};
}


type GetElementSizeOptions = {
	includeMargin?: boolean;
	includeBorder?: boolean;
	includePadding?: boolean;
	pseudoElement?: ":after" | ":before";
}

/**
 * Get both width and height of element
 */
export function getElementSize(element: HTMLElement, options: GetElementSizeOptions = {}) {
	// Get styles
	const elementStyle = window.getComputedStyle(
		element,
		options.pseudoElement
	);

	return {
		width: getElementWidth(element, options, elementStyle),
		height: getElementHeight(element, options, elementStyle)
	};
}

export function getElementWidth(element: HTMLElement, options: GetElementSizeOptions = {}, elementStyle: CSSStyleDeclaration | null = null) {
	// Keep supplied values or set to defaults
	options.includeMargin = options.includeMargin === true;
	options.includeBorder = options.includeBorder !== false;
	options.includePadding = options.includePadding !== false;

	// Get styles
	const style =
		elementStyle || window.getComputedStyle(element, options.pseudoElement);

	// Get width including border and padding
	let width = element.offsetWidth;

	// Calculate width with margin
	if (options.includeMargin) {
		width += parseFloat(style.marginLeft) + parseFloat(style.marginRight);
	}

	// Calculate width without border
	if (!options.includeBorder) {
		width -=
			parseFloat(style.borderLeftWidth) +
			parseFloat(style.borderRightWidth);
	}

	// Calculate width without padding
	if (!options.includePadding) {
		width -= parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
	}

	return width;
}

export function getElementHeight(element: HTMLElement, options: GetElementSizeOptions = {}, elementStyle: CSSStyleDeclaration | null = null) {
	// Keep supplied values or set to defaults
	options.includeMargin = options.includeMargin === true;
	options.includeBorder = options.includeBorder !== false;
	options.includePadding = options.includePadding !== false;

	// Get styles
	const style =
		elementStyle || window.getComputedStyle(element, options.pseudoElement);

	// Get height including border and padding
	let height = element.offsetHeight;

	// Calculate height with margin
	if (options.includeMargin) {
		height += parseFloat(style.marginTop) + parseFloat(style.marginBottom);
	}

	// Calculate height without border
	if (!options.includeBorder) {
		height -=
			parseFloat(style.borderTopWidth) +
			parseFloat(style.borderBottomWidth);
	}

	// Calculate height without padding
	if (!options.includePadding) {
		height -=
			parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);
	}

	return height;
}

const setAttribute = (elementCollection: HTMLElement | HTMLElement[], attributeName: string, attributeValue: string | boolean) =>
	forEach(elementCollection, (element: HTMLElement) => {
		if (isElement(element)) {
			// If the option value is a boolean, we'll check the value of the element's property. If that's a
			// boolean as well, we'll set the property "directly". This will get rid of those pesky use-cases
			// with `disabled="true"` instead of just `disabled`.
			if (typeof attributeValue === "boolean" && typeof (element as any)?.[attributeName] === "boolean") {
				(element as any)[attributeName] = attributeValue;
			} else {
				element.setAttribute(attributeName, attributeValue as string);
			}
		}
	});

/**
 * @deprecated
 */
export function setAttributes(element: HTMLElement, attributes: string | Record<string, string>, attributeValue: string | boolean | null = null) {
	if (typeof attributes === "object") {
		forEach(attributes, (value: any, attribute: any) =>
			setAttribute(element, attribute, value)
		);
	} else {
		if (attributeValue) {
			setAttribute(element, attributes, attributeValue);
		}
	}
}

/**
 * @deprecated
 */
export function removeAttributes(elements: HTMLElement | HTMLElement[], attributes: string | string[]) {
	forEach(elements, (element: HTMLElement) => {
		if (isElement(element)) {
			forEach(attributes, attribute =>
				element.removeAttribute(attribute)
			);
		}
	});
}
