const stepEightContentTemplate = ({ classes, sections }) => {
	return `
        <div class="${classes.rootClass}__section">
            <div class="${classes.rootClass}__event-intro">
                <p>${sections.confirmationTitle}</p>
                ${sections.confirmationText}
            </div>
        </div>
    `;
};

export default stepEightContentTemplate;
