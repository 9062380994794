import { buildTextareaInput } from "./buildTextareaInput";
import { delegateEvent } from "~/foundation/Events/events";
import { toggleClass } from "~/foundation/Dom/classList";

const createMarkup = (option, field, errorMessageTargetClass) => {
	if (option.textArea) {
		bindTextareaEvents(errorMessageTargetClass);
	}

	return `
        <li class="greeting__radio">
            <label>
                <input type="radio"
                    name="${field.key}"
                    value="${option.label}"
                    data-error-message="${field.defaultErrorMessage}"
                    data-error-container="${errorMessageTargetClass}"
                    ${field.readOnly ? ` readonly` : ``}
                    ${field.isRequired ? ` required` : ``}
                    ${option.textArea ? `data-textarea-trigger` : ``}
                />
                <span>
                    ${option.label}
                </span>
            </label>
        </li>
        ${
	option.textArea
		? `
                    <li class="greeting__textarea-group greeting__textarea-group--radio">
                        ${buildTextareaInput(
		option.textArea,
		errorMessageTargetClass
	)}
                    </li>
                `
		: ""
}
    `;
};

const bindTextareaEvents = errorMessageTargetClass => {
	// eslint-disable-next-line no-unused-vars
	delegateEvent(".greeting__radio input", "change", e => {
		const currentTextAreaContainer = document.querySelector(
			".greeting__step:not([hidden]) .greeting__textarea-group--radio"
		);
		const currentTextarea =
            currentTextAreaContainer.querySelector("textarea");

		//set showTextarea boolean based on trigger being checked
		const showTextarea = document.querySelector(
			".greeting__step:not([hidden]) [data-textarea-trigger]"
		).checked;

		//toggle visibility of textarea
		toggleClass(currentTextAreaContainer, "open-field", showTextarea);

		//handle validation and removal of errormessage manually
		toggleClass(
			currentTextarea,
			"form-validate__field--invalid",
			showTextarea
		);

		if (!showTextarea) {
			// Remove ARIA role from the element
			currentTextarea.removeAttribute("aria-describedby");
			// Check if an error message is in the DOM
			const errorMessage = document.querySelector(
				`.${errorMessageTargetClass}`
			);
			if (!errorMessage) {
				return;
			}

			// If so, empty it
			errorMessage.innerHTML = "";

			currentTextarea.removeAttribute("required");
		} else {
			//set textarea required if visible
			const attr = document.createAttribute("required");
			currentTextarea.setAttributeNode(attr);
		}
	});
};

export const buildRadioInput = (field, errorMessageTargetClass) => {
	return field.options
		.map(option => createMarkup(option, field, errorMessageTargetClass))
		.join("");
};
