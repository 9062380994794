import { appendElement, parseHTML } from "~/foundation/Dom/elementManipulation";
import {
	addEvent,
	addEventOnce,
	removeAllEvents
} from "~/foundation/Events/events";
import { selectTopicsMarkup } from "./markup";
import { fetcher } from "~/foundation/Api";
import { STANDARDCUBICBEZIER } from "~/foundation/Styles/easings";
import anime from "animejs";
import { isRtl } from "~/foundation/Helpers/isRtl";
import { SLOW, VERYSLOW } from "~/foundation/Styles/durations";
import {
	currentWindowWidth,
	currentWindowHeight,
	currentBreakpoint,
	breakpointIndex,
	onWindowResize
} from "~/foundation/Events/onWindowResize";

export class SelectTopics {
	/**
		 * Internal placeholder for cached DOM-objects.
		 *
		 * @type {object}
		 * @ignore
		 */
	dom = {};

	/**
		 *
		 * @param {Element} domReference - The element to work from. -> membership__content
		 */
	constructor(config) {
		this.dom.container = config.container;
		this.dom.membershipContent = config.membershipContent;
		this.texts = config.texts;
		this.saveTopicScoresApiUrl = config.saveTopicScoresApiUrl;

		this.callbacks = {
			onSelectedTopicsSaved: config.onSelectedTopicsSaved,
			onSkipSelectTopics: config.onSkipSelectTopics
		};

		this.previousBreakpoint = currentBreakpoint;
		this.showBelly = false;

		this.initialize();
	}

	selectTopicsAndClose = () => {
		const selectedButtons = [
			...this.dom.membershipContent.querySelectorAll(".signup-topics .button--selected")
		];

		if (selectedButtons.length) {
			const ids = selectedButtons.map(item => item.id);
			const confirmationResponse = this.dom.membershipContent.querySelector(".select-topics-confirmationResponse-text");

			removeAllEvents(this.dom.topicsButtons);

			if (this.dom.bellyContinueButton) {
				this.dom.bellyContinueButton.disabled = true;
			}

			this.dom.continueButton.disabled = true;

			fetcher(this.saveTopicScoresApiUrl, "POST", {
				TopicIds: ids
			}).then(({ success }) => {
				if (success) {
					if (currentBreakpoint <= breakpointIndex.md) {
						anime
							.timeline({
								easing: STANDARDCUBICBEZIER,
								duration: VERYSLOW,
								begin: () => {
									this.dom.membershipContent.querySelector(
										".belly"
									).style.left = 0;
									confirmationResponse.style.display = "flex";
								},
								complete: () => {
									removeAllEvents(
										this.dom.bellyContinueButton
									);
									removeAllEvents(this.dom.continueButton);
									this.callbacks.onSelectedTopicsSaved();
								}
							})
							.add({
								targets: this.dom.belly,
								d: [
									{
										value: [
											"M0,0 L375,0 C375,180.533529 375,361.067057 375,541.600586 C375,610.50293 375,623.562012 375,679.405273 C375,735.248535 375,735.248535 375,791.091797 C375,798.061198 375,805.030599 375,812 L0,812 L0,0 Z"
										]
									}
								]
							})
							.add(
								{
									targets: this.dom.bellyContinueButtonArrow,
									opacity: [1, 0]
								},
								0
							)
							.add(
								{
									targets: this.dom.bellyContinueButtonLabel,
									opacity: [1, 0]
								},
								0
							)
							.add(
								{
									targets: confirmationResponse,
									translateY: [15, 0],
									opacity: [0, 1],
									delay: 150
								},
								0
							);
					} else {
						const pageTransition =
							document.querySelector(".page-transition");
						pageTransition.style.zIndex = "10";
						const confirmationResponseText = `<span class="select-topics-confirmationResponse-text">${this.texts.confirmationResponseText}</span>`;
						appendElement(
							parseHTML(confirmationResponseText),
							pageTransition
						);
						const confirmationReponseTextElem =
							pageTransition.querySelector(
								".select-topics-confirmationResponse-text"
							);

						anime
							.timeline({
								easing: STANDARDCUBICBEZIER,
								duration: VERYSLOW,
								begin: () => {
									confirmationReponseTextElem.style.display =
										"flex";
								},
								complete: () => {
									anime
										.timeline({
											easing: STANDARDCUBICBEZIER,
											duration: VERYSLOW,
											complete: () => {
												removeAllEvents(
													this.dom.continueButton
												);
												this.callbacks.onSelectedTopicsSaved();
											}
										})
										.add({
											targets: this.dom.container,
											translateX: isRtl
												? [0, "-100%"]
												: [0, "100%"]
										});
								}
							})
							.add({
								targets: pageTransition,
								translateX: isRtl
									? ["100%", "0%"]
									: ["-100%", "0%"]
							})
							.add(
								{
									targets: confirmationReponseTextElem,
									translateY: [15, 0],
									opacity: [0, 1],
									delay: 550
								},
								0
							);
					}
				}
			});
		} else {
			alert(this.texts.pleaseSelectTopicsText);
		}
	};

	animateBelly = isSelected => {
		const selectedButtons = this.dom.membershipContent.querySelectorAll(
			".signup-topics .button--selected"
		);
		const selectTopicsConfirmationText =
			this.dom.membershipContent.querySelector(
				".select-topics-confirmation-text"
			);
		const animateBackwards = selectedButtons.length === 0;

		this.showBelly = selectedButtons.length > 0;

		if (
			selectedButtons.length === 0 ||
			(selectedButtons.length === 1 && isSelected)
		) {
			if (currentBreakpoint <= breakpointIndex.md) {
				this.dom.belly =
					this.dom.membershipContent.querySelector(".belly g path");
				this.dom.bellyContinueButton =
					this.dom.membershipContent.querySelector(
						".select-topics-continue"
					);
				this.dom.bellyContinueButtonArrow =
					this.dom.bellyContinueButton.querySelector(
						".select-topics-continue svg"
					);
				this.dom.bellyContinueButtonLabel =
					this.dom.bellyContinueButton.querySelector(
						".select-topics-continue span"
					);

				anime
					.timeline({
						easing: STANDARDCUBICBEZIER,
						duration: SLOW,
						begin: () => {
							if (animateBackwards) {
								this.dom.bellyContinueButton.classList.remove(
									"hasSelectedTopics"
								);
							} else {
								this.dom.bellyContinueButton.classList.add(
									"hasSelectedTopics"
								);
							}
						}
					})
					.add({
						targets: this.dom.belly,
						d: [
							{
								value: animateBackwards
									? [
										"M0,0 L15,0 C15,180.533529 15,361.067057 15,541.600586 C15,610.50293 15,623.562012 15,679.405273 C15,735.248535 15,735.248535 15,791.091797 C15,798.061198 15,805.030599 15,812 L0,812 L0,0 Z"
									]
									: [
										"M0,0 L15,0 C15,180.533529 15,361.067057 15,541.600586 C15,610.50293 60,623.562012 60,679.405273 C60,735.248535 15,735.248535 15,791.091797 C15,798.061198 15,805.030599 15,812 L0,812 L0,0 Z"
									]
							}
						]
					})
					.add(
						{
							targets: this.dom.bellyContinueButtonArrow,
							translateX: animateBackwards ? [15, 0] : [-10, 15],
							opacity: animateBackwards ? [1, 0] : [0, 1]
						},
						animateBackwards ? "-20" : 80
					)
					.add(
						{
							targets: this.dom.bellyContinueButtonLabel,
							translateX: animateBackwards ? [15, 0] : [10, 15],
							opacity: animateBackwards ? [1, 0] : [0, 1]
						},
						animateBackwards ? "-20" : 100
					)
					.add(
						{
							targets: selectTopicsConfirmationText,
							translateY: animateBackwards ? [15, 0] : [-10, 15],
							opacity: animateBackwards ? [1, 0] : [0, 1]
						},
						animateBackwards ? "-20" : 100
					);
			} else {
				this.dom.continueButton.disabled = animateBackwards;
				anime({
					begin: () => {
						selectTopicsConfirmationText.style.visibility =
							"visible";
					},
					targets: selectTopicsConfirmationText,
					easing: STANDARDCUBICBEZIER,
					duration: SLOW,
					translateY: animateBackwards ? [15, 0] : [-10, 15],
					opacity: animateBackwards ? [1, 0] : [0, 1]
				});
			}

			if (currentBreakpoint <= breakpointIndex.md) {
				if (
					!animateBackwards &&
					!this.dom.bellyContinueButton.classList.contains(
						"hasSelectedTopics"
					)
				) {
					addEvent(
						this.dom.bellyContinueButton,
						"click",
						this.selectTopicsAndClose
					);
				} else if (animateBackwards) {
					removeAllEvents(this.dom.bellyContinueButton);
				}
			}
		}
	};

	selectTopic = e => {
		const element = e.target;
		element.classList.toggle("button--selected");

		const isSelected = element.classList.contains("button--selected");
		this.animateBelly(isSelected);
		this.resize();
	};

	kill = () => {
		removeAllEvents(this.dom.continueButton);
		removeAllEvents(this.dom.bellyContinueButton);
		removeAllEvents(this.dom.topicsButtons);
	};

	skipSelectTopics = () => {
		this.kill();
		this.callbacks.onSkipSelectTopics();
	};

	resize = () => {
		if (currentBreakpoint <= breakpointIndex.md) {
			const bellyContinueButton =
				this.dom.membershipContent.querySelector(
					".select-topics-continue"
				);

			const bellyContinueButtonLabel =
				bellyContinueButton.querySelector("span");

			if (
				this.previousBreakpoint !== currentBreakpoint &&
				this.showBelly !==
				bellyContinueButton.classList.contains("hasSelectedTopics")
			) {
				const selectedButtons =
					this.dom.membershipContent.querySelectorAll(
						".signup-topics .button--selected"
					);

				this.animateBelly(selectedButtons.length > 0);
			}

			// Calculate center of belly
			const ishHeightofOrigBelly = 246;
			const ishWidthOfOrigBelly = 44.8;
			const origHeightOfBellySvg = 812;
			const origWidthOfBellySvg = 375;
			const offCenterPartHeight = 12.5;
			const offCenterPartRatio =
				offCenterPartHeight / origHeightOfBellySvg;
			const bellyRatio = Number(
				(ishHeightofOrigBelly / origHeightOfBellySvg).toFixed(3)
			); // 0,270
			const bellyWidthRatio = Number(
				(ishWidthOfOrigBelly / origWidthOfBellySvg).toFixed(3)
			);
			const ishHeightOfpartAfterBelly = 22;
			const lastPartAfterBellyRatio = Number(
				(ishHeightOfpartAfterBelly / origHeightOfBellySvg).toFixed(3)
			); // 0,039
			const heightOfBelly = Math.round(currentWindowHeight * bellyRatio);
			const widthOfBelly = Math.round(
				currentWindowWidth * bellyWidthRatio
			);
			const centerBellyRatio = bellyRatio / 2 + lastPartAfterBellyRatio;
			const centerOfBelly =
				currentWindowHeight * (centerBellyRatio - offCenterPartRatio);

			// Half of belly ratio plus lastPartAfterBelly ratio minus half of belly height (The button have the height of the belly)
			const buttonBottomPosition = Math.round(
				centerOfBelly - heightOfBelly / 2
			);

			bellyContinueButtonLabel.style.left = `${widthOfBelly + 20}px`;
			bellyContinueButton.style.height = `${heightOfBelly}px`;
			bellyContinueButton.style.width = `${widthOfBelly + 15}px`;
			bellyContinueButton.style.bottom = `${buttonBottomPosition}px`;
		}
		this.previousBreakpoint = currentBreakpoint;
	};

	initialize() {
		const selectTopicsHtml = selectTopicsMarkup(this.texts);

		appendElement(selectTopicsHtml, this.dom.membershipContent);
		this.dom.membershipContent.className =
			"membership__content membership__content--signup-topics";
		this.dom.topicsButtons = this.dom.membershipContent.querySelectorAll(
			".signup-topics .button"
		);
		const skipLink = this.dom.membershipContent.querySelector(".skip");

		anime({
			targets: this.dom.topicsButtons,
			translateX: isRtl ? [-100, 0] : [100, 0],
			opacity: [0, 1],
			easing: STANDARDCUBICBEZIER,
			delay: anime.stagger(250),
			complete: () => {
				addEvent(this.dom.topicsButtons, "click", this.selectTopic);
			}
		});

		this.dom.continueButton = this.dom.membershipContent.querySelector(
			".membership-footer .cta"
		);
		this.dom.continueButton.disabled = true;

		onWindowResize(this.resize);
		addEvent(this.dom.continueButton, "click", this.selectTopicsAndClose);
		addEventOnce(skipLink, "click", this.skipSelectTopics);
	}
}
