import { onReady } from "~/foundation/Events/onReady";
import { addClass } from "~/foundation/Dom/classList";

export class HideStreams {
	/**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
	dom = {
		container: undefined
	};

	/**
     *
     * @param {Element} domReference - The element to work from.
     */
	constructor(domReference) {
		this.dom.container = domReference;
		this.dom.parentToHide = this.dom.container.parentNode;
		this.dom.videoEpisodes = this.dom.parentToHide.querySelectorAll(
			".video-episode-teaser"
		);

		onReady(() => this.initialize());
	}

	checkIfStreamsExist = () => {
		if (!this.dom.videoEpisodes.length) {
			addClass(this.dom.parentToHide, "visuallyhidden");
		}
	};

	initialize() {
		this.checkIfStreamsExist();
	}
}
