const createMarkup = (option, field, errorMessageTargetClass) => {
	const hasHelpText = option.helptext !== null && option.helptext !== "";

	return `
        <li>
            <label>
                <input type="radio"
                    name="${field.key}"
                    value="${option.value}"
                    data-error-message="${field.defaultErrorMessage}"
                    data-error-container="${errorMessageTargetClass}"
                    ${
	field.selectedValue.toLowerCase() ===
                        option.value.toLowerCase()
		? ` checked`
		: ``
}
                    ${field.readOnly ? ` readonly` : ``}
                    ${field.isRequired ? ` required` : ``}
                />
                <span ${
	hasHelpText
		? "class='volunteer-signup__radio-helptext'"
		: ""
}>
                    ${option.label}
                    ${
	hasHelpText
		? `<span class="volunteer-signup__radio-helptext--small">${option.helptext}</span>`
		: ""
}
                </span>
            </label>
        </li>
    `;
};

export const buildRadioInput = (field, errorMessageTargetClass) => {
	return field.options
		.map(option => createMarkup(option, field, errorMessageTargetClass))
		.join("");
};
