import { OverviewScreen } from "./screens/overview/overview";
import { Membership, membershipStartPages } from "../../membership";
import { AddDependentScreen } from "./screens/add-dependent/add-dependent";
import { ConfirmAddScreen } from "./screens/confirm-add/confirm-add";
import { HostOverviewScreen } from "./screens/host-overview/host-overview";

export class Dependent {
	/**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
	dom = {};

	/**
     *
     * @param {Element} domReference - The element to work from. -> membership__content
     */
	constructor(config) {
		this.dom.container = config.container;
		this.dom.membershipContent = config.membershipContent;
		this.animate = config.animate;
		this.user = config.user;
		this.apiEndPoints = config.apiEndPoints;
		this.texts = config.texts;
		this.errorMessages = config.errorMessages;

		this.steps = {};
		this.currentStep = 0;

		this.initialize();
	}

	restart = () => {
		const membershipElement = this.dom.container;
		if (membershipElement) {
			new Membership(membershipElement, membershipStartPages.PROFILE);
		}
	};

	overviewScreen = () => {
		new OverviewScreen({
			container: this.dom.container,
			membershipContent: this.dom.membershipContent,
			user: this.user,
			texts: this.texts,
			errorMessages: this.errorMessages,
			apiEndPoints: this.apiEndPoints,
			onAddClick: () => {
				this.navigate();
			},
			onCancelClick: () => {
				this.restart();
			}
		});
	};

	hostOverviewScreen = () => {
		new HostOverviewScreen({
			container: this.dom.container,
			membershipContent: this.dom.membershipContent,
			user: this.user,
			texts: this.texts,
			errorMessages: this.errorMessages,
			apiEndPoints: this.apiEndPoints,
			onConfirmClick: () => {
				this.restart();
			},
			onCancelClick: () => {
				this.restart();
			}
		});
	};

	addDependentScreen = () => {
		new AddDependentScreen({
			container: this.dom.container,
			membershipContent: this.dom.membershipContent,
			texts: this.texts,
			errorMessages: this.errorMessages,
			apiEndPoints: this.apiEndPoints,
			onEmailSuccess: data => {
				this.data = data;
				this.navigate();
			},
			onCancelClick: () => {
				this.navigate(false);
			}
		});
	};

	confirmAddScreen = () => {
		new ConfirmAddScreen({
			container: this.dom.container,
			membershipContent: this.dom.membershipContent,
			texts: this.texts,
			errorMessages: this.errorMessages,
			apiEndPoints: this.apiEndPoints,
			data: this.data,
			onConfirmSuccess: () => {
				this.restart();
			},
			onCancelClick: () => {
				this.navigate(false);
			}
		});
	};

	navigate = (directionForward = true) => {
		this.currentStep = directionForward
			? this.currentStep + 1
			: this.currentStep - 1;

		this.steps[this.currentStep]();

		setTimeout(() => {
			this.animate();
		}, 50);
	};

	setupSteps = () => {
		this.steps = {
			1: this.overviewScreen,
			2: this.addDependentScreen,
			3: this.confirmAddScreen
		};
	};

	setupHostSteps = () => {
		this.steps = {
			1: this.hostOverviewScreen
		};
	};

	initialize() {
		if (this.user.showManageHostDependency) {
			this.setupHostSteps();
		} else {
			this.setupSteps();
		}

		this.navigate();
	}
}
