/* eslint-disable @typescript-eslint/no-explicit-any */
import { addEvent, removeEvent } from "./events";
import { forEach } from "~/foundation/Helpers/forEach";

type ElementType = Element | NodeListOf<Element> | Window | Document;

// We'll be using Map and not a regular object, since Map supports using objects as keys.
// This requires you to include the required polyfill. "default-3.6" from polyfill.io is fine.
const eventsMap = new Map<ElementType, any>();

export function onClickOutside(
	element: HTMLElement | Element,
	eventHandler: (event: Event) => void,
	clickElement: ElementType = window,
	onlyOnce: boolean = true,
	eventListener: string = "click touchstart"
) {
	if (typeof eventHandler === "function") {
		const elementHandlers = eventsMap.get(clickElement) || new Map<ElementType, any>();
		const thisElementHandlers = elementHandlers.get(element) || [];

		const handleEvent = (e: Event) => {
			if (elementHandlers && typeof elementHandlers === "object") {
				elementHandlers.forEach((callbackList: Array<any>, handleElement: any) => {
					if (e.target && !handleElement.contains(e.target)) {
						if (Array.isArray(callbackList) && callbackList.length) {
							// Fire all associated event handlers!
							const tempCallbackList = callbackList.slice(0);
							forEach(tempCallbackList, callback => {
								callback.eventHandler(e);

								// Remove event if it is only to be fired once
								if (callback.onlyOnce) {
									callbackList.splice(callbackList.indexOf(callback), 1);

									// Clean eventsMap
									if (callbackList.length) {
										elementHandlers.set(
											handleElement,
											callbackList
										);
									} else {
										elementHandlers.delete(handleElement);
									}
									if (!elementHandlers.size) {
										removeEvent(
											clickElement as HTMLElement,
											eventListener,
											handleEvent
										);
										eventsMap.delete(clickElement);
									}
								}
							});
						}
					}
				});
			}
		};

		thisElementHandlers.push({ onlyOnce, eventHandler, handleEvent });

		// Only listen for this event on the given element, is said element hasn't already got an event listener
		if (!eventsMap.get(clickElement)) {
			// In case this function was called from within an event, set the new click handler inside
			// a timeout to allow the original event to finish propagating.
			setTimeout(() => {
				addEvent(clickElement as Element, eventListener, handleEvent)
			});
		}

		elementHandlers.set(element, thisElementHandlers);
		eventsMap.set(clickElement, elementHandlers);
	}
}

export function removeOnClickOutside(
	element: HTMLElement | Element,
	eventHandlerToRemove: any,
	clickElement: ElementType = window
) {
	const elementHandlers = eventsMap.get(clickElement);

	if (elementHandlers && typeof elementHandlers === "object") {
		const thisElementHandlers = elementHandlers.get(element);

		if (Array.isArray(thisElementHandlers) && thisElementHandlers.length > 0) {
			const tempThisElementHandlers = thisElementHandlers.slice(0);
			forEach(tempThisElementHandlers, handler => {
				// If a specific event handler is set to be removed, remove that - or else: remove all handlers.
				if (typeof eventHandlerToRemove !== "function" || eventHandlerToRemove === handler.eventHandler) {
					thisElementHandlers.splice(
						thisElementHandlers.indexOf(handler),
						1
					);

					// Clean eventsMap
					if (thisElementHandlers.length) {
						elementHandlers.set(element, thisElementHandlers);
					} else {
						elementHandlers.delete(element);
					}
					if (!elementHandlers.size) {
						removeEvent(clickElement as HTMLElement, "click", handler.handleEvent);
						eventsMap.delete(clickElement);
					}
				}
			});
		}
	}
}

export function onClickOutsideContinuously(
	element: HTMLElement | Element,
	callback: () => void,
	clickElement: HTMLElement | Element | Document | Window = window
) {
	onClickOutside(element as HTMLElement, callback, clickElement as HTMLElement, false);
}
