export type useBookmarkCount = ReturnType<typeof useBookmarkCount>;

export const useBookmarkCount = () => {
	const headerBookmarkCountElement = document.querySelector("[data-bookmark-count]");

	return {
		increase: async () => {
			if (headerBookmarkCountElement?.innerHTML !== null){
				headerBookmarkCountElement!.innerHTML = (Number(headerBookmarkCountElement?.innerHTML) + 1).toString();

				if (!headerBookmarkCountElement?.classList.contains("visible")) {
					headerBookmarkCountElement?.classList.add("visible");
				}
			}
		},
		decrease: async () => {
			if (headerBookmarkCountElement?.innerHTML !== null){
				headerBookmarkCountElement!.innerHTML = (Number(headerBookmarkCountElement?.innerHTML) - 1).toString();

				if (Number(headerBookmarkCountElement?.innerHTML) < 1) {
					headerBookmarkCountElement?.classList.remove("visible");
				}
			}
		},
	}
}