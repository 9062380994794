/**
 * Split a string into an array.
 * @deprecated Do not use this!
 */
export function splitter(input: string | string[], callback: (input: string) => void) {
	const inputArray =
		typeof input === "string"
			? input
				.replace(/,/gm, " ") // First, replace commas with spaces
				.replace(/[\s]{2,}/gm, " ") // Then, replace two or more spaces with just one.
				.trim() // Remove leading/trailing whitespace and similar crap
				.split(" ")
			: Array.isArray(input)
				? input
				: [];

	if (typeof callback === "function") {
		inputArray.forEach(inputEntry => {
			if (inputEntry !== undefined) {
				callback(inputEntry);
			}
		});
	}

	return inputArray;
}
