import { type Module } from "~/foundation/Bootstrapper/types";
import { onClickOutside, removeOnClickOutside } from "~/foundation/Events/onClickOutside";


export const SectionSubNavigation: Module = (container: HTMLElement) => {
	const linkButton = container.querySelector("[data-link-button]");
	const linkList = container.querySelector("[data-link-list]");

	if (!linkButton || !linkList) return;

	const toggle = () => {
		linkButton.ariaExpanded === "false" ? open() : close();
	}

	const open = () => {
		linkButton.ariaExpanded = "true";
		linkList.classList.add("open");
		onClickOutside(container, close, window, true);
	}

	const close = () => {
		linkButton.ariaExpanded = "false";
		linkList.classList.remove("open");
		removeOnClickOutside(container, close, window);
	}

	linkButton.addEventListener("click", toggle);
}

export default SectionSubNavigation;