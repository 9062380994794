import { parseHTML } from "~/foundation/Dom/elementManipulation";

export const newsletterMarkup = (
	labels,
	subscriptionLists,
	acceptSubscribe
) => {
	const { subscriptionLabel, termsAndConditionsLabel } = labels;

	const checkbox = ({ id, name, checked }) => `
    <div class="animate-translate checkbox">
        <input type="checkbox" id="${id}" name="${id}"
        ${checked ? "checked" : ""} />
        <label for="${id}">
            <span class="checkbox__labelText">
                ${name}
            </span>
            <div class="checkbox__labelBox">
                <svg focusable="false" xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
                    <path fill="none" fill-rule="evenodd" stroke="#00AAE2" stroke-width="2" d="M138 23L128.084 35 124 29.863" transform="translate(-123 -22)"/>
                </svg>
            </div>
        </label>
    </div>`;

	return parseHTML(`
    <div class="newsletter">
        <h3 class="animate-translate newsletter__headline">${subscriptionLabel}</h3>
        <div class="checkboxes">
            ${subscriptionLists
		?.map(
			({
				subscriptionListID,
				subscriptionListName,
				isSubscribed
			}) =>
				checkbox({
					id: subscriptionListID,
					name: subscriptionListName,
					checked: isSubscribed
				})
		)
		.join("")}
            ${checkbox({
			id: "consent",
			name: `${termsAndConditionsLabel}`,
			checked: acceptSubscribe
		})}
        </div>
    </div>`);
};
