import { onReady } from "~/foundation/Events/onReady";
import { addEvent } from "~/foundation/Events/events";
import { forEach } from "~/foundation/Helpers/forEach";
import { fetcher } from "~/foundation/Api";

export class Survey {
	/**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
	dom = {
		container: undefined
	};

	settings = {};

	/**
     *
     * @param {Element} domReference - The element to work from.
     */
	constructor(domReference) {
		this.dom.container = domReference;

		this.dom.submitButton = this.dom.container.querySelector(
			"button.survey__submit"
		);

		this.settings = {
			endPoint: this.dom.container.dataset.endpoint,
			eventId: this.dom.container.dataset.eventId
		};

		onReady(() => this.initialize());
	}

	submitData = () => {
		this.surveyData.eventId = this.settings.eventId;

		this.dom.submittableFields = this.dom.container.querySelectorAll(
			".survey__field-group input[type=radio]:checked, .survey__field-group textarea"
		);

		forEach(this.dom.submittableFields, field => {
			this.surveyData[field.name] = field.value;
		});

		fetcher(this.settings.endPoint, "POST", this.surveyData).then(() => {
			window.location.reload();
		});
	};

	setupEvents = () => {
		addEvent(this.dom.submitButton, "click", event => {
			event.preventDefault();

			this.submitData();
		});
	};

	initialize() {
		this.setupEvents();

		this.surveyData = {};
	}
}
