import { parseHTML } from "~/foundation/Dom/elementManipulation";
import { closeMarkup } from "../../close";

export const editNameMarkup = (labels, user) => {
	const {
		editNameButtonLabel,
		firstNameWatermark,
		lastNameWatermark,
		cancelButtonLabel,
		saveButtonLabel
	} = labels;

	const { firstName, lastName } = user;

	return parseHTML(`
    <div class="membership-header">
        ${closeMarkup}
        <h2 class="membership-header__title"><strong>${editNameButtonLabel}</strong></h2>
    </div>
    <div class="animate-fade membership-middle membership-middle--login-email-password">
        <div class="animate-translate editable-name">
            <div class="animate-translate input">
                <label for="firstName">${firstNameWatermark}</label>
                <input required type="text" id="firstName" value="${firstName}" />
                <span class="error"></span>
            </div>
            <div class="animate-translate input">
                <label for="lastName">${lastNameWatermark}</label>
                <input required type="text" id="lastName" value="${lastName}"/>
                <span class="error"></span>
            </div>
        </div>
    </div>
    <div class="membership-footer">
        <button class="animate-scale animate-scale--once cta cta--white button--save" type="submit">
            <span>${saveButtonLabel}</span> 
        </button>
        <button type="button" class="button-link button-link--secondary button--cancel">${cancelButtonLabel}</button>
    </div>
    `);
};
