import { useDialog } from "~/foundation/Components/dialog/useDialog";
import type { Module } from "~/foundation/Bootstrapper/types.ts";
import { Membership, membershipStartPages } from "~/feature/Membership/membership";
import { lockIconLocked } from "~/foundation/Icons/lock.ts";
import "./restricted-content.scss";
import { membershipSignupPromptServiceMeta } from "~/feature/Membership/services/membershipSignupPromptService.ts";
import { fetcher } from "~/foundation/Api";
import { type SignupPromptModel } from "~/feature/Membership/generated-types.ts";

const dialog = useDialog();

async function restrictedContentDialogTemplate(targetHref: string | undefined) {

	const [method, url] = membershipSignupPromptServiceMeta.signupPromptStart;

	const response = await fetcher(url, method);
	const data = (response as { data: SignupPromptModel }).data;

	return /*html*/`
			<div class="restricted-content">
				<h2 class="restricted-content__header">${lockIconLocked} ${data.dialogTitle}</h2>
				<h3 class="restricted-content__title">${data.dialogSignupTitle}</h3>
				<p>${data.dialogSignupText}</p>
				<button class="cta--theme" data-membership-signup><span class="cta__label-animation">${data.dialogSignupButtonLabel}</span></button>
				<h3 class="restricted-content__title">${data.dialogLoginTitle}</h3>
				<p>${data.dialogLoginText}</p>
				<button class="cta--outline-theme" data-membership-login data-targetHref="${targetHref}"><span class="cta__label-animation">${data.dialogLoginButtonLabel}</span></button>
			</div>
		`;
}

async function renderRestrictedContent(targetHref: string | undefined) {
	const isApp = document.querySelector(".is-app") !== null;
	const html = await restrictedContentDialogTemplate(targetHref);

	await dialog.setContent(html);

	dialog.element?.querySelector("button[data-membership-signup]")?.addEventListener("click", async () => {
		dialog.close();

		if (isApp) {
			window.location.href = "aramcolife://opensignup";
		} else {
			const membershipElement = document.querySelector("[data-module='membership']")!;
			new Membership(membershipElement, membershipStartPages.SIGNUP);
		}
	});

	dialog.element?.querySelector("button[data-membership-login]")?.addEventListener("click", async () => {
		dialog.close();

		if (targetHref) {
			window.history.pushState("", "", targetHref);
		}

		if (isApp && targetHref) {
			if (window.location.search.includes("?download=")) {
				window.location.href = `aramcolife://openlogin?url=${encodeURIComponent(`${window.location.origin}${window.location.pathname}`)}?download=${encodeURIComponent(targetHref.split("=")[1])}`;
			} else {
				window.location.href = `aramcolife://openlogin?url=${encodeURIComponent(targetHref)}`;
			}
		} else {
			const membershipElement = document.querySelector("[data-module='membership']")!;
			new Membership(membershipElement, membershipStartPages.LOGIN);
		}
	});
}

const openRestrictedContent: Module = (element) => {
	const socialBarWrapper = document.querySelector<HTMLElement>(".social-bar");
	const isActionBarElem = element.parentElement?.classList.contains("social-bar__menu--vertical");
	const focusElement = isActionBarElem ? socialBarWrapper : undefined;

	element.dataset.bootstrapped = "true";
	const returnUrl = element.dataset.href || element.getAttribute("href") || "";

	element.addEventListener("click", async (e) => {
		e.preventDefault();
		await dialog.open(null, undefined, focusElement);

		await renderRestrictedContent(returnUrl);
	});

	element.addEventListener('keypress', async (e) => {
		if (e.key === 'Enter') {
			await dialog.open(null, undefined, focusElement);
			await renderRestrictedContent(returnUrl);
		}
	});
};

export default openRestrictedContent;