import { parseHTML } from "~/foundation/Dom/elementManipulation";
import closeMarkup from "~/foundation/Icons/close";
import plusIcon from "~/foundation/Icons/plus";

export const overviewMarkup = labels => {
	const {
		introTitle,
		introText,
		myDependentsLabel,
		addNewDependentLinkText,
		noDependentsText,
		cancelButtonLabel
	} = labels;

	return parseHTML(`
    <div class="membership-header">
        ${closeMarkup}
        <h2 class="membership-header__title"><strong>${introTitle}</strong></h2>
        <div class="membership-header__intro">${introText}</div>
    </div>
    <div class="animate-fade membership-middle dependent">
        <h3 class="membership-header__title">${myDependentsLabel}</h3>
        <div class="dependent__list">${noDependentsText}</div>
        <button type="button" class="button-link button-link--secondary button--icon button--add">${plusIcon} ${addNewDependentLinkText}</button>
    </div>
    <div class="membership-footer">
        <button type="button" class="button-link button-link--secondary button--cancel">${cancelButtonLabel}</button>
    </div>`);
};
