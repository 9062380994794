import { buildRadioInput } from "../inputs/buildRadioInput";

const stepSixContentTemplate = ({ classes, model, sections }) => {
	const errorMessageStepSix = "step-six";

	return `
        <div class="${classes.rootClass}__event-info">
        <h4>${model.texts.signupForLabel} ${model.eventModel.name}</h4>
        <time
            data-async-module="local-datetime"
            data-date-format="event-date-time"
            data-utc-start="${model.eventModel.startDateUTC}"
            data-utc-end="${model.eventModel.endDateUTC}"
            data-date-fallback="${model.eventModel.dateString}"
            data-use-ksa="true"
        ></time>
        </div>
        <div class="${classes.rootClass}__section">
            <fieldset class="${classes.rootClass}__fieldset">
                <legend class="visuallyhidden">Step 6</legend>
                <div class="${classes.rootClass}__radio-group ${
	classes.rootClass
}__radio-group--default">
                    <h3>${sections.title}</h3>
                    <ul class="${classes.rootClass}__vertical-list">
                        ${buildRadioInput(
		sections.fields[0],
		errorMessageStepSix
	)}
                    </ul>
                    <p class="${errorMessageStepSix}"></p>
                </div>
            </fieldset>
        </div>
    `;
};

export default stepSixContentTemplate;
