import viewPasswordSvg from "~/foundation/Icons/view-password";
import { closeMarkup } from "../../close";
import { parseHTML } from "~/foundation/Dom/elementManipulation";

export const changePasswordMarkup = labels => {
	const {
		changePasswordButtonLabel,
		oldPasswordWatermark,
		newPasswordWatermark,
		repeatNewPasswordWatermark,
		saveButtonLabel,
		cancelButtonLabel,
		passwordHelpText
	} = labels;

	return parseHTML(`
    <div class="membership-header">
        ${closeMarkup}
        <h2 class="membership-header__title"><strong>${changePasswordButtonLabel}</strong></h2>
    </div>
    <div class="membership-middle membership-middle--login-email-password">
        <div class="enter-password">
            <div class="animate-translate input">
                <label for="oldPassword">${oldPasswordWatermark}</label>
                <input autocomplete="off" required type="password" id="oldPassword" class="password" />
                <span class="error"></span>
                <button class="toggle-visible-password" type="button">${viewPasswordSvg}</button>
            </div>
            <div class="animate-translate input">
                <label for="newPassword">${newPasswordWatermark}</label>
                <input autocomplete="off" required type="password" id="newPassword" class="password" />
                <span class="error"></span>
                <button class="toggle-visible-password" type="button">${viewPasswordSvg}</button>
            </div>
            <div class="animate-translate input">
                <label for="newPassword">${repeatNewPasswordWatermark}</label>
                <input autocomplete="off" required type="password" id="repeatPassword" class="password" />
                <span class="error"></span>
                <button class="toggle-visible-password" type="button">${viewPasswordSvg}</button>
            </div>
            <div class="animate-translate password-guide">
                ${passwordHelpText}
            </div>
        </div>
    </div>
    <div class="membership-footer">
        <button class="animate-scale animate-scale--once cta cta--white button--save" type="submit">
            <span>${saveButtonLabel}</span>
        </button>
        <button type="button" class="button-link button-link--secondary button--cancel">${cancelButtonLabel}</button>
    </div>`);
};
