import { buildRadioInput } from "../inputs/buildRadioInput";

const stepTwoTemplate = ({ classes, sections, stepText }) => {
	const errorMessageTarget = `${classes.rootClass}__error-message-step-2`;

	return `
				<div class="greeting__step-2">
					<div class="${classes.rootClass}__header">
							<p class="${classes.rootClass}__manchet">${stepText}</p>
							<h2 class="${classes.rootClass}__title">${sections.title}</h2>
					</div>
					<div class="${classes.rootClass}__section">
							<div>
									<ul class="greeting__radio-list">
											${buildRadioInput(sections.fields[0], errorMessageTarget)}
									</ul>
									<p class="${errorMessageTarget}"></p>
							</div>
					</div>
				</div>
    `;
};

export default stepTwoTemplate;
