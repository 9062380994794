import { type ApiErrorMessage } from "./generated-types";

export type Http = typeof http;
export type ApiRequestMethods = "GET" | "PUT" | "POST" | "DELETE";
export type ServiceEndpointMeta = [method: ApiRequestMethods, url: string];
export type Meta<TKeys> = Record<keyof TKeys, ServiceEndpointMeta>;
export type ApiError = ApiErrorMessage & unknown;

type FetchData<T> = {
	params?: { [key: string]: any }, // eslint-disable-line
	body?: T,
	queryStrings?: { [key: string]: any }; // eslint-disable-line
};

export type ApiResponse<TSuccess> = {
	success: boolean;
	statusCode?: number;
	data?: TSuccess;
	error?: ApiError;
}

let token: string | undefined = undefined;
let appkey: string | undefined = undefined;

if (window.aramcolife) {
	token = window.aramcolife.token;
	appkey = window.aramcolife.appkey;
}

export const http = {
	fetch: async <TResponse, TRequest = unknown>(meta: ServiceEndpointMeta, data?: FetchData<TRequest>): Promise<ApiResponse<TResponse>> => {

		const [method, endpoint] = meta;
		const url = endpoint;

		const fetchObj: RequestInit = {
			method,
			headers: {
				"accept": "application/json",
				"content-type": "application/json;charset=UTF-8",
				...(appkey && { "AppKey": appkey }),
				...(token && { "TokenAuthorization": token }),
			}
		};

		if (data?.body) {
			fetchObj.body = JSON.stringify(data.body);
		}

		let finalUrl = url;

		if (data?.params) {
			Object.keys(data.params).forEach(param => {
				finalUrl = finalUrl.replace(`/:${param}`, `/${data.params?.[param]}`)
			});
		}

		if (data?.queryStrings) {
			const queryStrings: string[] = [];
			Object.keys(data.queryStrings).forEach(queryStringKey => {
				const queryStringValue = data.queryStrings?.[queryStringKey];
				if (queryStringValue) {
					queryStrings.push(`${queryStringKey}=${queryStringValue}`);
				}
			});

			finalUrl = `${finalUrl}?${queryStrings.join("&")}`;
		}

		try {
			const response = await fetch(finalUrl, fetchObj);
			const { status, ok } = response;

			if (status === 201 || status === 204) { // Created, NoContent
				return {
					success: true,
					statusCode: status,
					data: undefined
				}
			}

			const jsonResponse = await response.json();

			if (!ok) {
				if (status === 500) { // InternalServerError
					console.error(jsonResponse);
				}

				return {
					success: false,
					statusCode: status,
					error: jsonResponse as ApiError
				};
			}

			return {
				success: true,
				statusCode: status,
				data: jsonResponse as TResponse
			};
		} catch (e) {
			console.error(`Error fetching ${finalUrl}`, e);

			return {
				success: false,
				error: e as ApiError
			}
		}
	},
};