import { type Module } from "~/foundation/Bootstrapper/types";
import "./header-search.scss";

const headerSearch: Module = (headerSearch) => {
	const searchUrl = headerSearch?.getAttribute("data-search-url");

	const redirectSearch = async (event: Event) => {
		event.preventDefault();
		const searchInput = headerSearch.querySelector<HTMLInputElement>(".header__search-input");
		const searchValue = searchInput?.value?.trim();
		if (searchValue) {
			window.location.href = `${searchUrl}?q=${searchValue}`;
		} else {
			if (searchUrl) {
				window.location.href = searchUrl;
			}
		}
	}

	headerSearch.addEventListener("submit", redirectSearch);
}

export default headerSearch;