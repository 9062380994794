export function changeDirection() {
	const body = document.body;
	const headerLogo = body.querySelector(".header a");

	const htmlElement = document.documentElement;
	const isRtl = document.documentElement.getAttribute("dir") === "rtl";

	if (headerLogo) {
		headerLogo.setAttribute("href", isRtl ? "/en" : "/ar");
	}

	htmlElement.setAttribute("dir", isRtl ? "ltr" : "rtl");
	htmlElement.setAttribute("lang", isRtl ? "en" : "ar");
}
