import { type Module } from "~/foundation/Bootstrapper/types.ts";
import { type FeedbackEmailRequestModel } from "../generated-types";
import { breakpointMin } from "~/foundation/Events/onWindowResize";
import { isRtl } from "~/foundation/Helpers/isRtl";
import { feedbackService } from "../services/feedbackService";

const feedback: Module = (feedback) => {
	const restart = () => {
		containerSuccess && (containerSuccess.style.display = "none");
		containerError && (containerError.style.display = "none");
		container && (container.style.display = "flex");
		formButtonSubmit && (formButtonSubmit.disabled = false);
		textArea && (textArea.value = "");
		toogleFormVisibility(true);
	};

	const handleFormSubmit = async (event: Event) => {
		event.preventDefault();

		// disable button when fetching
		formButtonSubmit && (formButtonSubmit.disabled = true);
		const form = event.currentTarget as HTMLFormElement;

		// Get form fields
		const formData = new FormData(form);
		const plainFormData = Object.fromEntries(formData.entries());
		const feedbackData: FeedbackEmailRequestModel = {
			pageUrl: plainFormData.PageUrl.toString(),
			body: plainFormData.Body.toString(),
		};

		const response = await feedbackService.sendFeedback(feedbackData);
		if (response.success) {
			if (response.data?.success) {
				container && (container.style.display = "none");
				containerSuccess && (containerSuccess.style.display = "flex");
				successCloseBtn && (successCloseBtn.style.display = "block");
				successCloseBtn?.addEventListener("click", restart);
			}
		} else {
			console.error(response.error);
			container && (container.style.display = "none");
			containerError && (containerError.style.display = "flex");
			errorCloseBtn && (errorCloseBtn.style.display = "block");
			errorCloseBtn?.addEventListener("click", restart);
			errorBtn?.addEventListener("click", restart);
		}
	};

	const toogleFormVisibility = (isFormVisible: boolean) => {
		if (isFormVisible === false) {
			startTitle && (startTitle.style.display = "none");
			startButton && (startButton.style.display = "none");

			formTitle && (formTitle.style.display = "block");
			formcontent && (formcontent.style.display = "block");
			let animHeight;

			if (svgBg){
				const animHeightBase = svgBg.clientHeight - (22 * 16);
				animHeight = animHeightBase + 86.8;
				if (breakpointMin("lg")) {
					animHeight = animHeightBase + (6.7375 * 16);
				}
			}

			if (breakpointMin("sm") && form) {
				form.style.height = "22rem";
				isRtl && svgBg && animHeight
					? (svgBg.style.transform = `translateY(${animHeight}px) scaleX(-1)`)
					: svgBg && (svgBg.style.transform = `translateY(${animHeight}px)`);
			}
			startTitle && (startTitle.style.display = "none");
			formButtonSubmit && (formButtonSubmit.style.display = "block");
			form?.addEventListener("submit", handleFormSubmit);

			closeBtn && (closeBtn.style.display = "block");
			closeBtn?.addEventListener("click", () => {
				toogleFormVisibility(true);
			});
		} else if (isFormVisible === true) {
			formTitle && (formTitle.style.display = "none");
			formcontent && (formcontent.style.display = "none");
			formButtonSubmit && (formButtonSubmit.style.display = "none");
			closeBtn && (closeBtn.style.display = "none");
			form && (form.style.height = "");
			svgBg && (svgBg.style.transform = "");

			startTitle && (startTitle.style.display = "block");
			startButton && (startButton.style.display = "block");
		}
	};
	const container = feedback.querySelector<HTMLElement>(".feedback--start");
	const containerSuccess =
		feedback.querySelector<HTMLElement>(".feedback--success");
	const containerError =
		feedback.querySelector<HTMLElement>(".feedback--error");
	const form = feedback.querySelector<HTMLElement>("form");
	const startTitle = feedback.querySelector<HTMLElement>(".title");
	const startButton =
		feedback.querySelector<HTMLButtonElement>(".button--start");
	const formTitle = feedback.querySelector<HTMLElement>(".title-form");
	const formcontent = feedback.querySelector<HTMLElement>(
		".feedback__content--form"
	);
	const formButtonSubmit =
		feedback.querySelector<HTMLButtonElement>(".button--form");
	const svgBg = feedback.querySelector<HTMLElement>("#bg-svg");
	const closeBtn = feedback.querySelector<HTMLButtonElement>(".button--close");
	const textArea = feedback.querySelector<HTMLInputElement>("textarea");
	const successCloseBtn = feedback.querySelector<HTMLButtonElement>(
		".button--close--success"
	);
	const errorCloseBtn = feedback.querySelector<HTMLButtonElement>(
		".button--close--error"
	);
	const errorBtn = feedback.querySelector<HTMLButtonElement>(".button-error");

	startButton?.addEventListener("click", () => {
		toogleFormVisibility(false);
	});

	window.addEventListener("resize", () => {
		toogleFormVisibility(true);
	});
};

export default feedback;
