import { onReady } from "~/foundation/Events/onReady";
import { addEvent } from "~/foundation/Events/events";
import html2canvas from "html2canvas";

export class CertificateDownload {
	/**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
	dom = {
		container: undefined
	};

	/**
     *
     * @param {Element} domReference - The element to work from.
     */
	constructor(domReference) {
		this.dom.container = domReference;
		this.dom.downloadButton = this.dom.container.querySelector("button");

		this.dom.nodeToBeConverted =
            this.dom.container.querySelector(".certificate__item");

		onReady(() => this.initialize());
	}

	initialize() {
		const eventName =
            this.dom.container.dataset.eventname.replace(" ", "-") ||
            "aramco-life-event";

		// On click we send the node to be converted to an image through the html2canvas package and download it.
		addEvent(this.dom.downloadButton, "click", () => {
			html2canvas(this.dom.nodeToBeConverted, {}).then((canvas) => {
				var dataURL = canvas.toDataURL();

				const link = document.createElement("a");
				link.download = `certificate-${eventName}.jpeg`;
				link.href = dataURL;
				link.click();
			});
		});
	}
}
