const createMarkup = (option, field, errorMessageTargetClass) => {
	return `
        <li>
            <label>
                <input type="radio"
                    name="${field.key}"
                    value="${option.value}"
                    data-error-message="${field.defaultErrorMessage}"
                    data-error-container="${errorMessageTargetClass}"
                    ${
	field.selectedValue.toLowerCase() ===
                        option.value.toLowerCase()
		? ` checked`
		: ``
}
                    ${field.readOnly ? ` readonly` : ``}
                    ${field.isRequired ? ` required` : ``}
                />
                <span>${option.label}</span>
            </label>
        </li>
    `;
};

export const buildRadioButtonInput = (field, errorMessageTargetClass) => {
	return field.options
		.map(option => createMarkup(option, field, errorMessageTargetClass))
		.join("");
};
