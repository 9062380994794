import { onReady } from "~/foundation/Events/onReady";
import { appendElement, deleteElement } from "~/foundation/Dom/elementManipulation";
import { addEvent } from "~/foundation/Events/events";
import { pressReleaseItemMarkup } from "./markup/pressReleaseItemMarkup";
import { setupInView } from "~/foundation/Dom/inView";
import { removeClass } from "~/foundation/Dom/classList";
import { fetcher } from "~/foundation/Api";
import { isRtl } from "~/foundation/Helpers/isRtl";
import { addOldLoader } from "~/foundation/Loaders/loader";
import { createElement } from "~/foundation/Dom/createElement";
import { LocalDatetime } from "~/foundation/LocalDatetime/local-datetime";

export class PressReleaseList {
	/**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
	dom = {
		container: undefined
	};

	/**
     *
     * @param {Element} domReference - The element to work from.
     */
	constructor(domReference) {
		this.dom.container = domReference;

		this.endPoint = this.dom.container.dataset.endpoint;
		this.loadMoreAmount = parseInt(this.dom.container.dataset.listLength);
		this.loadedAmount = this.loadMoreAmount;
		this.currentCountIndex = 0;
		this.totalNewsItems = undefined;

		this.settings = {
			searchInProgress: false,
			showLoader: false,
			loaderSuspense: 400
		};

		onReady(() => this.initialize());
	}

	addLoader = parentAppendingTo => {
		const wrapper = createElement("div", {
			className: "press-release-list__loader"
		});

		addOldLoader(parentAppendingTo, {
			wrapperElement: wrapper
		});
	};

	removeLoader = () => {
		this.dom.loaderElement = this.dom.container.querySelector(
			".press-release-list__loader"
		);
		deleteElement(this.dom.loaderElement);
	};

	/**
     * Fetching the initial items and updating the necessary settings for this class.
     */
	loadInitialItems = () => {
		// Handling the loader suspense
		this.settings.searchInProgress = true;

		setTimeout(() => {
			if (this.settings.searchInProgress) {
				this.settings.showLoader = true;

				// Showing our loader since the threshold from the suspense is not reached
				this.addLoader(this.dom.pressReleaseListContainer);
			}
		}, this.settings.loaderSuspense);

		fetcher(
			`${this.endPoint}?language=${isRtl ? "arabic" : "english"}`,
			"GET"
		).then(response => {
			this.settings.searchInProgress = false;

			if (this.settings.showLoader) {
				this.settings.showLoader = false;

				this.removeLoader(this.dom.pressReleaseListContainer);
			}

			if (response?.success) {
				// Storing the data for load more use
				this.pressReleaseData = response.data;
				this.totalNewsItems = this.pressReleaseData.length;

				this.pressReleaseData.forEach((item, count) => {
					if (count <= this.loadMoreAmount - 1) {
						this.loadMarkup(item);
					}
				});

				if (this.totalNewsItems > this.loadedAmount) {
					removeClass(
						this.dom.loadMoreButton,
						"press-release-list__load-more--hide"
					);
				}
			} else {
				appendElement(
					`${
						isRtl
							? `يبدو أن هناك مشكلة في الحصول على الأخبار. الرجاء المحاولة في وقت لاحق `
							: `Looks like there was a problem getting the news. Please try again later.`
					}`,
					this.dom.pressReleaseListContainer
				);
			}
		});
	};

	/**
     * We have a ton of conditions here to help us update the state settings but also make sure our data set is being filtered if needed.
     */
	loadMore() {
		if (this.loadedAmount < this.totalNewsItems) {
			// Disable button while getting more polls
			this.dom.loadMoreButton.disabled = true;

			this.currentCountIndex =
                this.currentCountIndex + this.loadMoreAmount;

			this.pressReleaseData.forEach((item, count) => {
				if (
					count > this.loadedAmount - 1 &&
                    count < this.loadMoreAmount + this.loadedAmount
				) {
					this.loadMarkup(item);
				}
			});

			this.loadedAmount = this.currentCountIndex + this.loadMoreAmount;

			// If no more items remove listeners
			if (this.loadedAmount >= this.totalNewsItems) {
				this.dom.loadMoreButton.style.display = "none";
			} else {
				this.dom.loadMoreButton.disabled = false;
			}
		}
	}

	/**
     * Rendering the data in the correct place while also taking our inView functionality into account
     *
     * @param item
     */
	loadMarkup(item) {
		const pressReleaseListContainerHtml = pressReleaseItemMarkup(item);
		appendElement(
			pressReleaseListContainerHtml,
			this.dom.pressReleaseListContainer
		);

		const inViewClass = ".inview";
		const inViewElements =
            this.dom.pressReleaseListContainer.querySelector(inViewClass);

		if (inViewElements) {
			setupInView(inViewClass, "inview--active", "show", 0);
		}

		//check if item contains local-datetime module and initialize this if so
		const pressReleaseDate = this.dom.pressReleaseListContainer.querySelectorAll('.local-datetime--not-initialized[data-async-module="local-datetime"]');

		if (pressReleaseDate.length > 0) {
			pressReleaseDate.forEach(dateItem => {
				new LocalDatetime(dateItem);
				removeClass(dateItem, "local-datetime--not-initialized");
			});
		}
	}

	initialize() {
		this.dom.pressReleaseListContainer = this.dom.container.querySelector(".press-release-list__container");
		this.dom.loadMoreButton = this.dom.container.querySelector(".press-release-list__load-more");

		this.loadInitialItems();

		addEvent(this.dom.loadMoreButton, "click", () => {
			this.loadMore();
		});
	}
}
