import { onReady } from "~/foundation/Events/onReady";
import { addEvent, removeAllEvents } from "~/foundation/Events/events";
import { getParameter } from "~/foundation/Helpers/queryString";
// eslint-disable-next-line helix-structure/restricted-imports
import { Membership, membershipStartPages } from "~/feature/Membership/membership";
import "./top-navigation.scss";
import "./bottom-navigation.scss";

const isApp = document.querySelector(".is-app") !== null;
const membershipElement = document.querySelector("[data-module='membership']");

export class Navigation {
	dom = {
		container: undefined
	};

	constructor(domReference) {
		this.dom.container = domReference;

		onReady(() => this.initialize());
	}

	kill() {
		removeAllEvents(this.dom.navigationButton);
		removeAllEvents(this.dom.navigationCloseButton);
		removeAllEvents(this.dom.caretItems);

		if (this.dom.subCarets.length) {
			removeAllEvents(this.dom.subCarets);
		}

		if (this.dom.levelBackButtons.length) {
			removeAllEvents(this.dom.levelBackButtons);
		}

		if (this.dom.logInButton) {
			removeAllEvents(this.dom.logInButton);
		}

		if (this.dom.signUpButton) {
			removeAllEvents(this.dom.signUpButton);
		}

		if (this.dom.profileButton) {
			removeAllEvents(this.dom.profileButton);
		}
	}

	signUp = () => {
		if (isApp) {
			window.location.href = "aramcolife://opensignup";
		} else {
			if (membershipElement) {
				void new Membership(membershipElement, membershipStartPages.SIGNUP);
			}
		}
	};

	logIn = (e) => {
		if (isApp) {
			window.location.href = "aramcolife://openlogin";
		} else {
			if (membershipElement) {
				void new Membership(membershipElement, membershipStartPages.LOGIN);
			}
		}
	};

	profile = () => {
		if (isApp) {
			window.location.href = "aramcolife://openprofile";
		} else {
			if (membershipElement) {
				void new Membership(membershipElement, membershipStartPages.PROFILE);
			}
		}
	};

	initialize() {
		this.dom.logInButton = this.dom.container.querySelectorAll(".login");

		this.dom.signUpButton = this.dom.container.querySelectorAll(".signup");

		this.dom.profileButton = this.dom.container.querySelectorAll(".profile");

		if (this.dom.logInButton) {
			addEvent(this.dom.logInButton, "click", this.logIn);
		}

		if (this.dom.signUpButton) {
			addEvent(this.dom.signUpButton, "click", this.signUp);
		}

		if (this.dom.profileButton) {
			addEvent(this.dom.profileButton, "click", this.profile);
		}

		if (!membershipElement) return;

		this.showSignup =
			membershipElement.getAttribute("data-signup") === "true";
		this.showSelectTopics =
			membershipElement.getAttribute("data-select-topics") === "true";
		this.showResetPassword =
			membershipElement.getAttribute("data-reset-password") === "true";

		if (this.showSelectTopics) {
			new Membership(
				membershipElement,
				membershipStartPages.SELECT_TOPICS
			);
		} else if (this.showResetPassword) {
			new Membership(
				membershipElement,
				membershipStartPages.RESET_PASSWORD
			);
		} else if (this.showSignup) {
			new Membership(membershipElement, membershipStartPages.SIGNUP);
		}
	}
}