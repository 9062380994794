import { buildTextareaInput } from "../inputs/buildTextareaInput";
import { buildTextInput } from "../inputs/buildTextInput";

const stepSevenContentTemplate = ({ classes, model, sections }) => {
	const [sectionAnythingToAdd, sectionUsername] = sections;

	return `
        <div class="${classes.rootClass}__event-info">
        <h4>${model.texts.signupForLabel} ${model.eventModel.name}</h4>
        <time
            data-async-module="local-datetime"
            data-date-format="event-date-time"
            data-utc-start="${model.eventModel.startDateUTC}"
            data-utc-end="${model.eventModel.endDateUTC}"
            data-date-fallback="${model.eventModel.dateString}"
            data-use-ksa="true"
        ></time>
        </div>
        <div class="${classes.rootClass}__section">
            <fieldset class="${classes.rootClass}__fieldset">
                <legend class="visuallyhidden">Step 6</legend>

                <div class="${classes.rootClass}__username-section">
                    <h3>${sectionUsername.title}</h3>
                    <p>${sectionUsername.description}</p>
                    ${sectionUsername.fields
		.map(field => `${buildTextInput(field, classes)}`)
		.join("")}
                </div>

                <div class="${classes.rootClass}__textarea-group input ${
	classes.rootClass
}__textarea-group--default">
                    <h3>${sectionAnythingToAdd.title}</h3>
                    ${buildTextareaInput(sectionAnythingToAdd.fields[0])}
                </div>
            </fieldset>
        </div>
    `;
};

export default stepSevenContentTemplate;
