import { buildRadioInput } from "../inputs/buildRadioInput";

const stepFourContentTemplate = ({ classes, model, sections }) => {
	const [sectionLanguageEN, sectionLanguageAR] = sections;
	const errorMessageSectionLanguageEN = "step-four__section-language-en";
	const errorMessageSectionLanguageAR = "step-four__section-language-ar";

	return `
        <div class="${classes.rootClass}__event-info">
        <h4>${model.texts.signupForLabel} ${model.eventModel.name}</h4>
        <time
            data-async-module="local-datetime"
            data-date-format="event-date-time"
            data-utc-start="${model.eventModel.startDateUTC}"
            data-utc-end="${model.eventModel.endDateUTC}"
            data-date-fallback="${model.eventModel.dateString}"
            data-use-ksa="true"
        ></time>
        </div>
        <div class="${classes.rootClass}__section">
            <fieldset class="${classes.rootClass}__fieldset">
                <legend class="visuallyhidden">Step 4</legend>

                <div class="${classes.rootClass}__radio-group ${
	classes.rootClass
}__radio-group--default">
                    <h3>${sectionLanguageEN.title}</h3>
                    <ul class="${classes.rootClass}__vertical-list">
                        ${buildRadioInput(
		sectionLanguageEN.fields[0],
		errorMessageSectionLanguageEN
	)}
                    </ul>
                    <p class="${errorMessageSectionLanguageEN}"></p>
                </div>

                <div class="${classes.rootClass}__radio-group ${
	classes.rootClass
}__radio-group--default">
                    <h3>${sectionLanguageAR.title}</h3>
                    <ul class="${classes.rootClass}__vertical-list">
                        ${buildRadioInput(
		sectionLanguageAR.fields[0],
		errorMessageSectionLanguageAR
	)}
                    </ul>
                    <p class="${errorMessageSectionLanguageAR}"></p>
                </div>
            </fieldset>
        </div>
    `;
};

export default stepFourContentTemplate;
