import { triggerCustomEvent } from "~/foundation/Events/triggerCustomEvent";
import { getElementScroll } from "./elementProperties";
import { addClass, removeClass } from "./classList";

const className = "dis-scroll-lock";
let scrollTop = 0;

export let currentState = false;

/**
 * Enable the scroll lock.
 */
export function enableScrollLock() {
	if (!currentState) {
		const scrollPosition = getElementScroll();

		if (scrollPosition) {
			window.scrollTo(scrollPosition.left, 0);

			const htmlTag = document.documentElement;
			addClass(htmlTag, className);
			htmlTag.style.marginTop = `${-scrollPosition.top}px`;
			htmlTag.style.position = "fixed";
			htmlTag.style.overflow = "hidden";
			htmlTag.style.width = "100%";

			// Trigger event on target. You can listen for it using document.body.addEventListener("dis.scrolllock:enable", callbackHere)
			triggerCustomEvent(document.body, "dis.scrolllock:enable");

			// Remember state
			currentState = true;
			scrollTop = scrollPosition.top;
		}
	}
}

/**
 * @deprecated
 */
export const enable = enableScrollLock;

/**
 * Disable the scroll lock
 */
export function disableScrollLock() {

	if (currentState) {
		const scrollPosition = getElementScroll();

		if (scrollPosition) {
			const htmlTag = document.documentElement;
			removeClass(htmlTag, className);
			htmlTag.style.marginTop = "";
			htmlTag.style.position = "";
			htmlTag.style.overflow = "";
			htmlTag.style.width = "";

			// Set the scroll position to what it was before
			window.scrollTo(scrollPosition.left, scrollTop);

			// Trigger event on target. You can listen for it using document.body.addEventListener("dis.scrolllock:disable", callbackHere)
			triggerCustomEvent(document.body, "dis.scrolllock:disable");

			// Remember state
			currentState = false;
		}
	}
}

/**
 * @deprecated
 */
export const disable = disableScrollLock;

export function toggleScrollLock() {
	if (currentState) {
		disableScrollLock();
	} else {
		enableScrollLock();
	}
}
