import "@total-typescript/ts-reset";
import "./index.scss";
import { isExperienceEditor } from "~/foundation/Helpers/isExperienceEditor";
import LazyLoad from "vanilla-lazyload";
import { setupInView } from "~/foundation/Dom/inView";
import { Carousel } from "~/feature/Article/carousel";
// import { SocialBar } from "~/feature/Article/social-bar/social-bar";
import { ImageZoom } from "./lifeMedia/image-zoom/image-zoom";
import { InTheNews } from "./in-the-news/in-the-news";
import { VideoEpisodesUpcoming } from "~/feature/Streaming/video-episodes-upcoming/video-episodes-upcoming";
import { LocalDatetime } from "~/foundation/LocalDatetime/local-datetime";
// import { PollList } from "~/feature/Poll/poll-list/poll-list";
// import { PollQuizList } from "~/feature/Poll/poll-list/poll-quiz-list";
import { SignupRibbon } from "~/feature/Membership/signup-ribbon/signup-ribbon";
import { OnetrustToggle } from "~/foundation/Components/onetrust-toggle/onetrust-toggle";
import { HideStreams } from "~/feature/Streaming/hide-streams/hide-streams";
import { Greeting } from "~/feature/GreetingCard/greeting/greeting";
import { PressReleaseList } from "./press-release-list/press-release-list";
import { ShortcutNavigation } from "~/feature/Navigation/shortcut/shortcut-navigation.js";
import { AudioPlayer } from "~/feature/Article/audioplayer/audioplayer";
import { bootstrapper, bootstrapConstructorOnElement } from "~/foundation/Bootstrapper";
import { Navigation } from "~/feature/Navigation";
import { customMembershipTriggers, Membership, membershipStartPages } from "~/feature/Membership/membership";
import header from "./header/header";
import sectionSubNavigation from "~/feature/Navigation/section-sub/section-sub-navigation.ts";
import feedback from "./feedback/feedback.ts";
import topStorySlider from "./top-story-slider/top-story-slider.ts";
import openBookmarks from "./bookmarks/open-bookmarks.ts";
import { OwnerEventTabs } from "./volunteer/owner-event/owner-event-tabs/owner-event-tabs.js";
import { VolunteerSignup } from "./volunteer/signup/volunteer-signup.js";
import { Survey } from "./volunteer/survey/survey.js";
import { CertificateDownload } from "./volunteer/certificate/certificate-download.js";
import openRestrictContent from "./restricted-content/open-restrict-content.ts";
import { PdfViewer } from "./article/pdf-viewer/pdf-viewer";
import { getParameter } from "~/foundation/Helpers/queryString.ts";
import share from "~/feature/Article/action-bar/share/share.ts";
import headerSearch from "./header-search/header-search.ts";
import AnnouncementSlider from "./announcement/announcement-slider.ts";

const modules = {
	header, //example without codesplitting
	"header-search": headerSearch,
	feedback,
	"open-bookmarks": openBookmarks,
	"open-restricted-content": openRestrictContent,
	"top-story-slider": topStorySlider,
	"announcement-slider": AnnouncementSlider,
	"section-sub-navigation": sectionSubNavigation,
	"video-slider": () => import("~/project/AramcoLife/video-slider/video-slider.ts"), //example with codesplitting
	"welcome-banner": () => import("./welcome-banner/welcome-banner.ts"),
	"latest-travel-list": () => import("./article/latest-travel-list/latest-travel-list"),
	"latest-article-list": () => import("./article/latest-article-list/latest-article-list"),
	"latest-content-list": () => import("./search/lists/latest-content-list.ts"),
	"edition-list": () => import("./article/edition-list/edition-list"),
	"bookmark-page": () => import("./bookmarks/bookmark-page.ts"),
	"photo-gallery-list": () => import("./photo-gallery/photo-gallery-list.ts"),
	"featured-banner-slider": () => import("./featured/featured-banner-slider.ts"),
	"social-wall-list": () => import("./social-wall/social-wall-list.ts"),
	"slider": () => import("~/foundation/Slider/slider"),
	"image-slider": () => import("./image-slider/image-slider.ts"),
	"tab-navigation-iframe": () => import("~/feature/PageComponent/tab-navigation-iframe/tab-navigation-iframe.ts"),
	"three-spot-teaser": () => import("./three-spot-teaser/three-spot-teaser.ts"),
	"poll": () => import("~/feature/Poll/poll/poll.ts"),
	"poll-list": () => import("~/project/AramcoLife/poll-list/poll-list.ts"),
	// "quiz-list": () => import("~/feature/Poll/poll-list/poll-quiz-list.ts"),
	"chatbot": () => import("./chatbot/chatbot.ts"),
	"social-wall-hub-card": () => import("./lifeHub/social-wall-hub-card/social-wall-hub-card.ts"),
	"action-bar": () => import("~/feature/Article/action-bar/action-bar.ts"),
	"open-share": share,
	"site-search": () => import("./search/site-search.ts"),
	"social-wall-slider": () => import("./social-wall-slider/social-wall-slider.ts"),
	"featured-banner": () => import("./featured/featured-banner.ts"),
};

const legacyModules = {
	carousel: Carousel,
	// "social-bar": SocialBar,
	"image-zoom": ImageZoom,
	"in-the-news": InTheNews,
	"signup-ribbon": SignupRibbon,
	"onetrust-toggle": OnetrustToggle,
	"hide-streams": HideStreams,
	"video-episodes-upcoming": VideoEpisodesUpcoming,
	"local-datetime": LocalDatetime,
	"owner-event-tabs": OwnerEventTabs,
	"volunteer-signup": VolunteerSignup,
	survey: Survey,
	"certificate-download": CertificateDownload,
	"shortcut-navigation": ShortcutNavigation,
	greeting: Greeting,
	"press-release-list": PressReleaseList,
	audioplayer: AudioPlayer,
	"navigation": Navigation,
	"photo-gallery-detail": () => import("./photo-gallery/photo-gallery-detail.js").then(x => x.PhotoGalleryDetail),
	"photo-gallery-leaderboard": () => import("./photo-gallery/photo-gallery-leaderboard.js").then(x => x.PhotoGalleryLeaderboard),
	"audio-player": () => import("~/feature/Article/audioplayer/audioplayer.js").then(x => x.AudioPlayer),
};

document.addEventListener("DOMContentLoaded", () => {
	bootstrapper(modules, legacyModules);

	// Only init membership module if not closed
	const membershipElement = document.querySelector<HTMLElement>("[data-module='membership']");
	if (membershipElement) {
		const shouldShowSignUp = customMembershipTriggers.some((trigger) => membershipElement.getAttribute(trigger) === "true");

		if (shouldShowSignUp) {
			if (!isExperienceEditor) {
				bootstrapConstructorOnElement(
					membershipElement,
					Membership as any, // eslint-disable-line
					"membership",
					null
				);
			}
		} else {
			membershipElement.style.display = "none";
		}

		// We make the check for deeplink for Aramco users (IsInternal)
		// If the deeplink contains returnUrl, we prompt them with Login Overlay
		const urlParameter = getParameter("returnUrl")[0] || getParameter("ReturnUrl")[0] || "";
		if (urlParameter.length > 0) {
			new Membership(membershipElement, membershipStartPages.LOGIN);
		}
	}

	//****** Lazyload  ********/
	const lazyClass = ".lazy";
	const lazyloadConfig = {
		elements_selector: lazyClass,
		class_loading: "lz-loading",
		class_loaded: "lz-loaded",
	};

	//****** inView  ********/
	const inViewClass = ".inview";

	const inViewElements = document.body.querySelector(inViewClass);
	if (inViewElements && !isExperienceEditor) {
		setupInView(inViewClass, "inview--active", "show", 0);
	}

	// Check if there is any images to lazyLoad before init lazyload
	const lazyElements = document.querySelector(lazyClass);
	if (lazyElements) {
		void new LazyLoad(lazyloadConfig);
	}

	//to fix 100vw excl. scrollbar
	new ResizeObserver(() => {
		const vw = document.documentElement.clientWidth;
		document.documentElement.style.setProperty('--vw', `${vw}px`);
	}).observe(document.documentElement);

	void new PdfViewer(document.body, null);

	let intervalCount = 0;
	const oneTrustInterval = setInterval(() => {
		intervalCount++;
		if (intervalCount > 4) {
			clearInterval(oneTrustInterval);
		}
		if (window.OneTrust) {
			clearInterval(oneTrustInterval);
			window.OneTrust.OnConsentChanged(() => {
				window.location.reload();
			});
		}
	}, 800);
});
