export const setCookie = (name: string, value: string, options?: { days?: number; path?: string }) => {
	const optionsWithDefaults = {
		days: 7,
		path: "/",
		...options
	};

	const expires = new Date(Date.now() + optionsWithDefaults.days * 864e5).toUTCString();

	document.cookie =
		name +
		"=" +
		encodeURIComponent(value) +
		"; expires=" +
		expires +
		"; path=" +
		optionsWithDefaults.path;
};

export const getCookie = (name: string) => {
	return document.cookie.split("; ").reduce((r, v) => {
		const parts = v.split("=");
		return parts[0] === name ? decodeURIComponent(parts[1]) : r;
	}, "");
};
