import { parseHTML } from "~/foundation/Dom/elementManipulation";
import { closeMarkup } from "../../../../close";

export const hostOverviewMarkup = labels => {
	const {
		dependencyIntroTitle,
		dependencyIntroText,
		myDependencyLabel,
		confirmDisconnectButtonLabel,
		myDependencyDisconnectInfoText,
		cancelButtonLabel
	} = labels;

	return parseHTML(`
    <div class="membership-header">
        ${closeMarkup}
        <h2 class="membership-header__title"><strong>${dependencyIntroTitle}</strong></h2>
        <div class="membership-header__intro">${dependencyIntroText}</div>
    </div>
    <div class="animate-fade membership-middle dependent">
        <h3>${myDependencyLabel}</h3>
        <div class="dependent__host"></div>
        <hr />
        <div class="dependent__message">
            ${myDependencyDisconnectInfoText}
        </div>
    </div>
    <div class="membership-footer">
        <button class="animate-scale animate-scale--once cta cta--white button--confirm visuallyhidden" type="submit">
            <span>${confirmDisconnectButtonLabel}</span>
        </button>
        <button type="button" class="button-link button-link--secondary button--cancel">${cancelButtonLabel}</button>
    </div>`);
};
