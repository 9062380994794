import { createElement } from "~/foundation/Dom/createElement";
import { appendElement } from "~/foundation/Dom/elementManipulation";
import { addEvent, removeAllEvents } from "~/foundation/Events/events";
import { onReady } from "~/foundation/Events/onReady";
import {
	breakpointMin,
	onWindowResize,
} from "~/foundation/Events/onWindowResize";
import { isRtl } from "~/foundation/Helpers/isRtl";
import { pauseIcon, playIcon } from "./icons/icons";
import { audioBar } from "./markup/audioBar";

export class AudioPlayer {
	/**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
	dom = {
		container: undefined,
	};

	/**
     *
     * @param {Element} domReference - The element to work from.
     */
	constructor(domReference) {
		this.dom.container = domReference;
		this.dom.audioBarElement = this.dom.container.querySelector(
			".article-audioplayer__bar"
		);
		this.dom.audioPlayer = this.dom.container.querySelector(
			".article-audioplayer__audio"
		);
		this.isPlaying = false;
		onReady(() => this.initialize());
	}

	toggleAudioBar() {
		const audioUrl = this.dom.container.getAttribute("data-audio");
		if (!this.dom.audioBarElement) {
			this.isPlaying = true;
			this.dom.audioBarElement = createElement("section", {
				className: "article-audioplayer__bar",
				html: audioBar({
					duration: this.formatTime(0),
					currentTime: this.formatTime(0),
					audioFileUrl: audioUrl,
					isPlaying: this.isPlaying,
					title: this.dom.container.getAttribute("data-title"),
				}),
			});

			appendElement(this.dom.audioBarElement, this.dom.container);

			// Initialize the audio player element
			this.dom.audioPlayer = this.dom.audioBarElement.querySelector(".article-audioplayer__audio");
			this.dom.audioPlayer.src = audioUrl;

			// Handle metadata load and play
			addEvent(this.dom.audioPlayer, "canplaythrough", () => {
				this.updateAudioDuration(); // Update duration display once audio is fully loadable
				this.dom.audioPlayer.play().catch(error => {
					if (error.name !== 'AbortError') {
						console.error("Audio play error:", error);
					}
				});
			});

			addEvent(this.dom.audioPlayer, "timeupdate", () => this.updateAudioProgress());

			const playPauseButton = this.dom.audioBarElement.querySelector(".article-audioplayer__play-pause");

			addEvent(playPauseButton, "click", () => this.toggleAudioPlayer());
			this.dom.audioClose = this.dom.audioBarElement.querySelector(".article-audioplayer__toggle");
			if (this.dom.audioClose) {
				addEvent(this.dom.audioClose, "click", () => this.toggleAudioBar());
			}

			this.updateStartButtonText();
		} else {
			this.dom.audioBarElement.classList.add("fade-out");
			setTimeout(() => {
				if (this.dom.audioBarElement && this.dom.audioBarElement.parentNode === this.dom.container) {
				// Stop and reset the audio before removing it
					if (this.dom.audioPlayer) {
						this.dom.audioPlayer.pause();
						this.dom.audioPlayer.src = "";
					}
					this.dom.container.removeChild(this.dom.audioBarElement);
				}

				this.dom.audioBarElement = null;
				this.updateStartButtonText();
			}, 700);
		}
	}

	// Toggle audio play/pause state
	toggleAudioPlayer() {
		if (this.dom.audioPlayer.paused) {
			this.dom.audioPlayer.play();
			this.isPlaying = true;
		} else {
			this.dom.audioPlayer.pause();
			this.isPlaying = false;
		}
		this.updatePlayPauseButton();
	}

	updatePlayPauseButton() {
		const playPauseButton = this.dom.audioBarElement.querySelector(
			".article-audioplayer__play-pause"
		);
		if (playPauseButton) {
			playPauseButton.innerHTML = this.isPlaying ? pauseIcon : playIcon;
			playPauseButton.setAttribute(
				"aria-label",
				this.isPlaying ? "Pause audio" : "Play audio"
			);
		}
	}

	// Update the audio progress bar + time displays
	updateAudioProgress() {
		if (!this.dom.audioBarElement) {
			return;
		}

		const progressElement = this.dom.audioBarElement.querySelector(
			".article-audioplayer__time-current"
		);
		const remainingTimeElement = this.dom.audioBarElement.querySelector(
			".article-audioplayer__time-remaining"
		);
		const progressBarElement = this.dom.audioBarElement.querySelector(
			".article-audioplayer__progressbar"
		);

		if (
			progressElement &&
            progressBarElement &&
            remainingTimeElement &&
            this.dom.audioPlayer
		) {
			const currentTime = this.dom.audioPlayer.currentTime;
			const duration = this.dom.audioPlayer.duration;

			progressElement.textContent = this.formatTime(currentTime);

			const percentage =
                duration > 0 ? (currentTime / duration) * 100 : 0;
			progressBarElement.style.width = `${percentage}%`;

			const playPauseButton = this.dom.audioBarElement.querySelector(
				".article-audioplayer__play-pause"
			);

			if (playPauseButton) {
				const percentage = (currentTime / duration) * 100;
				isRtl
					? (playPauseButton.style.right = `${percentage}%`)
					: (playPauseButton.style.left = `${percentage}%`);

				isRtl
					? (playPauseButton.style.transform = `translateX(${percentage}%)`)
					: (playPauseButton.style.transform = `translateX(-${percentage}%)`);
			}

			const remainingTime = duration - currentTime;
			remainingTimeElement.textContent = `-${this.formatTime(
				remainingTime
			)}`;
		}
	}

	// Update the duration display after metadata is loaded
	updateAudioDuration() {
		if (this.dom.audioPlayer) {
			const duration = this.dom.audioPlayer.duration;
			const remainingTimeElement = this.dom.audioBarElement.querySelector(
				".article-audioplayer__time-remaining"
			);
			if (remainingTimeElement) {
				remainingTimeElement.textContent = `-${this.formatTime(
					duration
				)}`;
			}
		}
	}

	// Format from seconds to "MM:SS" format
	formatTime(seconds) {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = Math.floor(seconds % 60);
		return `${minutes}:${
			remainingSeconds < 10 ? "0" : ""
		}${remainingSeconds}`;
	}

	updateStartButtonText() {
		const startButton = this.dom.container.querySelector(
			".article-audioplayer__start"
		);

		if (startButton) {
			if (
				!this.dom.audioBarElement &&
                startButton.classList.contains("hovered")
			) {
				startButton.classList.remove("hovered");
			}

			startButton.setAttribute(
				"aria-label",
				this.dom.audioBarElement ? "Close Audio Bar" : "Open Audio Bar"
			);

			if (this.dom.audioBarElement && breakpointMin("md")) {
				startButton.classList.add("hide-text");
			} else if (!this.dom.audioBarElement) {
				startButton.classList.remove("hide-text");
			}

			const addHoverClass = () => {
				if (this.dom.audioBarElement) {
					startButton.classList.add("hovered");
				}
			};

			const removeHoverClass = () => {
				if (this.dom.audioBarElement) {
					startButton.classList.remove("hovered");
				}
			};

			// Add event listeners if the bar is open
			if (this.dom.audioBarElement) {
				addEvent(startButton, "mouseenter", addHoverClass);
				addEvent(startButton, "mouseleave", removeHoverClass);
			}
		}
	}

	kill() {
		removeAllEvents(this.dom.startButton);
		removeAllEvents(this.dom.audioPlayer);
	}

	// Init audio player
	initialize() {
		this.dom.audioStart = this.dom.container.querySelector(
			".article-audioplayer__start"
		);

		addEvent(this.dom.audioStart, "click", () => {
			this.toggleAudioBar();
		});

		// Update the start button text on window resize
		onWindowResize(() => {
			this.updateStartButtonText();
		});
	}
}
