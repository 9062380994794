import {
	appendElement,
	emptyElement
} from "~/foundation/Dom/elementManipulation";
import {
	addEvent,
	addEventOnce,
	removeAllEvents
} from "~/foundation/Events/events";
import { profileMarkup } from "./markup";
import { Newsletter } from "./newsletter/newsletter";
import { Membership, membershipStartPages } from "../membership";
import { Snackbar } from "~/foundation/Components/snackbar/snackbar";
import { fetcher } from "~/foundation/Api";
import { STANDARDCUBICBEZIER } from "~/foundation/Styles/easings";
import { isRtl } from "~/foundation/Helpers/isRtl";

export class Profile {
	/**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
	dom = {};

	/**
     *
     * @param {Element} domReference - The element to work from. -> membership__content
     */
	constructor(config) {
		this.config = config;

		this.onChangePasswordClick = config.onChangePasswordClick;
		this.onEditNameClick = config.onEditNameClick;

		this.dom.membershipContent = config.membershipContent;

		this.texts = config.texts;
		this.user = config.user ? config.user : {};
		this.errorMessages = config.errorMessages;
		this.languageCode = config.languageCode;

		this.subscriptionLists = config.subscriptionLists;
		this.dependentsLists = config.dependentsLists;

		this.snackbar = undefined;

		this.logoutApiUrl = config.logoutApiUrl;
		this.logOutRedirectUrl = config.logOutRedirectUrl;
		this.deleteAccountApiUrl = config.deleteAccountApiUrl;
		this.deleteAccountRedirectUrl = config.deleteAccountRedirectUrl;

		this.easing = STANDARDCUBICBEZIER;
		this.durationIn = 1500;
		this.durationOut = 500;

		this.initialize();
	}

	kill = () => {
		this.newsletter.kill();
		removeAllEvents([
			this.dom.editNameButton,
			this.dom.changePasswordButton,
			this.dom.logoutButton,
			this.dom.deleteButton,
			this.dom.dependencyButton
		]);
	};

	logout = () => {
		fetcher(this.logoutApiUrl).then(() => {
			this.kill();
			window.location.href = this.logOutRedirectUrl;
		});
	};

	delete = () => {
		// Hide snackbar so we don't create multiple instances
		if (this.snackbar) {
			this.snackbar.close();
		}

		this.snackbar = new Snackbar({
			element: this.dom.deleteButton,
			text: this.texts.confirmDeleteAccountLabel,
			autoClose: false,
			confirm: true,
			cancelLabel: this.texts.noButtonLabel,
			confirmLabel: this.texts.yesButtonLabel,
			position: isRtl ? "left" : "right",
			onCancel: () => {
				this.snackbar.close();
				this.snackbar = undefined;
			},
			onConfirm: () => {
				fetcher(this.deleteAccountApiUrl, "POST", {
					email: this.user.emailAddress
				}).then(result => {
					if (result.success) {
						this.kill();
						window.location.href = this.deleteAccountRedirectUrl;
					} else {
						alert("delete error");
					}
				});
			}
		});
	};

	showDependency = () => {
		const membershipElement = document.querySelector(
			"[data-module='membership']"
		);
		if (membershipElement) {
			new Membership(membershipElement, membershipStartPages.DEPENDENT);
		}
	};

	initialize() {
		const profileHtml = profileMarkup(
			this.texts,
			this.user,
			this.subscriptionLists,
			this.dependentsLists
		);

		emptyElement(this.dom.membershipContent);
		appendElement(profileHtml, this.dom.membershipContent);

		this.dom.membershipContent.classList.add("scrollable");

		this.dom.editNameButton =
            this.dom.membershipContent.querySelector(".edit-name");

		addEvent(this.dom.editNameButton, "click", () => {
			this.onEditNameClick();
		});

		this.dom.changePasswordButton =
            this.dom.membershipContent.querySelector(".change-password-button");

		addEvent(this.dom.changePasswordButton, "click", () => {
			this.onChangePasswordClick();
		});

		this.newsletter = new Newsletter(this.config);

		this.dom.logoutButton =
            this.dom.membershipContent.querySelector(".logout");
		this.dom.deleteButton =
            this.dom.membershipContent.querySelector(".delete");

		addEvent(this.dom.deleteButton, "click", this.delete);
		addEventOnce(this.dom.logoutButton, "click", this.logout);

		this.dom.dependencyButton = this.dom.membershipContent.querySelector(
			"[data-status='dependency']"
		);

		addEvent(this.dom.dependencyButton, "click", this.showDependency);
	}
}
