import { type Module } from "~/foundation/Bootstrapper/types";
import { useDialog } from "~/foundation/Components/dialog/useDialog";
import { globalDictionary } from "~/foundation/Dictionary";
import { setupInView } from "~/foundation/Dom/inView";
import binSvg from "~/foundation/Icons/bin";
import bookmarkSvg from "~/foundation/Icons/bookmark";
import imgPlaceholder from "~/foundation/Icons/img-placeholder";
import { type BookmarkModel } from "../generated-types";
import { bookmarkService } from "../services/bookmarkService";
import { useToggleBookmark } from "./useToogleBookmark";
import { useBookmarkCount } from "./useBookmarkCount.ts";

const dialog = useDialog();
const bookmarkCount = useBookmarkCount();

function bookmarksDialogTemplate(bookmarks: BookmarkModel[]) {
	const isPageBookmarked = document.body.classList.contains("pageIsBookmarked");

	return /*html*/`
		<div class="bookmarks">
			<div class="bookmarks-title">
				${bookmarkSvg}
				<h3>${globalDictionary.bookmarksTitle}</h3>
			</div>
			${bookmarks?.map(bookmark => /*html*/`
				<div data-page-Item-Id="${bookmark.sitecorePageItemId}" class="bookmark inview">
					<a href="${bookmark.url}" class="bookmark-item">
						${bookmark.imageSource
			? `<img src="${bookmark.imageSource}" class="bookmark-item-img" />`
			: `<div class="bookmark-item-img">${imgPlaceholder}</div>`
		}
						<h5 class="bookmark-item-title">${bookmark.title}</h5>
					</a>
					<button class="bookmark__delete">${binSvg}</button>
				</div>
			`).join("")}
			${!isPageBookmarked ? `<button class="bookmark__add cta">${globalDictionary.bookmarksAddLabel}</button>` : ''}
		</div>
	`;
}

async function renderBookmarks() {
	const response = await bookmarkService.getAll();
	const bookmarks = response.data;
	const pageItemId = document.body.dataset.currentItemId!;
	await dialog.setContent(bookmarksDialogTemplate(bookmarks || []));

	// Setup in-view for new bookmark items one by one
	const inViewElements = dialog.element?.querySelectorAll<HTMLElement>(".bookmark.inview");
	if (inViewElements) {
		inViewElements.forEach((element, index) => {
			setTimeout(() => {
				const itemId = element.getAttribute('data-page-item-id');
				if (itemId) {
					setupInView(`[data-page-item-id="${itemId}"]`, "inview--active", "show", 0);
				}
			}, index * 200);
		});
	}

	dialog.element?.querySelectorAll<HTMLButtonElement>("button.bookmark__delete")?.forEach(buttonElement => {
		buttonElement.addEventListener("click", async () => {
			const bookmarkPageItemId = buttonElement.parentElement?.dataset.pageItemId;
			if (bookmarkPageItemId === pageItemId.toLowerCase().replace(/[{}]/g, '')) {
				const response = await useToggleBookmark(bookmarkPageItemId!);
				if (response.success) {
					await renderBookmarks();
				}
			} else {
				const response = await bookmarkService.delete(bookmarkPageItemId!);
				if (response.success) {
					await bookmarkCount.decrease();
					await renderBookmarks();
				}
			}
		})
	});

	dialog.element?.querySelector("button.bookmark__add")?.addEventListener("click", async () => {
		const response = await useToggleBookmark(pageItemId!);
		if (response.success) {
			await dialog.setContent("").then(renderBookmarks);
		}
	});
}

const openBookmarks: Module = (element) => {
	const socialBarWrapper = document.querySelector<HTMLElement>(".social-bar");
	const isActionBarElem = element.parentElement?.classList.contains("social-bar__menu--vertical");
	const focusElement = isActionBarElem ? socialBarWrapper : undefined;

	element.addEventListener("click", async () => {
		await dialog.open(undefined, undefined, focusElement);
		await renderBookmarks();
	});
};

export default openBookmarks;