import { type Module } from "~/foundation/Bootstrapper/types";
import { useDialog } from "~/foundation/Components/dialog/useDialog";
import { articleService } from "../../services/articleService";

const dialog = useDialog();

const share: Module = (container: HTMLElement) => {

	const copyLink = async () => {
		const copyIcon = document.body.querySelector(".copy__icon");
		navigator.clipboard.writeText(window.location.href);
		if (copyIcon) {
			copyIcon.classList.add("copied");
			setTimeout(() => {
				copyIcon.classList.remove("copied");
			}, 500);
		}
	};

	const shareFB = async () => {
		const url = `https://facebook.com/sharer.php?display=popup&u=${window.location.href}`;
		window.open(url, "sharer", "toolbar=0,status=0,resizable=1,width=626,height=436");
		await share();
	};

	const shareTwitter = async () => {
		const url = `https://x.com/share?url=${window.location.href}`;
		window.open(url, "sharer", "toolbar=0,status=0,resizable=1,width=626,height=436");
		await share();
	};

	const shareLinkedIn = async () => {
		const url = `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`;
		window.open(url, "sharer", "toolbar=0,status=0,resizable=1,width=626,height=436");
		await share();
	};

	const share = async () => {
		const currentItemId = document.body.getAttribute("data-current-item-id");
		if (currentItemId) {
			const service = articleService.share;
			await service(currentItemId, { articleId: currentItemId });
		}
	};

	const addEventListeners = () => {
		const facebookShare = dialog.element?.querySelector(".social--facebook");
		const twitterShare = dialog.element?.querySelector(".social--twitter");
		const linkedinShare = dialog.element?.querySelector(".social--linkedin");
		const copyLinkButton = dialog.element?.querySelector(".copy-link");
		const copyLinkUrl = dialog.element?.querySelector(".copy-link__url");
		const currentUrl = window.location.href;
		if (copyLinkUrl) copyLinkUrl.textContent = currentUrl;

		facebookShare?.addEventListener("click", async () => await shareFB());
		twitterShare?.addEventListener("click", async () => await shareTwitter());
		linkedinShare?.addEventListener("click", async () => await shareLinkedIn());
		copyLinkButton?.addEventListener("click", async () => await copyLink());
	};

	const toggleShareDialog = async () => {
		const shareDialog = document.querySelector("#share-dialog");
		const shareDialogHTML = shareDialog?.innerHTML;
		if (!shareDialogHTML) return;

		const socialBar = document.querySelector<HTMLElement>(".social-bar");
		if (socialBar) {
			socialBar.tabIndex = -1;
			socialBar.focus();
		}

		await dialog.open(shareDialogHTML);
		addEventListeners();
	};

	// Open dialog and set up listeners
	container?.addEventListener("click", async () => {
		const actionBar = document.querySelector<HTMLElement>(".action-bar");
		const menu = actionBar?.querySelector<HTMLElement>(".social-bar__menu");

		if (actionBar && menu) {
			await toggleShareDialog();
		}
	});
};

export default share;
