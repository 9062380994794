import { buildTextInput } from "../inputs/buildTextInput";

const stepThreeTemplate = ({ classes, model, sections, stepText }) => {
	const errorMessageStepThree = `${classes.rootClass}__error-message`;

	return `
		<div class="greeting__step-3">
			<div class="${classes.rootClass}__header">
				<p class="${classes.rootClass}__manchet">${stepText}</p>
				<h2 class="${classes.rootClass}__title">${sections.title}</h2>
			</div>

			<div class="${classes.rootClass}__section">
				<div class="${classes.rootClass}__preview-container"></div>
				<fieldset class="${classes.rootClass}__fieldset">
					<div class="${classes.rootClass}__ghost-input">
						${sections.fields.map(field => `${buildTextInput(field, classes)}`).join("")}
						<p class="${errorMessageStepThree}"></p>
					</div>

					<div class="${classes.rootClass}__share-buttons">
						<button type="button" class="cta cta--outline greeting__buttons--back"></button>
						<button type="button" class="cta cta--primary greeting__buttons--continue" data-step="3"></button>
					</div>
				</fieldset>
			</div>
		</div>
  `;
};

export default stepThreeTemplate;
