/* eslint-disable @typescript-eslint/no-explicit-any */
import { forEach } from "~/foundation/Helpers/forEach";

export function triggerCustomEvent(target: HTMLElement | HTMLElement[], eventName: string, eventData: any = {}) {
	forEach(target, element => {
		let event;

		if (window.CustomEvent) {
			event = new CustomEvent(eventName, {
				detail: eventData,
				bubbles: true,
				cancelable: true
			});
		} else {
			event = document.createEvent("CustomEvent");
			event.initCustomEvent(eventName, true, true, eventData);
		}

		element.dispatchEvent(event);
	});
}
