import { newsletterMarkup } from "./newsletter/markup";
import { dependentMarkup } from "./dependent/markup";
import { closeMarkup } from "../close";
import { parseHTML } from "~/foundation/Dom/elementManipulation";
import { lockIconLocked } from "~/foundation/Icons/lock";

export const profileMarkup = (
	labels,
	user,
	subscriptionLists,
	dependentsLists
) => {
	const {
		headerTitle,
		editNameLabel,
		changePasswordLabel,
		deleteAccountLabel,
		logoutLabel
	} = labels;

	const {
		firstName,
		lastName,
		emailAddress,
		isInternal,
		showManageHostDependency
	} = user;

	return parseHTML(`
    <div class="membership-header membership-header--profile">
        ${closeMarkup}
        <h2 class="animate-fade membership-header__title"><strong>${headerTitle}</strong></h2>
    </div>
    <div class="membership-middle membership-middle--profile">
        <div class="animate-translate name">
            <div>
                <span class="firstName">${firstName}</span>
                <span class="lastName">${lastName}</span>
            </div>
            <button type="button" class="animate-fade button-link button-link--secondary edit-name">${editNameLabel}</button>
        </div>
        <div class="animate-translate">${emailAddress}</div>
        <span class="change-password">
            <button type="button" class="animate-translate change-password-button button-link button-link--secondary button--icon">
            ${lockIconLocked} ${changePasswordLabel}</button>
        </span>
        ${
            isInternal || showManageHostDependency
                ? dependentMarkup(labels, user, dependentsLists)[0].innerHTML
                : ""
        }
        ${
            newsletterMarkup(labels, subscriptionLists, user.acceptSubscribe)[0]
                .outerHTML
        }
    </div>
    <div class="membership-footer membership-footer--profile">
        <button type="button" class="animate-fade button button--secondary button--secondary-white delete">${deleteAccountLabel}</button>
        <button type="button" class="animate-fade button button--secondary button--secondary-white logout">${logoutLabel}</button>
    </div>`);
};
