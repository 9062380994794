import { parseHTML } from "~/foundation/Dom/elementManipulation";
import viewPasswordSvg from "~/foundation/Icons/view-password";
import { closeMarkup } from "../../../close";

export const loginMarkup = labels => {
	const {
		headerTitle,
		loginText,
		emailLabel,
		passwordLabel,
		forgotPasswordLabel,
		logInLabel,
		createAccountHeader,
		createAccountLabel
	} = labels;

	return parseHTML(`
    <div class="membership-header">
        ${closeMarkup}
        <h2 class="animate-fade membership-header__title">${headerTitle}</h2>
        <div class="animate-fade membership-header__text membership-header__text--truncated">${loginText}</div>
    </div>
    </div>
    <div class="membership-middle membership-middle--login-email-password">
        <div class="animate-translate input">
            <label for="email">${emailLabel}</label>
            <input autocomplete="off" required type="text" id="email" name="email" />
            <span class="error"></span>
        </div>
        <div class="animate-translate input">
            <label for="email">${passwordLabel}</label>
            <input autocomplete="off" required type="password" id="password" name="password" />
            <span class="error"></span>
            <button class="toggle-visible-password" type="button">${viewPasswordSvg}</button>
        </div>
        <div class="animate-translate membership-middle__login-buttons">
            <button class="animate-scale cta cta--white button--login type="submit">
                <span>${logInLabel}</span>
            </button>
            <button type="button" class="animate-scale forgot-password button-link button-link--secondary">${forgotPasswordLabel}</button>
        </div>
    </div>
    <div class="membership-footer membership-footer--login-email-password">
        <button type="button" class="animate-fade button-link button-link--secondary--white create-account">
            ${createAccountHeader}</br>
            ${createAccountLabel}
        </button>
    </div>`);
};
