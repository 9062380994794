import {
	appendElement,
	emptyElement
} from "~/foundation/Dom/elementManipulation";
import { overviewMarkup } from "./list-markup";
import { addEvent, removeAllEvents } from "~/foundation/Events/events";
import { fetcher } from "~/foundation/Api";
import { listItemMarkup } from "./list-item-markup";
import { Snackbar } from "~/foundation/Components/snackbar/snackbar";
import { isRtl } from "~/foundation/Helpers/isRtl";

export class OverviewScreen {
	/**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
	dom = {};

	/**
     *
     * @param {Element} domReference - The element to work from. -> membership__content
     */
	constructor(config) {
		this.dom.container = config.container;
		this.dom.membershipContent = config.membershipContent;
		this.texts = config.texts;
		this.user = config.user ? config.user : {};
		this.errorMessages = config.errorMessages;

		this.snackbar = undefined;

		this.getDependentsApiUrl = config.apiEndPoints.getDependentsApiUrl;
		this.deleteDependentApiUrl = config.apiEndPoints.deleteDependentApiUrl;

		this.callbacks = {
			onAddClick: config.onAddClick,
			onCancelClick: config.onCancelClick
		};

		this.initialize();
	}

	createList = list => {
		emptyElement(this.dom.list);

		if (list.length > 0) {
			list.forEach(dependent => {
				const listItem = listItemMarkup(this.texts, dependent);
				appendElement(listItem, this.dom.list);
			});

			this.dom.deleteLinks =
                this.dom.membershipContent.querySelectorAll(".button--delete");

			addEvent(this.dom.deleteLinks, "click", this.remove);
		} else {
			appendElement(this.texts.noDependentsText, this.dom.list);
		}
	};

	getList = () => {
		fetcher(this.getDependentsApiUrl).then(result => {
			if (result.success) {
				this.createList(result.data.list);
			}
		});
	};

	remove = element => {
		// Hide snackbar so we don't create multiple instances
		if (this.snackbar) {
			this.snackbar.close();
		}

		const button = element.target;
		const listItem = button.closest(".dependent__list-item");

		this.snackbar = new Snackbar({
			element: element.target,
			text: this.texts.confirmDeleteText,
			autoClose: false,
			confirm: true,
			cancelLabel: this.texts.noButtonLabel,
			confirmLabel: this.texts.yesButtonLabel,
			position: isRtl ? "right" : "left",
			onCancel: () => {
				this.snackbar.close();
				this.snackbar = undefined;
			},
			onConfirm: () => {
				fetcher(this.deleteDependentApiUrl, "POST", {
					DependentId: listItem.dataset.id
				}).then(result => {
					if (result.success) {
						this.getList();
					} else {
						alert("remove error");
					}
				});
			}
		});
	};

	kill = () => {
		removeAllEvents([this.dom.addButton, this.dom.cancelButton]);
		this.callbacks.onCancelClick();
	};

	initialize() {
		this.getList();
		const overviewHtml = overviewMarkup(this.texts, this.user);

		emptyElement(this.dom.membershipContent);
		appendElement(overviewHtml, this.dom.membershipContent);

		this.dom.list =
            this.dom.membershipContent.querySelector(".dependent__list");

		this.dom.addButton =
            this.dom.membershipContent.querySelector(".button--add");
		addEvent(this.dom.addButton, "click", this.callbacks.onAddClick);

		this.dom.cancelButton =
            this.dom.container.querySelector(".button--cancel");
		addEvent(this.dom.cancelButton, "click", this.kill);
	}
}
