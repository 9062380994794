import { appendElement } from "~/foundation/Dom/elementManipulation";
import {
	addEvent,
	addEventOnce,
	removeAllEvents
} from "~/foundation/Events/events";
import { ToggleVisiblePassword } from "~/foundation/Form/input/toggle-visible-password";
import { resetPasswordMarkup } from "./markup";
import {
	showError,
	removeErrors,
	removeError
} from "~/foundation/Form/input/input-errors";
import { fetcher } from "~/foundation/Api";
import { makeButtonLoad } from "~/foundation/Components/button/make-button-load";
import { getParameter } from "~/foundation/Helpers/queryString";

export class ResetPassword {
	/**
		 * Internal placeholder for cached DOM-objects.
		 *
		 * @type {object}
		 * @ignore
		 */
	dom = {};

	/**
		 *
		 * @param {Element} domReference - The element to work from. -> membership__content
		 */
	constructor(config) {
		this.dom.membershipContent = config.membershipContent;
		this.texts = config.texts;
		this.errorMessages = config.errorMessages;
		this.resetPasswordUrl = config.resetPasswordUrl;

		this.callbacks = {
			onSavePasswordSuccess: config.onSavePasswordSuccess,
			onStartOverClick: config.onStartOverClick
		};

		this.initialize();
	}

	kill() {
		removeAllEvents(this.dom.continueButton);
		removeAllEvents(this.dom.passwordInput);
	}

	goToLogin = () => {
		this.kill();
		this.callbacks.onStartOverClick();
	};

	onPasswordFocus(e) {
		const password = e.target;
		removeError(password);
	}

	validatePasswordAndSave = () => {
		if (this.dom.passwordInput.value === "") {
			showError(
				this.dom.passwordInput,
				this.errorMessages.passwordRequired
			);
			return;
		} else {
			removeErrors();
		}

		const buttonLoader = new makeButtonLoad(
			this.dom.continueButton,
			this.texts.loading
		);
		buttonLoader.load();

		fetcher(this.resetPasswordUrl, "POST", {
			email: getParameter("email")[0] || "",
			newPassword: this.dom.passwordInput.value,
			token: getParameter("token")[0] || ""
		}).then(result => {
			buttonLoader.finish(true).then(() => {
				if (result.success) {
					this.callbacks.onSavePasswordSuccess();
				} else {
					showError(this.dom.passwordInput, result.errors[0].message);
				}
			});
		});
	};

	initialize() {
		this.dom.errorMessage = document.querySelector(".error-message");

		const passwordHtml = resetPasswordMarkup(
			this.texts,
			this.dom.errorMessage,
			getParameter("email")[0]
		);

		appendElement(passwordHtml, this.dom.membershipContent);

		const passwordToggleButton = this.dom.membershipContent.querySelector(
			".toggle-visible-password"
		);
		this.togglePasswordVisibility = new ToggleVisiblePassword(
			passwordToggleButton
		);

		if (this.dom.errorMessage) {
			this.dom.membershipContent.className =
				"membership__content membership__content--reset-password-error";
		} else {
			this.dom.membershipContent.className =
				"membership__content membership__content--reset-password";
		}

		this.dom.continueButton = this.dom.membershipContent.querySelector(
			".membership__content--reset-password .membership-footer .cta"
		);

		this.dom.startOverButton = this.dom.membershipContent.querySelector(
			".membership__content--reset-password-error .membership-footer .cta"
		);

		this.dom.passwordInput =
			this.dom.membershipContent.querySelector("#password");

		this.dom.membershipContent.autocomplete = "off";

		addEventOnce(this.dom.startOverButton, "click", this.goToLogin);
		addEvent(
			this.dom.continueButton,
			"click",
			this.validatePasswordAndSave
		);
		addEvent(this.dom.passwordInput, "focus", this.onPasswordFocus);
	}
}
