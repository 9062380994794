import { addEvent, removeAllEvents } from "~/foundation/Events/events";
import { onReady } from "~/foundation/Events/onReady";

/**
 * Toggle show/hide password in inputs
 */

export class ToggleVisiblePassword {
	/**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
	dom = {
		container: undefined
	};

	/**
     *
     * @param {Element} domReference - The element to work from.
     */
	constructor(domReference) {
		this.dom.container = domReference;

		onReady(() => this.initialize());
	}

	toggleVisible = e => {
		const input = e.target.parentNode.querySelector("input");
		const typeIsPassword = input.getAttribute("type") === "password";
		input.setAttribute("type", typeIsPassword ? "text" : "password");
	};

	kill = () => {
		removeAllEvents(this.dom.container);
	};

	initialize() {
		addEvent(this.dom.container, "click", this.toggleVisible);
	}
}
