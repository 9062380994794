import { buildCheckboxInput } from "../inputs/buildCheckboxInput";

const stepFiveContentTemplate = ({ classes, model, sections }) => {
	const errorMessageStepFive = "step-five";

	return `
        <div class="${classes.rootClass}__event-info">
        <h4>${model.texts.signupForLabel} ${model.eventModel.name}</h4>
        <time
            data-async-module="local-datetime"
            data-date-format="event-date-time"
            data-utc-start="${model.eventModel.startDateUTC}"
            data-utc-end="${model.eventModel.endDateUTC}"
            data-date-fallback="${model.eventModel.dateString}"
            data-use-ksa="true"
        ></time>
        </div>
        <div class="${classes.rootClass}__section">
            <fieldset class="${classes.rootClass}__fieldset">
                <legend class="visuallyhidden">Step 5</legend>
                <div class="${classes.rootClass}__checkbox-group ${
	classes.rootClass
}__checkbox-group--default">
                    <h3>${sections.title}</h3>
                    <ul class="${classes.rootClass}__vertical-list">
                        ${buildCheckboxInput(
		sections.fields[0],
		errorMessageStepFive
	)}
                    </ul>

                    <p class="${errorMessageStepFive}"></p>
                </div>
            </fieldset>
        </div>
    `;
};

export default stepFiveContentTemplate;
