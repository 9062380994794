const createMarkup = (option, field) => {
	const label = option.label ? option.label : field.label;
	const value = option.value ? option.value : field.value;

	return `
        <label for="${field.key}">${label}</label>
        <textarea id="${field.key}"
            maxlength="2000"
            name="${field.key}"
            ${label ? ` placeholder="${label}"` : ``}
            ${field.readOnly ? ` readonly` : ``}
            ${field.isRequired ? ` required` : ``}
        >${value || ""}</textarea>
    `;
};

export const buildTextareaInput = field => {
	return field.options
		? field.options.map(option => createMarkup(option, field)).join("")
		: createMarkup({}, field);
};
