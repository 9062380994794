import { parseHTML } from "~/foundation/Dom/elementManipulation";
import { closeMarkup } from "../../../close";
import checkmark from "~/foundation/Icons/checkmark";

export const selectLanguageMarkup = labels => {
	const { headerTitle, signupTitle, languages, continueLabel, logInLabel } =
        labels;

	let languagesString = "";

	for (let index = 0; index < languages.length; index++) {
		const language = languages[index];
		languagesString =
        languagesString +
        `<button id="${
            language.languageCode
        }" class="animate-translate button button--secondary button--secondary-white${
            language.isSelected ? " button--selected" : ""
        } button-${language.title.toLowerCase()}" type="button">
            <span>${language.title}</span>
            <span class="icon">${checkmark}</span>
        </button>`;
	}

	return parseHTML(`
    <div class="membership-header membership-header--signup-select-language">
        ${closeMarkup}
        <h2 class="animate-fade membership-header__title">${headerTitle}</h2>
    </div>
    <div class="membership-middle membership-middle--signup-select-language">
        <h3 class="animate-translate">${signupTitle}</h3>
        <div class="select-language">
            ${languagesString}
        </div>
    </div>
    <div class="membership-footer membership-footer--signup-select-language">
        <button class="animate-scale cta cta--white" type="submit">
            <span>${continueLabel}</span>
        </button>
        <button class="animate-fade login button-link button-link--secondary" type="button">${logInLabel}</button>
    </div>`);
};
