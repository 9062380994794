import { forEach } from "~/foundation/Helpers/forEach";
import { splitter } from "~/foundation/Helpers/splitter";

/**
 * @deprecated
 */
export function addClass(input: Element | Element[] | NodeList, classNames: string | string[]) {
	forEach(input, element => {
		splitter(classNames, className => {
			if (element.classList) {
				element.classList.add(className);
			} else {
				element.className += ` ${className}`;
			}
		});
	});
}

/**
 * @type {function}
 * @ignore
 * @deprecated
 */
export const add = addClass;

/**
 * @deprecated
 */
export function hasClass(element: Element, className: string) {
	if (element && (element.classList || element.className)) {
		if (element.classList) {
			return element.classList.contains(className);
		} else {
			return new RegExp(`(^| ) ${className}( |$)`, "gi").test(
				element.className
			);
		}
	}

	return false;
}

/**
 * @type {function}
 * @ignore
 * @deprecated
 */
export const contains = hasClass;

/**
 * @deprecated
 */
export function removeClass(input: Element | Element[] | NodeListOf<Element>, classNames: string | string[]) {
	forEach(input, element => {
		splitter(classNames, className => {
			if (element.classList) {
				element.classList.remove(className);
			} else {
				element.className = element.className.replace(
					new RegExp(
						`(^|\\b)${className.split(" ").join("|")}(\\b|$)`,
						"gi"
					),
					" "
				);
			}
		});
	});
}

/**
 * @type {function}
 * @ignore
 * @deprecated
 */
export const remove = removeClass;

/**
 * @deprecated
 */
export function toggleClass(input: Element | Element[] | NodeList, classNames: string | string[], condition?: boolean) {
	forEach(input, element => {
		splitter(classNames, className => {
			let classShouldBeIncluded;
			const classIsIncludedAlready = contains(element, className);

			// If a condition is given, we'll use that to figure out if the class should be added or not.
			if (condition !== undefined) {
				// We'll only continue if the condition doesn't match the current state. So we'll only remove the
				// class if it added already and the condition is "false", and vice versa.
				if (condition !== classIsIncludedAlready) {
					classShouldBeIncluded = condition;
				}
			} else {
				// No condition was given, so we'll just toggle the class.
				classShouldBeIncluded = !classIsIncludedAlready;
			}

			// Only continue if a new mode is defined. Otherwise there's no point.
			if (classShouldBeIncluded !== undefined) {
				if (classShouldBeIncluded) {
					add(element, className);
				} else {
					remove(element, className);
				}
			}
		});
	});
}
