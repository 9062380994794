import Swiper from "swiper";
import "./announcement.scss";
import { type Module } from "~/foundation/Bootstrapper/types";
import { Autoplay, EffectCreative } from "swiper/modules";

export const AnnouncementSlider: Module = (container) => {
	const swiperContainer = container.querySelector<HTMLElement>(".swiper-container");

	if (!swiperContainer) return;

	new Swiper(swiperContainer, {
		modules: [EffectCreative, Autoplay],
		direction: "vertical",
		effect: "creative",
		loop: true,
		noSwiping: true,
		noSwipingClass: "swiper-slide",
		speed: 1000,
		autoplay: {
			delay: 4000,
		},
		creativeEffect: {
			prev: {
				translate: [0, "50%", -1],
			},
			next: {
				translate: [0, "-100%", 0],
			},
		},
		on: {
			init: (swiper) => {
				swiper.el.classList.remove("init");
			}
		}
	});
};

export default AnnouncementSlider;