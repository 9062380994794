import { addClass } from "./classList";
import { setStyles } from "./setStyles";
import { appendElement } from "./elementManipulation";
import { setAttributes } from "./elementProperties";

type CreateElementOptions = {
	className?: string | string[];
	text?: string;
	html?: string | Element | Element[];
	style?: string | CSSStyleDeclaration;
	attributes?: Record<string, string>;
}

export function createElement(tagName: string, options: CreateElementOptions = {}) {
	const newElement = document.createElement(tagName);

	Object.keys(options).forEach(optionKey => {
		const optionValue = options[optionKey as keyof CreateElementOptions];

		switch (optionKey) {
			case "className":
				addClass(newElement, (optionValue as string) || "");
				break;

			case "attributes":
				setAttributes(newElement, optionValue as Record<string, string>);
				break;

			case "html":
				// We'll only set the HTML contents if the "text" property hasn't ALSO been set.
				if (!options.text && typeof optionValue !== "undefined") {
					if (typeof optionValue === "string") {
						newElement.innerHTML = optionValue;
					} else {
						appendElement(optionValue as Element, newElement);
					}
				}
				break;

			case "text":
				if (options.text) {
					newElement.innerText = options.text;
				}
				break;

			case "style":
				if (typeof options.style === "object") {
					setStyles(newElement, optionValue as CSSStyleDeclaration);
				} else {
					setAttributes(newElement, optionKey, optionValue as string);
				}
				break;

			default:
				// Any unknown option key will be treated as a raw attribute.
				setAttributes(newElement, optionKey, optionValue as string);
		}
	})

	return newElement;
}
