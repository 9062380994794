import { appendElement, parseHTML, emptyElement } from "~/foundation/Dom/elementManipulation";
import "./Loaders.scss";

type LoaderOptions = {
	wrapperElement?: HTMLElement | null;
	replace?: boolean,
	responsive?: boolean,
	isAbsolute?: boolean,
}

const oldLoaderMarkup = `
    <div class="spring-spinner">
        <div class="spring-spinner-part top">
            <div class="spring-spinner-rotator"></div>
        </div>
        <div class="spring-spinner-part bottom">
            <div class="spring-spinner-rotator"></div>
        </div>
    </div>
`;

/**
 * Add Old loader
 *
 * @param {Node} parentElement - The parent element the loader is added to
 * @param {Object} options - Object of options
 * @param {Node} [options.wrapperElement=null] - An element that should be wrapped around the loader but inside the parent
 * @param {boolean} [options.replace=false] - If the loader should replace the content of the parent element
 */
export const addOldLoader = (parentElement: HTMLElement, options: LoaderOptions = { wrapperElement: null, replace: false }) => {
	const { wrapperElement, replace } = options;

	if (replace) {
		emptyElement(parentElement);
	}

	let loaderElement: string | HTMLElement = oldLoaderMarkup;

	if (wrapperElement) {
		wrapperElement.innerHTML = oldLoaderMarkup;
		loaderElement = wrapperElement;
	}

	appendElement(parseHTML(loaderElement), parentElement);
};

const loaderMarkup = `
    <span class="loader">
    	<span></span>
    	<span></span>
    	<span></span>
    	<span></span>
		</span>
`;

const loaderMarkupAbsolute = `
    <span class="loader loader--absolute">
    	<span></span>
    	<span></span>
    	<span></span>
    	<span></span>
		</span>
`;

const responsiveLoaderMarkup = `
    <span class="loader__responsive">
    	<span></span>
    	<span></span>
    	<span></span>
    	<span></span>
		</span>
`;

/**
 * Add loader
 *
 * @param {Node} parentElement - The parent element the loader is added to
 * @param {Object} options - Object of options
 * @param {Node} [options.wrapperElement=null] - An element that should be wrapped around the loader but inside the parent
 * @param {boolean} [options.replace=false] - If the loader should replace the content of the parent element
 */
export const addLoader = (parentElement: HTMLElement, options: LoaderOptions = { wrapperElement: null, replace: false, responsive: false, isAbsolute: false }) => {
	const { wrapperElement, replace, responsive, isAbsolute } = options;

	if (replace) {
		emptyElement(parentElement);
	}

	let loaderElement: string | HTMLElement = responsive ? responsiveLoaderMarkup : isAbsolute ? loaderMarkupAbsolute : loaderMarkup;

	if (wrapperElement) {
		wrapperElement.innerHTML = loaderMarkup;
		loaderElement = wrapperElement;
	}

	appendElement(parseHTML(loaderElement), parentElement);
};