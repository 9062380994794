import { onReady } from "~/foundation/Events/onReady";
import Flickity from "flickity";

export class Carousel {
	dom = {
		container: undefined
	};

	constructor(domReference) {
		this.dom.container = domReference;
		this.dom.carousel = this.dom.container.querySelector(".imgslider_slider");
		onReady(() => this.initialize());
	}

	initialize() {
		const carouselFlickity = {
			pageDots: true,
			//freeScroll: true,
			wrapAround: true,
			cellAlign: "center",
			prevNextButtons: false,
			lazyLoad: true
		};

		// eslint-disable-next-line no-unused-vars
		const carouselSlider = new Flickity(
			this.dom.carousel,
			carouselFlickity
		);
	}
}
