import { isExperienceEditor } from "~/foundation/Helpers/isExperienceEditor";
import { type Module } from "~/foundation/Bootstrapper/types.ts";
import "./header.scss";

const header: Module = (header) => {
	const settings = Object.freeze({
		hideOffset: 900,
		hideClass: "header--hidden",
	});

	const state = {
		scrollValue: 0,
		isHeaderVisible: true,
		isExpanded: true
	}

	const expand = () => {
		if (!state.isExpanded) {
			state.isExpanded = true;
			header.classList.remove("header--scrolled");
		}
	}

	const contract = () => {
		state.isExpanded = false;
		header.style.opacity = "0";
		setTimeout(() => {
			header.style.opacity = "1";
		}, 400);
		header.classList.add("header--scrolled");
	}

	const toggleHeaderVisibility = () => {
		if (window.scrollY <= 0) {
			expand();
		}

		// Wait for animation to be finished before changhing headermode. Light to dark
		if (window.scrollY >= settings.hideOffset && window.scrollY > state.scrollValue) {
			header.classList.add(settings.hideClass);
			if (state.isExpanded) {
				contract();
			}

			state.isHeaderVisible = false;
		} else {
			if (!state.isHeaderVisible) {
				header.classList.remove(settings.hideClass);
				state.isHeaderVisible = true;
			}
		}

		state.scrollValue = window.scrollY;
	};

	if (!isExperienceEditor) {
		toggleHeaderVisibility();
		window.addEventListener("scroll", toggleHeaderVisibility);
	}
}

export default header;