/* eslint-disable @typescript-eslint/no-explicit-any */
import { type Module } from "./types";

export const bootstrappedInstances: Record<string, any> = {};

export const counts: Record<string, number> = {};


// function isClass(obj: any) {
// 	return typeof obj === 'function' && /^class\s/.test(Function.prototype.toString.call(obj));
// }

function isClass(obj: any): boolean {
	return Function.prototype.toString.call(obj).startsWith("class");
}

function isAsyncFunction(obj: any) {
	return obj && obj.constructor && obj.constructor.name === 'AsyncFunction';
}

function isFunction(obj: any) {
	return typeof obj === 'function';
}

type AnyModule = Module<any, any>;


export async function bootstrapConstructorOnElement(element: HTMLElement, module: AnyModule, moduleName: string, props?: any) {
	if (isAsyncFunction(module)) {
		bootstrappedInstances[moduleName] = await module(element, props);
		if (!counts[moduleName]) {
			counts[moduleName] = 1;
		} else {
			counts[moduleName]++;
		}
		return;
	}

	if (isClass(module)) {
		bootstrappedInstances[moduleName] = new (module as any)(element);
		if (!counts[moduleName]) {
			counts[moduleName] = 1;
		} else {
			counts[moduleName]++;
		}
		return;
	}

	if (isFunction(module)) {
		const response = module(element, props);
		if (response instanceof Promise) {
			const importedModule = await (response as unknown as { default?: Module });
			if (importedModule.default) {
				bootstrapConstructorOnElement(element, importedModule.default, moduleName, props);
			} else {
				bootstrapConstructorOnElement(element, importedModule as Module, moduleName, props);
			}

		} else {
			bootstrappedInstances[moduleName] = response;
			if (!counts[moduleName]) {
				counts[moduleName] = 1;
			} else {
				counts[moduleName]++;
			}
		}
		return;
	}

	console.error("Invalid bootstrap module", element, module);
}

export async function bootstrapper(modernModules: Record<string, (AnyModule | (() => Promise<{ default: AnyModule }>))>, legacyModules: Record<string, any>) {
	const modules = { ...modernModules, ...legacyModules };

	document.querySelectorAll<HTMLElement>("[data-module]").forEach(async (element) => {
		const moduleName = element.dataset.module;
		if (moduleName) {
			const module = modules[moduleName] as AnyModule;

			if (module) {
				try {
					const props = JSON.parse(element.dataset.moduleProps || "{}");
					await bootstrapConstructorOnElement(element, module, moduleName, props);
				} catch (e) {
					console.warn("Unable to bootstrap", moduleName);
					console.error(e);
				}
			}
		}
	});
}