const binIcon = `
    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group 38034">
            <path id="Vector" d="M3.02148 4.44153V22.346C3.0224 22.6557 3.08433 22.9623 3.20372 23.2482C3.32312 23.534 3.49765 23.7936 3.71735 24.012C3.93706 24.2304 4.19762 24.4034 4.48418 24.5211C4.77073 24.6388 5.07767 24.6989 5.38745 24.6979H16.4877C16.7975 24.6989 17.1044 24.6388 17.391 24.5211C17.6775 24.4034 17.9381 24.2304 18.1578 24.012C18.3775 23.7936 18.552 23.534 18.6714 23.2482C18.7908 22.9623 18.8527 22.6557 18.8537 22.346V4.44153" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10"/>
            <path id="Vector_2" d="M1 4.94397H21" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
            <path id="Vector_3" d="M6.8125 4.8683L7.56658 1.09907C7.56425 1.07566 7.57108 1.05226 7.58565 1.03379C7.60021 1.01531 7.62136 1.0032 7.64467 1H14.1925C14.2155 1.00347 14.2364 1.0157 14.2507 1.03414C14.2651 1.05259 14.2717 1.07584 14.2694 1.09907L15.0852 4.8683" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
            <path id="Vector_4" d="M10.9551 10.8227V20.7061" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
            <path id="Vector_5" d="M14.9922 10.8227V20.7061" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
            <path id="Vector_6" d="M7.04688 10.8227V20.7061" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
        </g>
    </svg>
`;

export default binIcon;
