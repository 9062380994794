import { forEach } from "~/foundation/Helpers/forEach";
import { filter } from "~/foundation/Helpers/filter";
import { splitter } from "~/foundation/Helpers/splitter";

// We'll be using Map and not a regular object, since Map supports using objects as keys.
// This requires you to include the required polyfill. "default-3.6" from polyfill.io is fine.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const knownDOMObjects = new Map<any, any>();

function handleEvent(e: Event) { // eslint-disable-line
	// eslint-disable-next-line
	// @ts-ignore
	const element = this as any; // eslint-disable-line
	const elementHandlers = knownDOMObjects.get(element);
	const eventType = e.type;

	if (elementHandlers && typeof elementHandlers === "object") {
		const callbackList = elementHandlers[eventType];

		// Fire all associated event handlers!
		if (Array.isArray(callbackList) && callbackList.length) {
			forEach(callbackList, callback => {
				callback.eventHandler(e);

				// Remove event if it is only to be fired once
				if (callback.once) {
					removeEvent(element, eventType, callback.eventHandler);
				}
			});
		}
	}
}

/**
 * @deprecated do not use this
 */
export function removeEvent(elements: Element | NodeListOf<Element>, eventTypes: string | string[], eventHandlerToRemove?: any) { // eslint-disable-line
	forEach(elements, element => {
		const elementHandlers = knownDOMObjects.get(element);

		if (elementHandlers) {
			splitter(eventTypes, event => {
				if (event) {
					if (eventHandlerToRemove !== undefined) {
						let callbackList = elementHandlers[event];

						// If there's a list of callbacks for the event type, filter it so it doesn't contain the given "target callback".
						if (Array.isArray(callbackList) && callbackList.length > 0) {
							callbackList = filter(callbackList, (callbackObject: any) => callbackObject.eventHandler !== eventHandlerToRemove); //eslint-disable-line

							// If there are any callbacks left, store them now, and then return to avoid hitting
							// the final "remove everything"-block.
							if (callbackList.length > 0) {
								elementHandlers[event] = callbackList;
								return;
							}
						}
					}

					// If we're here, no usable event handlers are left, or we should just kill anything anyway (if no
					// eventHandlerToRemove was provided). Destroy the entire event handler to clean up memory.
					element.removeEventListener(event, handleEvent);
					delete elementHandlers[event];
				}
			});

			// Update the event handler cache.
			knownDOMObjects.set(element, elementHandlers);
		}
	});
}

/**
 * Remove ALL event handlers set on the given DOM element(s).
 *
 * @param {Node|Node[]|NodeList|Window|Document} elements - The element(s) to clean up.
 * @deprecated do not use this
 */
export function removeAllEvents(elements: Element | NodeListOf<Element>) {
	forEach(elements, element => {
		const elementHandlers = knownDOMObjects.get(element);

		if (elementHandlers) {
			// Remove all the event handlers we can possibly find.
			forEach(Object.keys(elementHandlers), eventType => {
				removeEvent(element, eventType);

				// Remove event listeners and clean up memory.
				element.removeEventListener(eventType, handleEvent);
			});

			knownDOMObjects.delete(element);
		}
	});
}

/**
 * Add an event to the given element(s).
 *
 * @param {Node|Node[]|NodeList|Window|Document} elements - The element(s) we'll be working on.
 * @param {string|string[]} eventTypes  - A string containing one or more events to add (ie. "click", "mouseenter" etc.), separated by comma/space, or given as an array.
 * @param {function} eventHandler       - The event handler function that'll handle the event.
 * @param {boolean} [useCapture=false]  - Whether or not to use event capturing. See JS-docs for more.
 * @param {boolean} [once=false]        - Whether or not to only run the event once and then remove it.
 * @deprecated do not use this
 */
export function addEvent(
	elements: Element | NodeListOf<Element>,
	eventTypes: string | string[],
	eventHandler: (e: any) => void, // eslint-disable-line
	useCapture = false,
	once = false
) {
	forEach(elements, element => {
		const elementHandlers = knownDOMObjects.get(element) || {};

		splitter(eventTypes, event => {
			if (!Array.isArray(elementHandlers[event])) {
				elementHandlers[event] = [];
				element.addEventListener(event, handleEvent, useCapture);
			}

			elementHandlers[event].push({ once, eventHandler });
		});

		knownDOMObjects.set(element, elementHandlers);
	});
}

/**
 * Add an event to the given element(s) and remove it after its first run
 *
 * @param {Node|Node[]|NodeList|Window|Document} elements - The element(s) we'll be working on.
 * @param {string|string[]} eventTypes           - A string containing one or more events to add (ie. "click", "mouseenter" etc.), separated by space.
 * @param {function} eventHandler       - The event handler function that'll handle the event.
 * @param {boolean} [useCapture=false]  - Whether or not to use event capturing. See JS-docs for more.
 * @deprecated do not use this
 */
export function addEventOnce(
	elements: Element | NodeListOf<Element>,
	eventTypes: string | string[],
	eventHandler: (e: any) => void, // eslint-disable-line
	useCapture = false
) {
	addEvent(elements, eventTypes, eventHandler, useCapture, true);
}

/**
 * Create an eventListener on a parent DOMElement to handle events triggered on multiple elements
 * elements triggering the eventHandler is determined by selector given.
 * Bonus: Works even on elements created after the event listener was added.
 * Depends on experimental code `Element.prototype.closest`, which isn't supported in IE, so a polyfill is required.
 *
 * @param {string} selector             - Selector-string of element to trigger eventHandler on
 * @param {string|string[]} eventTypes  - A string containing one or more events to add (ie. "click", "mouseenter" etc.), separated by comma/space, or given as an array.
 * @param {function} eventHandler       - The event handler function that'll be triggered once event is fired inside selected element. Will be called with object as single parameter, containing event and event target (to avoid scope trouble).
 * @param {Node|Node[]|NodeList|HTMLDocument|Window|Document} elementScope    - Parent DOM-element to set eventListener on (optional, defaults to document).
 * @deprecated do not use this
 */
export function delegateEvent(
	selector: string,
	eventTypes: string | string[],
	eventHandler: (e: any) => void, // eslint-disable-line
	elementScope: Element | NodeListOf<Element> | Document = document
) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	addEvent(elementScope as any, eventTypes, event => {
		const listeningTarget = event.target.closest(selector);
		if (listeningTarget) {
			eventHandler({
				event,
				target: listeningTarget
			});
		}
	});
}
