/**
 *
 * @param {Node|NodeList|Window|Document|Array|object|*} collection - Collection to iterate over. Can be a single element, too.
 * @param {function} iterator - Callback function for iterator. Will be called with (value, key) as arguments.
 * @param {boolean} [allowNullValues=false] Whether or not to allow the iterator to run if null/undefined is given as a collection.
 * @deprecated do not use this
 */
export function forEach(collection: any, iterator: (item: any, index: number) => void, allowNullValues = false) { // eslint-disable-line
	// ** NODE LISTS
	// If we're dealing with a node list (see document.querySelectorAll()), we'll iterate through
	// it the old fashioned way.
	if (collection instanceof NodeList) {
		for (let i = 0; i < collection.length; i += 1) {
			iterator(collection.item(i), i);
		}
	} else if (Array.isArray(collection)) {
		// ** ARRAYS
		// Regular arrays are, you know, completely easy.
		// Why not use `Array.prototype.forEach()`? Because for all the optimizations behind
		// the scenes, it's still much slower than a simple for-loop.
		for (let i = 0; i < collection.length; i += 1) {
			iterator(collection[i], i);
		}
	} else if (collection && collection.constructor.name === "Iterator") {
		// ** ITERATORS
		// If we have an iterator object we'll use the "for ... of" method of iterating through it.
		let index = 0;
		for (const item of collection) {
			iterator(item, index);
			index += 1;
		}
	} else if (collection && typeof collection === "object" &&
		!(
			collection instanceof Node ||
			collection instanceof Window ||
			collection instanceof Document
		)
	) {
		// ** REGULAR OBJECTS
		// Objects that are *NOT* HTML-elements of some kind get special treatment.
		forEach(Object.keys(collection), key => {
			// We only want to deal with properties that exist on the object itself,
			// not some prototyped stuff.
			// eslint-disable-next-line no-prototype-builtins
			if (collection.hasOwnProperty(key)) {
				iterator(collection[key], key);
			}
		});
	} else {
		// ** SINGLE NODES OR ELEMENTS
		// Anything that doesn't fit in the cases above will be handled here. We'll just fire the iterator once with the
		// given collection argument, and 0 as the key, and hope that is enough for the use case.
		if (collection || allowNullValues) {
			iterator(collection, 0);
		}
	}
}
