const createMarkup = (option, field, classes, errorMessageTargetClass) => {
	return `
        <li>
            <div class="${classes.rootClass}__radio-group--icon-wrapper">
                <input type="radio"
                    id="${field.key}-${option.label}"
                    name="${field.key}"
                    value="${option.value}"
                    data-error-message="${field.defaultErrorMessage}"
                    data-error-container="${errorMessageTargetClass}"
                    ${option.selected ? ` checked` : ``}
                    ${field.readOnly ? ` readonly` : ``}
                    ${field.isRequired ? ` required` : ``}
                />
                <label for="${field.key}-${option.label}">
                    <span class="${classes.rootClass}__label-text">${
	option.label
}</span>
                    <span class="${classes.rootClass}__svg" data-svg="${
	option.iconUrl
}">
                        ${option.iconSvgContent}
                    </span>
                </label>
            </div>
        </li>
    `;
};

export const buildRadioIconInput = (
	field,
	classes,
	errorMessageTargetClass
) => {
	return field.options
		.map(option =>
			createMarkup(option, field, classes, errorMessageTargetClass)
		)
		.join("");
};
