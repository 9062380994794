import { parseHTML } from "~/foundation/Dom/elementManipulation";
import aramcoLifeLogo from "~/foundation/Icons/aramco-life-pale";
import aramcoLogo from "~/foundation/Icons/aramco-logo";

export const splashMarkup = () => {
	return parseHTML(`
    <div class="signup-splash animate-fade">
        ${aramcoLogo}
        ${aramcoLifeLogo}
    </div>`);
};
