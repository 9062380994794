import { parseHTML } from "~/foundation/Dom/elementManipulation";

export const infoNotificationMarkup = message => {
	return parseHTML(`
			<div class="notification notification--info">
					<div class="notification__message">
							<p>${message}</p>
					</div>
			</div>
    `);
};

export const errorNotificationMarkup = dataObject => {
	let message = "";
	message += `${dataObject.success === false &&
		`
			<div class="notification__message">
				<p>${dataObject.errors[0].message}</p>
			</div>
			`
		}`;

	return parseHTML(`
			<div class="notification ${dataObject.success === false ? `notification--error` : ""}">
				${message}
			</div>
    `);
};
