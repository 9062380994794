import { parseHTML } from "~/foundation/Dom/elementManipulation";
import { closeMarkup } from "../../../close";

export const confirmationMarkup = labels => {
    const { confirmationText, resendEmailText, resendLabel } = labels;

    return parseHTML(`
    <div class="membership-header membership-header--signup-confirmation">
        ${closeMarkup}
    </div>
    <div class="animate-translate membership-middle membership-middle--signup-confirmation">
        <div class="membership-message">
        ${confirmationText}
        <br />
        ${resendEmailText}
        <br />
        <button type="button" class="animate-translate button-link button-link--secondary">${resendLabel}</button>
        </div>
    </div>`);
};
